// MARK: React
import React from "react";
import ReactDOM from "react-dom";

// MARK: APP
import { bootAPI } from "./modules/API";
import Strings from "./modules/Strings";
import { App } from "./pages";


if ("serviceWorker" in navigator) {
	window.addEventListener("load", () => {
		navigator.serviceWorker
			.register("/serviceworker.js")
			.then((swRegistration) => {
				if ("Notification" in window) {
					if (Notification.permission !== "granted") {
						Notification.requestPermission();

						swRegistration.pushManager
							.getSubscription()
							.then((subscription) => {
								if (
									subscription &&
									Notification.permission !== "granted"
								) {
									subscription.unsubscribe();
								}
							})
							// tslint:disable-next-line: no-console
							.catch((err) =>
								console.log("Erro unsubscribing: ", err),
							);
					}

					if (Notification.permission === "granted") {
						const publicVapidKey =
							"BMo7bm_lvopx9umjkCRlxIrS0Mjx0chzrvHYbU387GeYyso__Lo9ImRkvoUUhrRVXkeV0AP7QeWBfQIuSKrgqyc";

						const padding = "=".repeat(
							(4 - (publicVapidKey.length % 4)) % 4,
						);

						const base64 = (publicVapidKey + padding)
							.replace(/\-/g, "+")
							.replace(/_/g, "/");

						const rawData = window.atob(base64);

						const outputArray = new Uint8Array(rawData.length);

						for (let i = 0; i < rawData.length; ++i) {
							outputArray[i] = rawData.charCodeAt(i);
						}

						swRegistration.pushManager
							.subscribe({
								userVisibleOnly: true,
								applicationServerKey: outputArray,
							})
							// tslint:disable-next-line: no-console
							.catch((err) =>
								console.log("Erro subscribing: ", err),
							);
					}
				}
			});

		navigator.serviceWorker.register("/firebase-messaging-sw.js");
	});
}

bootAPI(Strings);

ReactDOM.render(<App />, document.getElementById("app"));
