import {
	action,
	computed,
	makeObservable,
	observable,
	runInAction,
} from "mobx";

import { IField } from "src/components/Select";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import api from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import UIStore from "../../../../stores/UIStore";


export default class UserTableStore extends PaginatedListStore<api.User> {
	private uiStore: UIStore;
	public numberOfUsers: number | null = null;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(uiStore, router);
		this.fetchPage(0);
		this.getTotalNumberUsers();
		this.uiStore = uiStore;
		makeObservable(this, {
			_selectedFilter: observable,
			numberOfUsers: observable,
			changeFilter: action,
			selectFields: computed,
			deleteUser: action,
		});
	}

	public _selectedFilter: api.UserType | null = null;

	protected getDataItemsPerPage(page: number): Promise<api.User[]> {
		return api.getUsers(page, this._selectedFilter);
	}

	public async deleteUser(id: string): Promise<void> {
		try {
			await api.deleteUser(id);
			this.fetchPage(0);
			this.uiStore.showSnackbar(Strings.dialogs.operationHasBeenASuccess);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	}

	public changeFilter(selectedType: string): void {
		const parsedValue = api.UserType[selectedType as api.UserType] || null;

		this._selectedFilter = parsedValue;

		this.fetchPage(0);
	}

	public async getTotalNumberUsers(userType?: string): Promise<void>  {
		try {
			const result = await api.getTotalNumberUsers(api.UserType[userType as api.UserType] || null);
			runInAction(() => this.numberOfUsers = result);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	}

	public get selectFields(): IField[] {
		return [
			{
				name: "Motorista",
				value: api.UserType.driver,
			},

			{
				name: "Contratante",
				value: api.UserType.contractor,
			},

			{
				name: "Todos",
				value: "todos",
			},
		];
	}
}
