import React from "react";
import { Select, SelectProps } from "@material-ui/core";
import { observer } from "mobx-react-lite";

interface IProps extends SelectProps {
	fields: (fieldName: string) => void;
	name: string;
	allValuesEnum: () => string[];
	translateEnum: (enumValue: string) => string;
	error: boolean;
}

export const SelectEnum = observer<IProps>((props) => {
	return (
		<Select
			native
			{...props.fields("type")}
			error={props.error}
		>
			{props.allValuesEnum().map((enumValue: string, index: number) => (
				<option key={index} aria-selected value={enumValue}>
					{props.translateEnum(enumValue)}
				</option>
			))}
		</Select>
	);
});
