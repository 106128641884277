// MARK: React
import React from "react";

// MARK: Mobx
import {
	Box,
	Card,
	CardContent,
	CardActions,
	Divider,
	LinearProgress,
	Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";

// MARK: Resources

// MARK: Componentes
import { BackButtonHeader } from "../BackButtonHeader";
import { PageContainer } from "../PageContainer";
import { useStyles } from "./styles";

interface IProps {
	title: string;
	loading?: boolean;
	actionsFooter?: React.ReactElement | null;
	hasAError?: string;
}

const CardForm: React.FC<IProps> = (props) => {
	const classes = useStyles();
	const { title, loading, actionsFooter, hasAError } = props;

	return (
		<PageContainer>
			{loading ? <LinearProgress /> : null}
			<Card className={classes.root}>
				<BackButtonHeader title={title} />
				<Box className={classes.box} display="flex" flexDirection="column">
					<Divider />
					<CardContent className={classes.cardContent}>
						{hasAError ? (
							<Typography>{hasAError}</Typography>
						) : (
							props.children
						)}
					</CardContent>
				</Box>
				<Divider />
				<CardActions>{actionsFooter}</CardActions>
			</Card>
		</PageContainer>
	);
};

export default observer(CardForm);
