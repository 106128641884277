import React from "react";

// import { Offline } from "react-detect-offline";

import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { observer } from "mobx-react-lite";

import Strings from "../../../modules/Strings";

import { AvatarWithOptions } from "../AvatarWithOptions";

interface IProps {
	toggleDrawer?: () => void;
}

const Navbar: React.FC<IProps> = (props) => (
	<>
		<AppBar elevation={1} position="fixed" style={{ backgroundColor: "#ffff" }}>
			<Toolbar>
				<IconButton
					color="secondary"
					edge="start"
					aria-label="Menu"
					onClick={props.toggleDrawer}
				>
					<Menu color="secondary" />
				</IconButton>
				<Typography color="secondary" variant="h5">
					{Strings.nav.title}
				</Typography>
				<div style={{ flexGrow: 1 }} />
				<AvatarWithOptions />
			</Toolbar>
			{/* <Offline>
				<Alert style={{ borderRadius: 0 }} variant="filled" severity="warning">
					{Strings.nav.disconected}
				</Alert>
			</Offline> */}
		</AppBar>
	</>
);

export default observer(Navbar);
