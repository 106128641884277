// MARK: React
import * as React from "react";

// MARK: Mobx
import { Container } from "@material-ui/core";
import { observer } from "mobx-react-lite";

// MARK: Material
import { useGlobalStore } from "../../pages/context";
import useStyles from "./styles";

interface IProps {
	classStyles?: string;
}

const PageTableContainer: React.FC<IProps> = (props) => {
	const { uiStore, themeStore } = useGlobalStore();
	const classes = useStyles(themeStore.currentTheme);
	return (
		<Container
			maxWidth="xl"
			className={[classes.root, props.classStyles].join(" ")}
			disableGutters
		>
			<>{props.children}</>
		</Container>
	);
};
export default observer(PageTableContainer);
