import React from "react";
import { useParams, useHistory } from "react-router-dom";

import { observer, useLocalStore } from "mobx-react-lite";

import DetailsForm from "../../../../components/DetailsForm";
import { DetailRow } from "../../../../components/DetailRow";
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import Store from "./store";
import { useGlobalStore } from "../../../context";
import MyShippingTable from "./components/MyShippingTable";
import useStyles from "./styles";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));
	const classes = useStyles();
	return (
		<>
			<DetailsForm
				title={Strings.users.details.title}
				loading={store.loader.isLoading}
				hasAError={store.error || undefined}
				modelContent={!!store.model}
				contentStyle={classes.detailsFormContent}
			>
				{store.model && (
					<>
						<DetailRow
							mainCardStyle={classes.detailsRowCard}
							label={Strings.fields.name}
							value={store.model.name}
						/>
						<DetailRow
							mainCardStyle={classes.detailsRowCard}
							label={Strings.fields.phone}
							value={store.model.phone}
						/>
						<DetailRow
							mainCardStyle={classes.detailsRowCard}
							label={Strings.fields.type}
							value={API.translateUserType(store.model.type)}
						/>
						{
							/* Hide cpf
							<DetailRow
								mainCardStyle={classes.detailsRowCard}
								label={Strings.fields.cpf}
								value={store.model.cpf}
							/>
							*/
						}
						<DetailRow
							mainCardStyle={classes.detailsRowCard}
							label={Strings.fields.email}
							value={store.model.email}
						/>
					</>
				)}
			</DetailsForm>
			{store.model && store.model.type === API.UserType.driver && (
				<MyShippingTable driverId={id} />
			)}
		</>
	);
};
export default observer(DetailsView);
