import React from "react";
import { Typography, Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";

import FilterDrawer from "../../../../../../../../components/FilterDrawer";
import FilterShelf from "../../shelves/FilterShelf";
import Strings from "../../../../../../../../modules/Strings";
import DateFnsUtils from "@date-io/date-fns";

import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import useStyles from "./styles";

interface IProps {
	state: boolean;
	filterShelf: FilterShelf;
	setState: (state: boolean) => void;
	search: () => void;
	clear: () => void;
	loading: boolean;
}

const Filter: React.FC<IProps> = (props) => {
	const { clear, search, state, setState, filterShelf, loading } = props;
	const filterStrings = Strings.users.details;
	const classes = useStyles();
	return (
		<FilterDrawer
			onClose={() => setState(false)}
			state={state}
			containerStyle={classes.boxFilter}
			drawerStyle={classes.drawer}
		>
			<Typography variant="h6" className={classes.title}>
				{filterStrings.filter.title}
			</Typography>

			<Typography className={classes.titleDate}>
				{filterStrings.filter.initPeriodDate}
			</Typography>

			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					disableToolbar
					variant="inline"
					format="dd/MM/yyyy"
					margin="normal"
					label={filterStrings.filter.startDate}
					value={filterShelf.initPeriodStartDate.date}
					onChange={(date) => {
						filterShelf.initPeriodStartDate.setDate(date as Date);
					}}
					KeyboardButtonProps={{
						"aria-label": "change date",
					}}
				/>
				<KeyboardDatePicker
					disableToolbar
					variant="inline"
					format="dd/MM/yyyy"
					margin="normal"
					label={filterStrings.filter.endDate}
					value={filterShelf.initPeriodEndDate.date}
					onChange={(date) => {
						filterShelf.initPeriodEndDate.setDate(date as Date);
					}}
					KeyboardButtonProps={{
						"aria-label": "change date",
					}}
				/>
			</MuiPickersUtilsProvider>

			<Typography className={classes.titleDate}>
				{filterStrings.filter.finalPeriodDate}
			</Typography>

			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					disableToolbar
					variant="inline"
					format="dd/MM/yyyy"
					margin="normal"
					label={filterStrings.filter.startDate}
					value={filterShelf.finalPeriodStartDate.date}
					onChange={(date) => {
						filterShelf.finalPeriodStartDate.setDate(date as Date);
					}}
					KeyboardButtonProps={{
						"aria-label": "change date",
					}}
				/>
				<KeyboardDatePicker
					disableToolbar
					variant="inline"
					format="dd/MM/yyyy"
					margin="normal"
					label={filterStrings.filter.endDate}
					value={filterShelf.finalPeriodEndDate.date}
					onChange={(date) => {
						filterShelf.finalPeriodEndDate.setDate(date as Date);
					}}
					KeyboardButtonProps={{
						"aria-label": "change date",
					}}
				/>
			</MuiPickersUtilsProvider>
			<Button
				className={classes.filterButtons}
				onClick={search}
				disabled={loading}
				variant="contained"
				color="primary"
			>
				{filterStrings.filter.onFilter}
			</Button>
			<Button
				style={{ marginBottom: 10 }}
				disabled={loading}
				onClick={clear}
				variant="contained"
				color="secondary"
			>
				{filterStrings.filter.clearFilter}
			</Button>
		</FilterDrawer>
	);
};

export default observer(Filter);
