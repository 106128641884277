import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
	},
	cardNumber: {
		marginLeft: "18px",
		width: "100%",
		maxWidth: "200px",
		justifyContent: "center",
	},
	title: {
		textAlign: "center",
	},
	text: {
		fontWeight: "bold",
		textAlign: "center",
	},
}));

export default useStyles;
