import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
	},
	statisticsBox: {
		display: "flex",
		marginTop: theme.spacing(2),
	},
	boxFilter: {
		display: "flex",
		flexDirection: "column",
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
		minHeight: "85vh",
	},
	filterButtons: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},

	title: {
		textAlign: "center",
		fontWeight: "bold",
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	titleDate: {
		textAlign: "center",
		fontWeight: "bold",
		marginTop: theme.spacing(1),
	},

	filterIconOpen: {
		background: "#D0D0D0",
	},
	pageTableContainerAndFilter: {
		maxWidth: 1500,
		transition: "marginLeft 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
		transitionProperty: "marginLeft",
		transitionDuration: "195ms",
		[theme.breakpoints.up("sm")]: {
			marginLeft: 280,
		},
		[theme.breakpoints.down("md")]: {
			maxWidth: 960,
		},
	},
	pageTableContainerAndFilterClosed: {
		transition: "marginLeft 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
		transitionProperty: "marginLeft",
		transitionDuration: "195ms",
	},
	drawer: {
		top: 112,
	},
}));

export default useStyles;
