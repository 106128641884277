import React from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { observer, useLocalStore } from "mobx-react-lite";
import {
	Box,
	Typography,
} from "@material-ui/core";
import CardForm from "../../../../components/CardForm";
import { DetailRow } from "../../../../components/DetailRow";
import LinearProgress from "../../../../components/LinearProgress";
import Strings from "../../../../modules/Strings";
import { Formatting } from "../../../../modules/Formatting";
import API from "../../../../modules/API";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import useStyles from "./styles";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { uiStore } = useGlobalStore();
	const classes = useStyles();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	return (
		<CardForm title={Strings.cargoOrder.details.title}>
			{!store.model ? (
				<LinearProgress />
			) : (
				<>
					<DetailRow
						label={Strings.fields.id}
						value={store.model.id}
					/>
					<DetailRow
						label={Strings.fields.origin}
						value={store.model.origin.address}
					/>
					<DetailRow
						label={Strings.fields.destiny}
						value={store.model.destiny.address}
					/>
					<DetailRow
						label={Strings.fields.departureDate}
						value={moment(store.model.startDate).format("DD/MM/YYYY")}
					/>
					<DetailRow
						label={Strings.fields.arrivedDate}
						value={moment(store.model.endDate).format("DD/MM/YYYY")}
					/>
					<DetailRow
						label={Strings.fields.animalType}
						value={API.translateAnimalType(store.model.animalType)}
					/>
					<DetailRow
						label={Strings.fields.quantity}
						value={store.model.quantity}
					/>
					{store.model.weight && (
						<DetailRow
							label={Strings.fields.weightKg}
							value={store.model.weight}
						/>
					)}
					<DetailRow
						label={Strings.fields.priceProposal}
						value={Formatting.formatCurrencyBR(store.model.price)}
					/>
					<Box className={classes.contractorDataContainer} >
						<Typography className={classes.contractorTitle} variant="h6">
							{Strings.cargoOrder.details.contractorData}
						</Typography>
						<DetailRow
							label={Strings.fields.id}
							value={store.model.owner.id}
						/>
						<DetailRow
							label={Strings.fields.name}
							value={store.model.owner.name}
						/>
						<DetailRow
							label={Strings.fields.phone}
							value={store.model.owner.phone}
						/>
						<DetailRow
							label={Strings.fields.email}
							value={store.model.owner.email}
						/>
					</Box>
				</>
			)}
		</CardForm>
	);
};
export default observer(DetailsView);
