import { makeAutoObservable } from "mobx";

import api from "../../../../../../../../modules/API";
import DatePickerShelf from "../../../../../../../../shelves/DatePickerShelf";

export default class FilterStore {

	public initPeriodStartDate = new DatePickerShelf();

	public initPeriodEndDate = new DatePickerShelf();

	public finalPeriodStartDate = new DatePickerShelf();

	public finalPeriodEndDate = new DatePickerShelf();

	constructor() {
		makeAutoObservable(this);
	}

	public get initialPeriod(): api.PeriodDate | null {
		const startDate = this.initPeriodStartDate.date;
		const endDate = this.initPeriodEndDate.date;
		if (!startDate || !endDate) {
			return null;
		}
		return {
			startDate,
			endDate,
		};
	}

	public get finalPeriod(): api.PeriodDate | null {
		const startDate = this.finalPeriodStartDate.date;
		const endDate = this.finalPeriodEndDate.date;
		if (!startDate || !endDate) {
			return null;
		}
		return {
			startDate,
			endDate,
		};
	}

	public clear() {
		this.initPeriodStartDate.clear();
		this.initPeriodEndDate.clear();
		this.finalPeriodStartDate.clear();
		this.finalPeriodEndDate.clear();
	}
}
