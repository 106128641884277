import React from "react";

import { Box, CircularProgress } from "@material-ui/core";
import moment from "moment";
import { observer, useLocalStore } from "mobx-react-lite";
import API from "../../../../../../modules/API";
import CargoOrder from "../../../../../../components/CargoOrderDetails";
import TransportOrder from "../../../../../../components/TransportOrderDetails";
import { DetailRow } from "../../../../../../components/DetailRow";
import Strings from "../../../../../../modules/Strings";
import Store from "./store";
import useStyles from "./styles";

interface IShipping {
	shippingId: string;
}

const Shipping: React.FC<IShipping> = (props) => {
	const { shippingId } = props;
	const ComponentStrings = Strings.components.shipping;
	const classes = useStyles();
	const store = useLocalStore(() => new Store(shippingId));

	return (
		<>
			{store.shipping ? (
				<>
					<DetailRow label={ComponentStrings.id} value={store.shipping.id} />
					<DetailRow
						mainCardStyle={classes.detailsRowCard}
						label={API.translateUserType(store.shipping.owner.type)}
						value={store.shipping.owner.name}
					/>
					<DetailRow
						mainCardStyle={classes.detailsRowCard}
						label={API.translateUserType(store.shipping.accepter.type)}
						value={store.shipping.accepter.name}
					/>
					<DetailRow
						label={ComponentStrings.currentStatus}
						value={API.translateShippingStatusOptions(
							store.shipping.status.statusName,
						)}
					/>
					<DetailRow
						label={ComponentStrings.createDate}
						value={moment(store.shipping.createdAt).format("DD/MM/YYYY")}
					/>
					{store.shipping.transportOrder && (
						<TransportOrder transport={store.shipping.transportOrder} />
					)}
					{store.shipping.cargoOrder && (
						<CargoOrder cargo={store.shipping.cargoOrder} />
					)}
				</>
			) : (
				<Box
					style={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						width: "100%",
					}}
				>
					<CircularProgress />
				</Box>
			)}
		</>
	);
};
export default observer(Shipping);
