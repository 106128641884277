import React from "react";

import {
	Box,
	Divider,
	IconButton,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { observer } from "mobx-react";

import { styles } from "./styles";

interface IProps<DataItemType> {
	title?: string;
	headers: string[];
	rows: DataItemType[];
	renderItem: (
		item: DataItemType,
		index: number,
	) => React.ReactElement | null;
	FooterActions?: React.ReactElement;
	pagination?: {
		page: number;
		onNextClick: () => void;
		onPreviousClick: () => void;
	};
	loading?: boolean;
	bottomToolbar?: React.ReactElement;
	headerToolbar?: React.ReactElement;
	isHaveNexPage?: boolean;
	fallback?: React.ReactElement;
}

@observer
export default class SimpleTable<DataItemType> extends React.Component<
IProps<DataItemType>
> {
	public render() {
		return (
			<Paper style={styles.root as any}>
				<Box padding={2.5} display="flex">
					<Typography variant="h5">{this.props.title}</Typography>
					<div style={{ flexGrow: 1 }} />
					{this.props.headerToolbar}
				</Box>
				<Divider />
				{this.props.loading ? <LinearProgress /> : null}
				<TableContainer style={styles.container as any}>
					{this.props.rows.length ? (
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									{this.props.headers.map((header, index) => (
										<TableCell key={index} align="center">
											{header}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{this.props.rows.map((dataItem, index) => {
									return this.props.renderItem(
										dataItem,
										index,
									);
								})}
							</TableBody>
						</Table>
					) : (
						this.props.fallback
					)}
				</TableContainer>
				<Divider />
				<Box display="flex" flexDirection="row" alignItems="center">
					<Box
						display="flex"
						paddingLeft={1}
						paddingRight={1}
						flexDirection="row"
						flexGrow={1}
					>
						{this.props.bottomToolbar}
					</Box>
					{this.props.pagination && (
						<Box
							display="flex"
							flexDirection="row"
							alignItems="center"
						>
							<IconButton
								onClick={this.props.pagination.onPreviousClick}
								disabled={this.props.pagination.page === 0}
							>
								<ChevronLeft />
							</IconButton>
							<Typography>
								{this.props.pagination.page + 1}
							</Typography>
							<IconButton
								onClick={this.props.pagination.onNextClick}
								disabled={!this.props.isHaveNexPage}
							>
								<ChevronRight />
							</IconButton>
						</Box>
					)}
				</Box>
			</Paper>
		);
	}
}
