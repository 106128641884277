import React from "react";
import { Switch, Route } from "react-router-dom";

import { observer } from "mobx-react-lite";

import TableView from "./TableView";
import DetailsView from "./DetailsView";

interface IProps {}

const CargoOrder: React.FC<IProps> = () => {
	return (
		<Switch>
			<Route exact path="/dashboard/cargoOrder" component={TableView} />
			<Route
				exact
				path="/dashboard/cargoOrder/details/:id"
				component={DetailsView}
			/>
		</Switch>
	);
};

export default observer(CargoOrder);
