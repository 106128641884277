import { Color } from "@material-ui/lab";
import { makeAutoObservable, runInAction } from "mobx";
import { inspect } from "util";

import Strings from "../../modules/Strings";

export default class UIStore {
	public linearLoading = false;

	public snackbarMessage: string | null = null;

	public messageType: Color | null = null;

	public dialogTitle: string | null = null;

	public dialogMessage: string | null = null;

	public onDialogConfirm: (() => void) | null = null;

	public alertTitle: string | null = null;

	public alertMessage: string | null = null;

	public onAlertConfirm: (() => void) | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	public showErrorSnackbar = (error: any) => {
		let errorMessage: string;

		if (error.message) {
			errorMessage = error.message;
		} else {
			errorMessage = inspect(error);
		}

		this.showSnackbar(errorMessage, "error");
	};

	public setLinearLoading = (linearLoading: boolean) => {
		runInAction(() => (this.linearLoading = linearLoading));
	};

	public clearLinearLoading = () => {
		runInAction(() => (this.linearLoading = false));
	};

	public circularLoading = false;

	public setCircularLoading = (circularLoading: boolean) => {
		runInAction(() => (this.circularLoading = circularLoading));
	};

	public clearCircularLoading = () => {
		runInAction(() => (this.circularLoading = false));
	};

	public showSnackbar = (message: string, typeMessage: Color = "info") => {
		this.snackbarMessage = message;
		this.messageType = typeMessage;
		this.runTimeout(this.clearSnackbar);
	};

	public showSuccessSnackbar = () => {
		this.showSnackbar(Strings.dialogs.operationHasBeenASuccess, "success");
	};

	public clearSnackbar = () => {
		this.snackbarMessage = null;
	};

	public showDialog = (
		dialogData: { title: string; message: string },
		onConfirm?: () => void,
	) => {
		runInAction(() => {
			this.dialogTitle = dialogData.title;
			this.dialogMessage = dialogData.message;
			this.onDialogConfirm = onConfirm || null;
		});
	};

	public clearDialog = () => {
		runInAction(() => {
			this.dialogTitle = null;
			this.dialogMessage = null;
			this.onDialogConfirm = null;
		});
	};

	public showAlert = (
		alertData: { title: string; message: string },
		onConfirm?: () => void,
	) => {
		runInAction(() => {
			this.onAlertConfirm = onConfirm || (() => {});
			this.alertTitle = alertData.title;
			this.alertMessage = alertData.message;
		});
	};

	public clearAlert = () => {
		runInAction(() => {
			this.alertTitle = null;
			this.alertMessage = null;
			this.onAlertConfirm = null;
		});
	};

	public clear = () => {
		runInAction(() => {
			this.clearLinearLoading();
			this.clearCircularLoading();
			this.clearSnackbar();
			this.clearDialog();
			this.clearAlert();
		});
	};

	public clearTimeout = () => {
		this.runTimeout(this.clear);
	};

	public runTimeout = (run: () => void) => {
		setTimeout(run, 4000);
	};
}
