import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	detailsRowCard: {
		width: "45%",
	},
	title: {
		fontWeight: "bold",
		width: "100%",
		marginBottom: 10,
		marginLeft: 5,
	},
}));

export default useStyles;
