import React from "react";
import "./style.scss";

import {
	DialogActions as MDialogActions,
	Dialog as MDialog,
	DialogContent as MDialogContent,
	DialogContentText as MDialogContentText,
	Button as MButton,
	DialogTitle as MDialogTitle,
} from "@material-ui/core";

import Strings from "../../modules/Strings";

interface IProps {
	onDialogConfirm: (() => void) | null;
	clearDialog: () => void;
	dialogMessage?: string | null;
	dialogTitle?: string | null;
}

export default class SimpleDialog extends React.Component<IProps> {
	private onConfirmClick = () => {
		const { onDialogConfirm, clearDialog } = this.props;

		if (onDialogConfirm) {
			onDialogConfirm();
		}

		clearDialog();
	};

	public render() {
		const { clearDialog, dialogMessage, dialogTitle } = this.props;

		return (
			<MDialog
				open={
					(!!dialogTitle && dialogTitle !== "") ||
					(!!dialogMessage && dialogMessage !== "")
				}
				onClose={clearDialog}
			>
				{dialogTitle && <MDialogTitle>{dialogTitle}</MDialogTitle>}
				{dialogMessage && (
					<MDialogContent>
						<MDialogContentText>{dialogMessage}</MDialogContentText>
					</MDialogContent>
				)}
				<MDialogActions>
					<MButton onClick={clearDialog} variant="outlined">
						{Strings.cancel}
					</MButton>
					<MButton
						onClick={this.onConfirmClick}
						variant="contained"
						autoFocus
					>
						{Strings.confirm}
					</MButton>
				</MDialogActions>
			</MDialog>
		);
	}
}
