import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	cardNumber: {
		width: "100%",
		maxWidth: "240px",
		justifyContent: "center",
	},
	title: {
		textAlign: "center",
	},
	text: {
		fontWeight: "bold",
		textAlign: "center",
	},
}));

export default useStyles;
