import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface File {
    name: string;
    url: string;
}

export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface ImageCrop {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface UploadFile {
    bytes: Buffer;
    name: string;
}

export interface ShippingStatus {
    statusName: ShippingStatusOptions;
    changeDate: Date;
}

export interface Location {
    latitude: number;
    longitude: number;
    address: string;
}

export interface UserDetails {
    name: string;
    email: string;
    phone: string;
    cpf: string | null;
    image: Image | null;
    address: Address | null;
    type: UserType;
}

export interface NewVehicle {
    model: string;
    axisType: VehicleAxis;
}

export interface Vehicle {
    id: string;
    model: string;
    axisType: VehicleAxis;
}

export interface NewUser {
    password: string;
    type: UserType;
    cpf: string | null;
    name: string;
    email: string;
    phone: string;
    image: Image | null;
    address: Address | null;
}

export interface LatLng {
    lat: number;
    lng: number;
}

export interface Address {
    zipcode: string;
    street: string;
    streetNumber: string;
    complementary: string | null;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
    countryCode: string;
    latLng: LatLng;
}

export interface City {
    id: string;
    name: string;
    state: string;
    uf: string;
}

export interface AdminUserDetails {
    name: string;
    email: string;
}

export interface NewAdminUser {
    password: string;
    name: string;
    email: string;
}

export interface AdminUser {
    id: string;
    name: string;
    email: string;
}

export interface EditAdminUser {
    name: string;
    email: string;
}

export interface NewCargoOrderProposal {
    proposalStatus: ProposalStatus;
    price: number;
}

export interface CargoOrderProposal {
    id: string;
    proposer: User;
    createdAt: Date;
    proposalStatus: ProposalStatus;
    price: number;
}

export interface CargoOrder {
    id: string;
    owner: User;
    accepter: User | null;
    origin: Location;
    destiny: Location;
    startDate: Date;
    endDate: Date;
    price: number;
    weight: number | null;
    quantity: number;
    animalType: AnimalType;
    proposals: CargoOrderProposal[];
    createdAt: Date;
}

export interface NewAdminCargoOrder {
    ownerId: string;
    startDate: Date;
    endDate: Date;
    price: number;
    weight: number | null;
    quantity: number;
    origin: Location;
    destiny: Location;
    animalType: AnimalType;
}

export interface NewTransportOrderProposal {
    proposalStatus: ProposalStatus;
    price: number;
}

export interface TransportOrderProposal {
    id: string;
    proposer: User;
    createdAt: Date;
    proposalStatus: ProposalStatus;
    price: number;
}

export interface TransportOrder {
    id: string;
    owner: User;
    accepter: User | null;
    origin: Location;
    destiny: Location;
    vehicle: Vehicle;
    originDistanceRadius: number;
    destinyDistanceRadius: number;
    startDate: Date;
    endDate: Date;
    price: number;
    weight: number;
    proposals: TransportOrderProposal[];
    createdAt: Date;
}

export interface Secure {
    id: string;
    owner: User;
    cargoOrderId: string | null;
    transportOrderId: string | null;
    shippingId: string | null;
}

export interface NewAdminTransport {
    ownerId: string;
    origin: Location;
    destiny: Location;
    vehicle: Vehicle;
    originDistanceRadius: number;
    destinyDistanceRadius: number;
    startDate: Date;
    endDate: Date;
    price: number;
    weight: number;
}

export interface Shipping {
    id: string;
    owner: User;
    accepter: User;
    price: number;
    city: string;
    startDate: Date;
    endDate: Date;
    cargoOrder: CargoOrder | null;
    transportOrder: TransportOrder | null;
    status: ShippingStatus;
    statusHistory: ShippingStatus[];
    createdAt: Date;
}

export interface PeriodDate {
    startDate: Date;
    endDate: Date;
}

export interface ShippingFilter {
    driverId: string | null;
    status: ShippingStatusOptions | null;
    startPeriodDate: PeriodDate | null;
    endPeriodDate: PeriodDate | null;
    city: string | null;
}

export interface User {
    id: string;
    ratingAverage: number;
    vehicles: Vehicle[] | null;
    createdAt: Date;
    editedAt: Date;
    name: string;
    email: string;
    phone: string;
    cpf: string | null;
    image: Image | null;
    address: Address | null;
    type: UserType;
}

export interface ShippingStatistics {
    endShippingCount: number;
    currentShippingCount: number;
}

export interface NotCanceledShippingInfo {
    totalPrice: number;
    shippingsCount: number;
}

export enum Language {
    ptBr = "ptBr",
    enUs = "enUs",
}

export function translateLanguage(enumLanguage: Language): string {
    switch (enumLanguage) {
        case Language.ptBr: {
            return strings ? strings["enum"]["Language"]["ptBr"] || Language.ptBr : Language.ptBr;
        }
        case Language.enUs: {
            return strings ? strings["enum"]["Language"]["enUs"] || Language.enUs : Language.enUs;
        }
    }
    return "";
}

export function allValuesLanguage(): Language[] {
    return [
        Language.ptBr,
        Language.enUs,
    ];
}

export function allTranslatedValuesLanguage(): string[] {
    return [
        translateLanguage(Language.ptBr),
        translateLanguage(Language.enUs),
    ];
}

export function allDisplayableValuesLanguage(): string[] {
    return allTranslatedValuesLanguage().sort();
}

export function valueFromTranslationLanguage(translation: string): Language {
    const index = allTranslatedValuesLanguage().indexOf(translation);
    return allValuesLanguage()[index] || Language.ptBr;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum AnimalType {
    cow = "cow",
    calvingCow = "calvingCow",
    heifer = "heifer",
    ox = "ox",
    calf = "calf",
    buffalo = "buffalo",
    buffaloFemale = "buffaloFemale",
    pig = "pig",
    pigFemale = "pigFemale",
    pigYoung = "pigYoung",
    goatMale = "goatMale",
    goatFemale = "goatFemale",
    goatYoung = "goatYoung",
    ram = "ram",
    sheep = "sheep",
    lamb = "lamb",
    horse = "horse",
    mare = "mare",
    calvingMare = "calvingMare",
    horseYoung = "horseYoung",
    jack = "jack",
    donkey = "donkey",
    mule = "mule",
    hen = "hen",
    chick = "chick",
    chicken = "chicken",
    quail = "quail",
    peru = "peru",
}

export function translateAnimalType(enumAnimalType: AnimalType): string {
    switch (enumAnimalType) {
        case AnimalType.cow: {
            return strings ? strings["enum"]["AnimalType"]["cow"] || AnimalType.cow : AnimalType.cow;
        }
        case AnimalType.calvingCow: {
            return strings ? strings["enum"]["AnimalType"]["calvingCow"] || AnimalType.calvingCow : AnimalType.calvingCow;
        }
        case AnimalType.heifer: {
            return strings ? strings["enum"]["AnimalType"]["heifer"] || AnimalType.heifer : AnimalType.heifer;
        }
        case AnimalType.ox: {
            return strings ? strings["enum"]["AnimalType"]["ox"] || AnimalType.ox : AnimalType.ox;
        }
        case AnimalType.calf: {
            return strings ? strings["enum"]["AnimalType"]["calf"] || AnimalType.calf : AnimalType.calf;
        }
        case AnimalType.buffalo: {
            return strings ? strings["enum"]["AnimalType"]["buffalo"] || AnimalType.buffalo : AnimalType.buffalo;
        }
        case AnimalType.buffaloFemale: {
            return strings ? strings["enum"]["AnimalType"]["buffaloFemale"] || AnimalType.buffaloFemale : AnimalType.buffaloFemale;
        }
        case AnimalType.pig: {
            return strings ? strings["enum"]["AnimalType"]["pig"] || AnimalType.pig : AnimalType.pig;
        }
        case AnimalType.pigFemale: {
            return strings ? strings["enum"]["AnimalType"]["pigFemale"] || AnimalType.pigFemale : AnimalType.pigFemale;
        }
        case AnimalType.pigYoung: {
            return strings ? strings["enum"]["AnimalType"]["pigYoung"] || AnimalType.pigYoung : AnimalType.pigYoung;
        }
        case AnimalType.goatMale: {
            return strings ? strings["enum"]["AnimalType"]["goatMale"] || AnimalType.goatMale : AnimalType.goatMale;
        }
        case AnimalType.goatFemale: {
            return strings ? strings["enum"]["AnimalType"]["goatFemale"] || AnimalType.goatFemale : AnimalType.goatFemale;
        }
        case AnimalType.goatYoung: {
            return strings ? strings["enum"]["AnimalType"]["goatYoung"] || AnimalType.goatYoung : AnimalType.goatYoung;
        }
        case AnimalType.ram: {
            return strings ? strings["enum"]["AnimalType"]["ram"] || AnimalType.ram : AnimalType.ram;
        }
        case AnimalType.sheep: {
            return strings ? strings["enum"]["AnimalType"]["sheep"] || AnimalType.sheep : AnimalType.sheep;
        }
        case AnimalType.lamb: {
            return strings ? strings["enum"]["AnimalType"]["lamb"] || AnimalType.lamb : AnimalType.lamb;
        }
        case AnimalType.horse: {
            return strings ? strings["enum"]["AnimalType"]["horse"] || AnimalType.horse : AnimalType.horse;
        }
        case AnimalType.mare: {
            return strings ? strings["enum"]["AnimalType"]["mare"] || AnimalType.mare : AnimalType.mare;
        }
        case AnimalType.calvingMare: {
            return strings ? strings["enum"]["AnimalType"]["calvingMare"] || AnimalType.calvingMare : AnimalType.calvingMare;
        }
        case AnimalType.horseYoung: {
            return strings ? strings["enum"]["AnimalType"]["horseYoung"] || AnimalType.horseYoung : AnimalType.horseYoung;
        }
        case AnimalType.jack: {
            return strings ? strings["enum"]["AnimalType"]["jack"] || AnimalType.jack : AnimalType.jack;
        }
        case AnimalType.donkey: {
            return strings ? strings["enum"]["AnimalType"]["donkey"] || AnimalType.donkey : AnimalType.donkey;
        }
        case AnimalType.mule: {
            return strings ? strings["enum"]["AnimalType"]["mule"] || AnimalType.mule : AnimalType.mule;
        }
        case AnimalType.hen: {
            return strings ? strings["enum"]["AnimalType"]["hen"] || AnimalType.hen : AnimalType.hen;
        }
        case AnimalType.chick: {
            return strings ? strings["enum"]["AnimalType"]["chick"] || AnimalType.chick : AnimalType.chick;
        }
        case AnimalType.chicken: {
            return strings ? strings["enum"]["AnimalType"]["chicken"] || AnimalType.chicken : AnimalType.chicken;
        }
        case AnimalType.quail: {
            return strings ? strings["enum"]["AnimalType"]["quail"] || AnimalType.quail : AnimalType.quail;
        }
        case AnimalType.peru: {
            return strings ? strings["enum"]["AnimalType"]["peru"] || AnimalType.peru : AnimalType.peru;
        }
    }
    return "";
}

export function allValuesAnimalType(): AnimalType[] {
    return [
        AnimalType.cow,
        AnimalType.calvingCow,
        AnimalType.heifer,
        AnimalType.ox,
        AnimalType.calf,
        AnimalType.buffalo,
        AnimalType.buffaloFemale,
        AnimalType.pig,
        AnimalType.pigFemale,
        AnimalType.pigYoung,
        AnimalType.goatMale,
        AnimalType.goatFemale,
        AnimalType.goatYoung,
        AnimalType.ram,
        AnimalType.sheep,
        AnimalType.lamb,
        AnimalType.horse,
        AnimalType.mare,
        AnimalType.calvingMare,
        AnimalType.horseYoung,
        AnimalType.jack,
        AnimalType.donkey,
        AnimalType.mule,
        AnimalType.hen,
        AnimalType.chick,
        AnimalType.chicken,
        AnimalType.quail,
        AnimalType.peru,
    ];
}

export function allTranslatedValuesAnimalType(): string[] {
    return [
        translateAnimalType(AnimalType.cow),
        translateAnimalType(AnimalType.calvingCow),
        translateAnimalType(AnimalType.heifer),
        translateAnimalType(AnimalType.ox),
        translateAnimalType(AnimalType.calf),
        translateAnimalType(AnimalType.buffalo),
        translateAnimalType(AnimalType.buffaloFemale),
        translateAnimalType(AnimalType.pig),
        translateAnimalType(AnimalType.pigFemale),
        translateAnimalType(AnimalType.pigYoung),
        translateAnimalType(AnimalType.goatMale),
        translateAnimalType(AnimalType.goatFemale),
        translateAnimalType(AnimalType.goatYoung),
        translateAnimalType(AnimalType.ram),
        translateAnimalType(AnimalType.sheep),
        translateAnimalType(AnimalType.lamb),
        translateAnimalType(AnimalType.horse),
        translateAnimalType(AnimalType.mare),
        translateAnimalType(AnimalType.calvingMare),
        translateAnimalType(AnimalType.horseYoung),
        translateAnimalType(AnimalType.jack),
        translateAnimalType(AnimalType.donkey),
        translateAnimalType(AnimalType.mule),
        translateAnimalType(AnimalType.hen),
        translateAnimalType(AnimalType.chick),
        translateAnimalType(AnimalType.chicken),
        translateAnimalType(AnimalType.quail),
        translateAnimalType(AnimalType.peru),
    ];
}

export function allDisplayableValuesAnimalType(): string[] {
    return allTranslatedValuesAnimalType().sort();
}

export function valueFromTranslationAnimalType(translation: string): AnimalType {
    const index = allTranslatedValuesAnimalType().indexOf(translation);
    return allValuesAnimalType()[index] || AnimalType.cow;
}

export enum VehicleAxis {
    threeQuarters = "threeQuarters",
    stump = "stump",
    truck = "truck",
    doubleTruck = "doubleTruck",
    semiTrailerHigh = "semiTrailerHigh",
    semiTrailerLow = "semiTrailerLow",
    doubleTrainHigh = "doubleTrainHigh",
    doubleTrainLow = "doubleTrainLow",
    roadTrainHigh = "roadTrainHigh",
    roadTrainLow = "roadTrainLow",
}

export function translateVehicleAxis(enumVehicleAxis: VehicleAxis): string {
    switch (enumVehicleAxis) {
        case VehicleAxis.threeQuarters: {
            return strings ? strings["enum"]["VehicleAxis"]["threeQuarters"] || VehicleAxis.threeQuarters : VehicleAxis.threeQuarters;
        }
        case VehicleAxis.stump: {
            return strings ? strings["enum"]["VehicleAxis"]["stump"] || VehicleAxis.stump : VehicleAxis.stump;
        }
        case VehicleAxis.truck: {
            return strings ? strings["enum"]["VehicleAxis"]["truck"] || VehicleAxis.truck : VehicleAxis.truck;
        }
        case VehicleAxis.doubleTruck: {
            return strings ? strings["enum"]["VehicleAxis"]["doubleTruck"] || VehicleAxis.doubleTruck : VehicleAxis.doubleTruck;
        }
        case VehicleAxis.semiTrailerHigh: {
            return strings ? strings["enum"]["VehicleAxis"]["semiTrailerHigh"] || VehicleAxis.semiTrailerHigh : VehicleAxis.semiTrailerHigh;
        }
        case VehicleAxis.semiTrailerLow: {
            return strings ? strings["enum"]["VehicleAxis"]["semiTrailerLow"] || VehicleAxis.semiTrailerLow : VehicleAxis.semiTrailerLow;
        }
        case VehicleAxis.doubleTrainHigh: {
            return strings ? strings["enum"]["VehicleAxis"]["doubleTrainHigh"] || VehicleAxis.doubleTrainHigh : VehicleAxis.doubleTrainHigh;
        }
        case VehicleAxis.doubleTrainLow: {
            return strings ? strings["enum"]["VehicleAxis"]["doubleTrainLow"] || VehicleAxis.doubleTrainLow : VehicleAxis.doubleTrainLow;
        }
        case VehicleAxis.roadTrainHigh: {
            return strings ? strings["enum"]["VehicleAxis"]["roadTrainHigh"] || VehicleAxis.roadTrainHigh : VehicleAxis.roadTrainHigh;
        }
        case VehicleAxis.roadTrainLow: {
            return strings ? strings["enum"]["VehicleAxis"]["roadTrainLow"] || VehicleAxis.roadTrainLow : VehicleAxis.roadTrainLow;
        }
    }
    return "";
}

export function allValuesVehicleAxis(): VehicleAxis[] {
    return [
        VehicleAxis.threeQuarters,
        VehicleAxis.stump,
        VehicleAxis.truck,
        VehicleAxis.doubleTruck,
        VehicleAxis.semiTrailerHigh,
        VehicleAxis.semiTrailerLow,
        VehicleAxis.doubleTrainHigh,
        VehicleAxis.doubleTrainLow,
        VehicleAxis.roadTrainHigh,
        VehicleAxis.roadTrainLow,
    ];
}

export function allTranslatedValuesVehicleAxis(): string[] {
    return [
        translateVehicleAxis(VehicleAxis.threeQuarters),
        translateVehicleAxis(VehicleAxis.stump),
        translateVehicleAxis(VehicleAxis.truck),
        translateVehicleAxis(VehicleAxis.doubleTruck),
        translateVehicleAxis(VehicleAxis.semiTrailerHigh),
        translateVehicleAxis(VehicleAxis.semiTrailerLow),
        translateVehicleAxis(VehicleAxis.doubleTrainHigh),
        translateVehicleAxis(VehicleAxis.doubleTrainLow),
        translateVehicleAxis(VehicleAxis.roadTrainHigh),
        translateVehicleAxis(VehicleAxis.roadTrainLow),
    ];
}

export function allDisplayableValuesVehicleAxis(): string[] {
    return allTranslatedValuesVehicleAxis().sort();
}

export function valueFromTranslationVehicleAxis(translation: string): VehicleAxis {
    const index = allTranslatedValuesVehicleAxis().indexOf(translation);
    return allValuesVehicleAxis()[index] || VehicleAxis.threeQuarters;
}

export enum ProposalStatus {
    pendingDriver = "pendingDriver",
    pendingContractor = "pendingContractor",
    accepted = "accepted",
    refused = "refused",
}

export function translateProposalStatus(enumProposalStatus: ProposalStatus): string {
    switch (enumProposalStatus) {
        case ProposalStatus.pendingDriver: {
            return strings ? strings["enum"]["ProposalStatus"]["pendingDriver"] || ProposalStatus.pendingDriver : ProposalStatus.pendingDriver;
        }
        case ProposalStatus.pendingContractor: {
            return strings ? strings["enum"]["ProposalStatus"]["pendingContractor"] || ProposalStatus.pendingContractor : ProposalStatus.pendingContractor;
        }
        case ProposalStatus.accepted: {
            return strings ? strings["enum"]["ProposalStatus"]["accepted"] || ProposalStatus.accepted : ProposalStatus.accepted;
        }
        case ProposalStatus.refused: {
            return strings ? strings["enum"]["ProposalStatus"]["refused"] || ProposalStatus.refused : ProposalStatus.refused;
        }
    }
    return "";
}

export function allValuesProposalStatus(): ProposalStatus[] {
    return [
        ProposalStatus.pendingDriver,
        ProposalStatus.pendingContractor,
        ProposalStatus.accepted,
        ProposalStatus.refused,
    ];
}

export function allTranslatedValuesProposalStatus(): string[] {
    return [
        translateProposalStatus(ProposalStatus.pendingDriver),
        translateProposalStatus(ProposalStatus.pendingContractor),
        translateProposalStatus(ProposalStatus.accepted),
        translateProposalStatus(ProposalStatus.refused),
    ];
}

export function allDisplayableValuesProposalStatus(): string[] {
    return allTranslatedValuesProposalStatus().sort();
}

export function valueFromTranslationProposalStatus(translation: string): ProposalStatus {
    const index = allTranslatedValuesProposalStatus().indexOf(translation);
    return allValuesProposalStatus()[index] || ProposalStatus.pendingDriver;
}

export enum ShippingStatusOptions {
    accepted = "accepted",
    initiated = "initiated",
    deliveredToDriver = "deliveredToDriver",
    deliveredToDestination = "deliveredToDestination",
    received = "received",
    canceled = "canceled",
}

export function translateShippingStatusOptions(enumShippingStatusOptions: ShippingStatusOptions): string {
    switch (enumShippingStatusOptions) {
        case ShippingStatusOptions.accepted: {
            return strings ? strings["enum"]["ShippingStatusOptions"]["accepted"] || ShippingStatusOptions.accepted : ShippingStatusOptions.accepted;
        }
        case ShippingStatusOptions.initiated: {
            return strings ? strings["enum"]["ShippingStatusOptions"]["initiated"] || ShippingStatusOptions.initiated : ShippingStatusOptions.initiated;
        }
        case ShippingStatusOptions.deliveredToDriver: {
            return strings ? strings["enum"]["ShippingStatusOptions"]["deliveredToDriver"] || ShippingStatusOptions.deliveredToDriver : ShippingStatusOptions.deliveredToDriver;
        }
        case ShippingStatusOptions.deliveredToDestination: {
            return strings ? strings["enum"]["ShippingStatusOptions"]["deliveredToDestination"] || ShippingStatusOptions.deliveredToDestination : ShippingStatusOptions.deliveredToDestination;
        }
        case ShippingStatusOptions.received: {
            return strings ? strings["enum"]["ShippingStatusOptions"]["received"] || ShippingStatusOptions.received : ShippingStatusOptions.received;
        }
        case ShippingStatusOptions.canceled: {
            return strings ? strings["enum"]["ShippingStatusOptions"]["canceled"] || ShippingStatusOptions.canceled : ShippingStatusOptions.canceled;
        }
    }
    return "";
}

export function allValuesShippingStatusOptions(): ShippingStatusOptions[] {
    return [
        ShippingStatusOptions.accepted,
        ShippingStatusOptions.initiated,
        ShippingStatusOptions.deliveredToDriver,
        ShippingStatusOptions.deliveredToDestination,
        ShippingStatusOptions.received,
        ShippingStatusOptions.canceled,
    ];
}

export function allTranslatedValuesShippingStatusOptions(): string[] {
    return [
        translateShippingStatusOptions(ShippingStatusOptions.accepted),
        translateShippingStatusOptions(ShippingStatusOptions.initiated),
        translateShippingStatusOptions(ShippingStatusOptions.deliveredToDriver),
        translateShippingStatusOptions(ShippingStatusOptions.deliveredToDestination),
        translateShippingStatusOptions(ShippingStatusOptions.received),
        translateShippingStatusOptions(ShippingStatusOptions.canceled),
    ];
}

export function allDisplayableValuesShippingStatusOptions(): string[] {
    return allTranslatedValuesShippingStatusOptions().sort();
}

export function valueFromTranslationShippingStatusOptions(translation: string): ShippingStatusOptions {
    const index = allTranslatedValuesShippingStatusOptions().indexOf(translation);
    return allValuesShippingStatusOptions()[index] || ShippingStatusOptions.accepted;
}

export enum NotificationType {
    redirectToShipping = "redirectToShipping",
}

export function translateNotificationType(enumNotificationType: NotificationType): string {
    switch (enumNotificationType) {
        case NotificationType.redirectToShipping: {
            return strings ? strings["enum"]["NotificationType"]["redirectToShipping"] || NotificationType.redirectToShipping : NotificationType.redirectToShipping;
        }
    }
    return "";
}

export function allValuesNotificationType(): NotificationType[] {
    return [
        NotificationType.redirectToShipping,
    ];
}

export function allTranslatedValuesNotificationType(): string[] {
    return [
        translateNotificationType(NotificationType.redirectToShipping),
    ];
}

export function allDisplayableValuesNotificationType(): string[] {
    return allTranslatedValuesNotificationType().sort();
}

export function valueFromTranslationNotificationType(translation: string): NotificationType {
    const index = allTranslatedValuesNotificationType().indexOf(translation);
    return allValuesNotificationType()[index] || NotificationType.redirectToShipping;
}

export enum UserType {
    driver = "driver",
    contractor = "contractor",
}

export function translateUserType(enumUserType: UserType): string {
    switch (enumUserType) {
        case UserType.driver: {
            return strings ? strings["enum"]["UserType"]["driver"] || UserType.driver : UserType.driver;
        }
        case UserType.contractor: {
            return strings ? strings["enum"]["UserType"]["contractor"] || UserType.contractor : UserType.contractor;
        }
    }
    return "";
}

export function allValuesUserType(): UserType[] {
    return [
        UserType.driver,
        UserType.contractor,
    ];
}

export function allTranslatedValuesUserType(): string[] {
    return [
        translateUserType(UserType.driver),
        translateUserType(UserType.contractor),
    ];
}

export function allDisplayableValuesUserType(): string[] {
    return allTranslatedValuesUserType().sort();
}

export function valueFromTranslationUserType(translation: string): UserType {
    const index = allTranslatedValuesUserType().indexOf(translation);
    return allValuesUserType()[index] || UserType.driver;
}

export enum StateUF {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    DF = "DF",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",
}

export function translateStateUF(enumStateUF: StateUF): string {
    switch (enumStateUF) {
        case StateUF.AC: {
            return strings ? strings["enum"]["StateUF"]["AC"] || StateUF.AC : StateUF.AC;
        }
        case StateUF.AL: {
            return strings ? strings["enum"]["StateUF"]["AL"] || StateUF.AL : StateUF.AL;
        }
        case StateUF.AP: {
            return strings ? strings["enum"]["StateUF"]["AP"] || StateUF.AP : StateUF.AP;
        }
        case StateUF.AM: {
            return strings ? strings["enum"]["StateUF"]["AM"] || StateUF.AM : StateUF.AM;
        }
        case StateUF.BA: {
            return strings ? strings["enum"]["StateUF"]["BA"] || StateUF.BA : StateUF.BA;
        }
        case StateUF.CE: {
            return strings ? strings["enum"]["StateUF"]["CE"] || StateUF.CE : StateUF.CE;
        }
        case StateUF.DF: {
            return strings ? strings["enum"]["StateUF"]["DF"] || StateUF.DF : StateUF.DF;
        }
        case StateUF.ES: {
            return strings ? strings["enum"]["StateUF"]["ES"] || StateUF.ES : StateUF.ES;
        }
        case StateUF.GO: {
            return strings ? strings["enum"]["StateUF"]["GO"] || StateUF.GO : StateUF.GO;
        }
        case StateUF.MA: {
            return strings ? strings["enum"]["StateUF"]["MA"] || StateUF.MA : StateUF.MA;
        }
        case StateUF.MT: {
            return strings ? strings["enum"]["StateUF"]["MT"] || StateUF.MT : StateUF.MT;
        }
        case StateUF.MS: {
            return strings ? strings["enum"]["StateUF"]["MS"] || StateUF.MS : StateUF.MS;
        }
        case StateUF.MG: {
            return strings ? strings["enum"]["StateUF"]["MG"] || StateUF.MG : StateUF.MG;
        }
        case StateUF.PA: {
            return strings ? strings["enum"]["StateUF"]["PA"] || StateUF.PA : StateUF.PA;
        }
        case StateUF.PB: {
            return strings ? strings["enum"]["StateUF"]["PB"] || StateUF.PB : StateUF.PB;
        }
        case StateUF.PR: {
            return strings ? strings["enum"]["StateUF"]["PR"] || StateUF.PR : StateUF.PR;
        }
        case StateUF.PE: {
            return strings ? strings["enum"]["StateUF"]["PE"] || StateUF.PE : StateUF.PE;
        }
        case StateUF.PI: {
            return strings ? strings["enum"]["StateUF"]["PI"] || StateUF.PI : StateUF.PI;
        }
        case StateUF.RJ: {
            return strings ? strings["enum"]["StateUF"]["RJ"] || StateUF.RJ : StateUF.RJ;
        }
        case StateUF.RN: {
            return strings ? strings["enum"]["StateUF"]["RN"] || StateUF.RN : StateUF.RN;
        }
        case StateUF.RS: {
            return strings ? strings["enum"]["StateUF"]["RS"] || StateUF.RS : StateUF.RS;
        }
        case StateUF.RO: {
            return strings ? strings["enum"]["StateUF"]["RO"] || StateUF.RO : StateUF.RO;
        }
        case StateUF.RR: {
            return strings ? strings["enum"]["StateUF"]["RR"] || StateUF.RR : StateUF.RR;
        }
        case StateUF.SC: {
            return strings ? strings["enum"]["StateUF"]["SC"] || StateUF.SC : StateUF.SC;
        }
        case StateUF.SP: {
            return strings ? strings["enum"]["StateUF"]["SP"] || StateUF.SP : StateUF.SP;
        }
        case StateUF.SE: {
            return strings ? strings["enum"]["StateUF"]["SE"] || StateUF.SE : StateUF.SE;
        }
        case StateUF.TO: {
            return strings ? strings["enum"]["StateUF"]["TO"] || StateUF.TO : StateUF.TO;
        }
    }
    return "";
}

export function allValuesStateUF(): StateUF[] {
    return [
        StateUF.AC,
        StateUF.AL,
        StateUF.AP,
        StateUF.AM,
        StateUF.BA,
        StateUF.CE,
        StateUF.DF,
        StateUF.ES,
        StateUF.GO,
        StateUF.MA,
        StateUF.MT,
        StateUF.MS,
        StateUF.MG,
        StateUF.PA,
        StateUF.PB,
        StateUF.PR,
        StateUF.PE,
        StateUF.PI,
        StateUF.RJ,
        StateUF.RN,
        StateUF.RS,
        StateUF.RO,
        StateUF.RR,
        StateUF.SC,
        StateUF.SP,
        StateUF.SE,
        StateUF.TO,
    ];
}

export function allTranslatedValuesStateUF(): string[] {
    return [
        translateStateUF(StateUF.AC),
        translateStateUF(StateUF.AL),
        translateStateUF(StateUF.AP),
        translateStateUF(StateUF.AM),
        translateStateUF(StateUF.BA),
        translateStateUF(StateUF.CE),
        translateStateUF(StateUF.DF),
        translateStateUF(StateUF.ES),
        translateStateUF(StateUF.GO),
        translateStateUF(StateUF.MA),
        translateStateUF(StateUF.MT),
        translateStateUF(StateUF.MS),
        translateStateUF(StateUF.MG),
        translateStateUF(StateUF.PA),
        translateStateUF(StateUF.PB),
        translateStateUF(StateUF.PR),
        translateStateUF(StateUF.PE),
        translateStateUF(StateUF.PI),
        translateStateUF(StateUF.RJ),
        translateStateUF(StateUF.RN),
        translateStateUF(StateUF.RS),
        translateStateUF(StateUF.RO),
        translateStateUF(StateUF.RR),
        translateStateUF(StateUF.SC),
        translateStateUF(StateUF.SP),
        translateStateUF(StateUF.SE),
        translateStateUF(StateUF.TO),
    ];
}

export function allDisplayableValuesStateUF(): string[] {
    return allTranslatedValuesStateUF().sort();
}

export function valueFromTranslationStateUF(translation: string): StateUF {
    const index = allTranslatedValuesStateUF().indexOf(translation);
    return allValuesStateUF()[index] || StateUF.AC;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    BadFormattedResponse = "BadFormattedResponse",
    ActionNotAllowed = "ActionNotAllowed",
    InvalidDate = "InvalidDate",
    FailedUpload = "FailedUpload",
    MissingEnvVariable = "MissingEnvVariable",
    InternalError = "InternalError",
    FieldAlreadyInUse = "FieldAlreadyInUse",
    WrongPassword = "WrongPassword",
    EmptyArray = "EmptyArray",
    NotApproved = "NotApproved",
    NotLoggedIn = "NotLoggedIn",
    UserBlocked = "UserBlocked",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    EmailAlreadyInUse = "EmailAlreadyInUse",
    InvalidEmail = "InvalidEmail",
    ExpiredResetPasswordToken = "ExpiredResetPasswordToken",
    LoginError = "LoginError",
    UserDoesntExist = "UserDoesntExist",
    AlreadyRegistered = "AlreadyRegistered",
    CpfAlreadyInUse = "CpfAlreadyInUse",
    Validation = "Validation",
    MissingPermission = "MissingPermission",
    RequiredWeight = "RequiredWeight",
    MissingPaymentInfo = "MissingPaymentInfo",
    TransferValueTooLow = "TransferValueTooLow",
    InsufficientFunds = "InsufficientFunds",
    DeviceNotFound = "DeviceNotFound",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.ActionNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["ActionNotAllowed"] || ErrorType.ActionNotAllowed : ErrorType.ActionNotAllowed;
        }
        case ErrorType.InvalidDate: {
            return strings ? strings["enum"]["ErrorType"]["InvalidDate"] || ErrorType.InvalidDate : ErrorType.InvalidDate;
        }
        case ErrorType.FailedUpload: {
            return strings ? strings["enum"]["ErrorType"]["FailedUpload"] || ErrorType.FailedUpload : ErrorType.FailedUpload;
        }
        case ErrorType.MissingEnvVariable: {
            return strings ? strings["enum"]["ErrorType"]["MissingEnvVariable"] || ErrorType.MissingEnvVariable : ErrorType.MissingEnvVariable;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.FieldAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["FieldAlreadyInUse"] || ErrorType.FieldAlreadyInUse : ErrorType.FieldAlreadyInUse;
        }
        case ErrorType.WrongPassword: {
            return strings ? strings["enum"]["ErrorType"]["WrongPassword"] || ErrorType.WrongPassword : ErrorType.WrongPassword;
        }
        case ErrorType.EmptyArray: {
            return strings ? strings["enum"]["ErrorType"]["EmptyArray"] || ErrorType.EmptyArray : ErrorType.EmptyArray;
        }
        case ErrorType.NotApproved: {
            return strings ? strings["enum"]["ErrorType"]["NotApproved"] || ErrorType.NotApproved : ErrorType.NotApproved;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.UserBlocked: {
            return strings ? strings["enum"]["ErrorType"]["UserBlocked"] || ErrorType.UserBlocked : ErrorType.UserBlocked;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.EmailAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyInUse"] || ErrorType.EmailAlreadyInUse : ErrorType.EmailAlreadyInUse;
        }
        case ErrorType.InvalidEmail: {
            return strings ? strings["enum"]["ErrorType"]["InvalidEmail"] || ErrorType.InvalidEmail : ErrorType.InvalidEmail;
        }
        case ErrorType.ExpiredResetPasswordToken: {
            return strings ? strings["enum"]["ErrorType"]["ExpiredResetPasswordToken"] || ErrorType.ExpiredResetPasswordToken : ErrorType.ExpiredResetPasswordToken;
        }
        case ErrorType.LoginError: {
            return strings ? strings["enum"]["ErrorType"]["LoginError"] || ErrorType.LoginError : ErrorType.LoginError;
        }
        case ErrorType.UserDoesntExist: {
            return strings ? strings["enum"]["ErrorType"]["UserDoesntExist"] || ErrorType.UserDoesntExist : ErrorType.UserDoesntExist;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.CpfAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["CpfAlreadyInUse"] || ErrorType.CpfAlreadyInUse : ErrorType.CpfAlreadyInUse;
        }
        case ErrorType.Validation: {
            return strings ? strings["enum"]["ErrorType"]["Validation"] || ErrorType.Validation : ErrorType.Validation;
        }
        case ErrorType.MissingPermission: {
            return strings ? strings["enum"]["ErrorType"]["MissingPermission"] || ErrorType.MissingPermission : ErrorType.MissingPermission;
        }
        case ErrorType.RequiredWeight: {
            return strings ? strings["enum"]["ErrorType"]["RequiredWeight"] || ErrorType.RequiredWeight : ErrorType.RequiredWeight;
        }
        case ErrorType.MissingPaymentInfo: {
            return strings ? strings["enum"]["ErrorType"]["MissingPaymentInfo"] || ErrorType.MissingPaymentInfo : ErrorType.MissingPaymentInfo;
        }
        case ErrorType.TransferValueTooLow: {
            return strings ? strings["enum"]["ErrorType"]["TransferValueTooLow"] || ErrorType.TransferValueTooLow : ErrorType.TransferValueTooLow;
        }
        case ErrorType.InsufficientFunds: {
            return strings ? strings["enum"]["ErrorType"]["InsufficientFunds"] || ErrorType.InsufficientFunds : ErrorType.InsufficientFunds;
        }
        case ErrorType.DeviceNotFound: {
            return strings ? strings["enum"]["ErrorType"]["DeviceNotFound"] || ErrorType.DeviceNotFound : ErrorType.DeviceNotFound;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.BadFormattedResponse,
        ErrorType.ActionNotAllowed,
        ErrorType.InvalidDate,
        ErrorType.FailedUpload,
        ErrorType.MissingEnvVariable,
        ErrorType.InternalError,
        ErrorType.FieldAlreadyInUse,
        ErrorType.WrongPassword,
        ErrorType.EmptyArray,
        ErrorType.NotApproved,
        ErrorType.NotLoggedIn,
        ErrorType.UserBlocked,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.EmailAlreadyInUse,
        ErrorType.InvalidEmail,
        ErrorType.ExpiredResetPasswordToken,
        ErrorType.LoginError,
        ErrorType.UserDoesntExist,
        ErrorType.AlreadyRegistered,
        ErrorType.CpfAlreadyInUse,
        ErrorType.Validation,
        ErrorType.MissingPermission,
        ErrorType.RequiredWeight,
        ErrorType.MissingPaymentInfo,
        ErrorType.TransferValueTooLow,
        ErrorType.InsufficientFunds,
        ErrorType.DeviceNotFound,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.ActionNotAllowed),
        translateErrorType(ErrorType.InvalidDate),
        translateErrorType(ErrorType.FailedUpload),
        translateErrorType(ErrorType.MissingEnvVariable),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.FieldAlreadyInUse),
        translateErrorType(ErrorType.WrongPassword),
        translateErrorType(ErrorType.EmptyArray),
        translateErrorType(ErrorType.NotApproved),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.UserBlocked),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.EmailAlreadyInUse),
        translateErrorType(ErrorType.InvalidEmail),
        translateErrorType(ErrorType.ExpiredResetPasswordToken),
        translateErrorType(ErrorType.LoginError),
        translateErrorType(ErrorType.UserDoesntExist),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.CpfAlreadyInUse),
        translateErrorType(ErrorType.Validation),
        translateErrorType(ErrorType.MissingPermission),
        translateErrorType(ErrorType.RequiredWeight),
        translateErrorType(ErrorType.MissingPaymentInfo),
        translateErrorType(ErrorType.TransferValueTooLow),
        translateErrorType(ErrorType.InsufficientFunds),
        translateErrorType(ErrorType.DeviceNotFound),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, imageCrop: ImageCrop | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
        imageCrop: imageCrop === null || imageCrop === undefined ? null : {
            x: imageCrop.x || 0,
            y: imageCrop.y || 0,
            width: imageCrop.width || 0,
            height: imageCrop.height || 0,
        },
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, imageCrop: ImageCrop | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
        imageCrop: imageCrop === null || imageCrop === undefined ? null : {
            x: imageCrop.x || 0,
            y: imageCrop.y || 0,
            width: imageCrop.width || 0,
            height: imageCrop.height || 0,
        },
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function cropImage(src: string, imageCrop: ImageCrop, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        src: src,
        imageCrop: {
            x: imageCrop.x || 0,
            y: imageCrop.y || 0,
            width: imageCrop.width || 0,
            height: imageCrop.height || 0,
        },
    };
    const ret = await makeRequest({name: "cropImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadFile(file: UploadFile, progress?: (progress: number) => void): Promise<File> {
    const args = {
        file: {
            bytes: file.bytes.toString("base64"),
            name: file.name,
        },
    };
    const ret = await makeRequest({name: "uploadFile", args, progress});
    return {
        name: ret.name,
        url: ret.url,
    };
}

export async function getCurrentAdminUser(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminUser", args: {}, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
    };
}

export async function getAdminUser(adminUserId: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
    };
    const ret = await makeRequest({name: "getAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
    };
}

export async function getAdminUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        email: e.email,
    }));
}

export async function createAdminUser(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            password: newAdminUser.password,
            name: newAdminUser.name,
            email: newAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
    };
}

export async function editAdminUser(adminUserId: string, editedAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
        editedAdminUser: {
            name: editedAdminUser.name,
            email: editedAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
    };
}

export async function deleteAdminUser(adminUserId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        adminUserId: adminUserId,
    };
    await makeRequest({name: "deleteAdminUser", args, progress});
    return undefined;
}

export async function login(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "login", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
    };
}

export async function logout(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logout", args: {}, progress});
    return undefined;
}

export async function sendResetPasswordAdminUser(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendResetPasswordAdminUser", args, progress});
    return undefined;
}

export async function resetPasswordEmailAdminUser(token: string, password: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        password: password,
    };
    await makeRequest({name: "resetPasswordEmailAdminUser", args, progress});
    return undefined;
}

export async function validateToken(token: string, progress?: (progress: number) => void): Promise<string> {
    const args = {
        token: token,
    };
    const ret = await makeRequest({name: "validateToken", args, progress});
    return ret;
}

export async function editUser(userId: string, editedUser: UserDetails, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
        editedUser: {
            name: editedUser.name,
            email: editedUser.email,
            phone: editedUser.phone,
            cpf: editedUser.cpf === null || editedUser.cpf === undefined ? null : editedUser.cpf,
            image: editedUser.image === null || editedUser.image === undefined ? null : {
                thumb: {
                    width: editedUser.image.thumb.width || 0,
                    height: editedUser.image.thumb.height || 0,
                    url: editedUser.image.thumb.url,
                },
                width: editedUser.image.width || 0,
                height: editedUser.image.height || 0,
                url: editedUser.image.url,
            },
            address: editedUser.address === null || editedUser.address === undefined ? null : {
                zipcode: editedUser.address.zipcode,
                street: editedUser.address.street,
                streetNumber: editedUser.address.streetNumber,
                complementary: editedUser.address.complementary === null || editedUser.address.complementary === undefined ? null : editedUser.address.complementary,
                neighborhood: editedUser.address.neighborhood,
                city: editedUser.address.city,
                state: editedUser.address.state,
                country: editedUser.address.country,
                countryCode: editedUser.address.countryCode,
                latLng: {
                    lat: editedUser.address.latLng.lat,
                    lng: editedUser.address.latLng.lng,
                },
            },
            type: editedUser.type,
        },
    };
    const ret = await makeRequest({name: "editUser", args, progress});
    return {
        id: ret.id,
        ratingAverage: ret.ratingAverage || 0,
        vehicles: ret.vehicles === null || ret.vehicles === undefined ? null : ret.vehicles.map((e: any) => ({
            id: e.id,
            model: e.model,
            axisType: e.axisType,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
        name: ret.name,
        email: ret.email,
        phone: ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            country: ret.address.country,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        type: ret.type,
    };
}

export async function deleteUser(userId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        userId: userId,
    };
    await makeRequest({name: "deleteUser", args, progress});
    return undefined;
}

export async function blockUser(userId: string, isBlocked: boolean, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
        isBlocked: !!isBlocked,
    };
    const ret = await makeRequest({name: "blockUser", args, progress});
    return {
        id: ret.id,
        ratingAverage: ret.ratingAverage || 0,
        vehicles: ret.vehicles === null || ret.vehicles === undefined ? null : ret.vehicles.map((e: any) => ({
            id: e.id,
            model: e.model,
            axisType: e.axisType,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
        name: ret.name,
        email: ret.email,
        phone: ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            country: ret.address.country,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        type: ret.type,
    };
}

export async function createUser(newUser: NewUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newUser: {
            password: newUser.password,
            type: newUser.type,
            cpf: newUser.cpf === null || newUser.cpf === undefined ? null : newUser.cpf,
            name: newUser.name,
            email: newUser.email,
            phone: newUser.phone,
            image: newUser.image === null || newUser.image === undefined ? null : {
                thumb: {
                    width: newUser.image.thumb.width || 0,
                    height: newUser.image.thumb.height || 0,
                    url: newUser.image.thumb.url,
                },
                width: newUser.image.width || 0,
                height: newUser.image.height || 0,
                url: newUser.image.url,
            },
            address: newUser.address === null || newUser.address === undefined ? null : {
                zipcode: newUser.address.zipcode,
                street: newUser.address.street,
                streetNumber: newUser.address.streetNumber,
                complementary: newUser.address.complementary === null || newUser.address.complementary === undefined ? null : newUser.address.complementary,
                neighborhood: newUser.address.neighborhood,
                city: newUser.address.city,
                state: newUser.address.state,
                country: newUser.address.country,
                countryCode: newUser.address.countryCode,
                latLng: {
                    lat: newUser.address.latLng.lat,
                    lng: newUser.address.latLng.lng,
                },
            },
        },
    };
    const ret = await makeRequest({name: "createUser", args, progress});
    return {
        id: ret.id,
        ratingAverage: ret.ratingAverage || 0,
        vehicles: ret.vehicles === null || ret.vehicles === undefined ? null : ret.vehicles.map((e: any) => ({
            id: e.id,
            model: e.model,
            axisType: e.axisType,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
        name: ret.name,
        email: ret.email,
        phone: ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            country: ret.address.country,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        type: ret.type,
    };
}

export async function getUserById(userId: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
    };
    const ret = await makeRequest({name: "getUserById", args, progress});
    return {
        id: ret.id,
        ratingAverage: ret.ratingAverage || 0,
        vehicles: ret.vehicles === null || ret.vehicles === undefined ? null : ret.vehicles.map((e: any) => ({
            id: e.id,
            model: e.model,
            axisType: e.axisType,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        editedAt: new Date(ret.editedAt + "Z"),
        name: ret.name,
        email: ret.email,
        phone: ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            country: ret.address.country,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        type: ret.type,
    };
}

export async function getUsers(pageOffset: number, userType: UserType | null, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffset: pageOffset || 0,
        userType: userType === null || userType === undefined ? null : userType,
    };
    const ret = await makeRequest({name: "getUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        ratingAverage: e.ratingAverage || 0,
        vehicles: e.vehicles === null || e.vehicles === undefined ? null : e.vehicles.map((e: any) => ({
            id: e.id,
            model: e.model,
            axisType: e.axisType,
        })),
        createdAt: new Date(e.createdAt + "Z"),
        editedAt: new Date(e.editedAt + "Z"),
        name: e.name,
        email: e.email,
        phone: e.phone,
        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        address: e.address === null || e.address === undefined ? null : {
            zipcode: e.address.zipcode,
            street: e.address.street,
            streetNumber: e.address.streetNumber,
            complementary: e.address.complementary === null || e.address.complementary === undefined ? null : e.address.complementary,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            country: e.address.country,
            countryCode: e.address.countryCode,
            latLng: {
                lat: e.address.latLng.lat,
                lng: e.address.latLng.lng,
            },
        },
        type: e.type,
    }));
}

export async function getTotalNumberUsers(userType: UserType | null, progress?: (progress: number) => void): Promise<number> {
    const args = {
        userType: userType === null || userType === undefined ? null : userType,
    };
    const ret = await makeRequest({name: "getTotalNumberUsers", args, progress});
    return ret || 0;
}

export async function addVehicleToUser(userId: string, vehicleData: NewVehicle, progress?: (progress: number) => void): Promise<Vehicle[]> {
    const args = {
        userId: userId,
        vehicleData: {
            model: vehicleData.model,
            axisType: vehicleData.axisType,
        },
    };
    const ret = await makeRequest({name: "addVehicleToUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        model: e.model,
        axisType: e.axisType,
    }));
}

export async function editVehicle(userId: string, vehicleId: string, vehicleData: NewVehicle, progress?: (progress: number) => void): Promise<Vehicle[]> {
    const args = {
        userId: userId,
        vehicleId: vehicleId,
        vehicleData: {
            model: vehicleData.model,
            axisType: vehicleData.axisType,
        },
    };
    const ret = await makeRequest({name: "editVehicle", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        model: e.model,
        axisType: e.axisType,
    }));
}

export async function deleteVehicle(userId: string, vehicleId: string, progress?: (progress: number) => void): Promise<Vehicle[]> {
    const args = {
        userId: userId,
        vehicleId: vehicleId,
    };
    const ret = await makeRequest({name: "deleteVehicle", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        model: e.model,
        axisType: e.axisType,
    }));
}

export async function getVehicleById(userId: string, vehicleId: string, progress?: (progress: number) => void): Promise<Vehicle> {
    const args = {
        userId: userId,
        vehicleId: vehicleId,
    };
    const ret = await makeRequest({name: "getVehicleById", args, progress});
    return {
        id: ret.id,
        model: ret.model,
        axisType: ret.axisType,
    };
}

export async function getSecureById(secureId: string, progress?: (progress: number) => void): Promise<Secure> {
    const args = {
        secureId: secureId,
    };
    const ret = await makeRequest({name: "getSecureById", args, progress});
    return {
        id: ret.id,
        owner: {
            id: ret.owner.id,
            ratingAverage: ret.owner.ratingAverage || 0,
            vehicles: ret.owner.vehicles === null || ret.owner.vehicles === undefined ? null : ret.owner.vehicles.map((e: any) => ({
                id: e.id,
                model: e.model,
                axisType: e.axisType,
            })),
            createdAt: new Date(ret.owner.createdAt + "Z"),
            editedAt: new Date(ret.owner.editedAt + "Z"),
            name: ret.owner.name,
            email: ret.owner.email,
            phone: ret.owner.phone,
            cpf: ret.owner.cpf === null || ret.owner.cpf === undefined ? null : ret.owner.cpf,
            image: ret.owner.image === null || ret.owner.image === undefined ? null : {
                thumb: {
                    width: ret.owner.image.thumb.width || 0,
                    height: ret.owner.image.thumb.height || 0,
                    url: ret.owner.image.thumb.url,
                },
                width: ret.owner.image.width || 0,
                height: ret.owner.image.height || 0,
                url: ret.owner.image.url,
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                zipcode: ret.owner.address.zipcode,
                street: ret.owner.address.street,
                streetNumber: ret.owner.address.streetNumber,
                complementary: ret.owner.address.complementary === null || ret.owner.address.complementary === undefined ? null : ret.owner.address.complementary,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                state: ret.owner.address.state,
                country: ret.owner.address.country,
                countryCode: ret.owner.address.countryCode,
                latLng: {
                    lat: ret.owner.address.latLng.lat,
                    lng: ret.owner.address.latLng.lng,
                },
            },
            type: ret.owner.type,
        },
        cargoOrderId: ret.cargoOrderId === null || ret.cargoOrderId === undefined ? null : ret.cargoOrderId,
        transportOrderId: ret.transportOrderId === null || ret.transportOrderId === undefined ? null : ret.transportOrderId,
        shippingId: ret.shippingId === null || ret.shippingId === undefined ? null : ret.shippingId,
    };
}

export async function getAllSecures(pageOffset: number, progress?: (progress: number) => void): Promise<Secure[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllSecures", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        owner: {
            id: e.owner.id,
            ratingAverage: e.owner.ratingAverage || 0,
            vehicles: e.owner.vehicles === null || e.owner.vehicles === undefined ? null : e.owner.vehicles.map((e: any) => ({
                id: e.id,
                model: e.model,
                axisType: e.axisType,
            })),
            createdAt: new Date(e.owner.createdAt + "Z"),
            editedAt: new Date(e.owner.editedAt + "Z"),
            name: e.owner.name,
            email: e.owner.email,
            phone: e.owner.phone,
            cpf: e.owner.cpf === null || e.owner.cpf === undefined ? null : e.owner.cpf,
            image: e.owner.image === null || e.owner.image === undefined ? null : {
                thumb: {
                    width: e.owner.image.thumb.width || 0,
                    height: e.owner.image.thumb.height || 0,
                    url: e.owner.image.thumb.url,
                },
                width: e.owner.image.width || 0,
                height: e.owner.image.height || 0,
                url: e.owner.image.url,
            },
            address: e.owner.address === null || e.owner.address === undefined ? null : {
                zipcode: e.owner.address.zipcode,
                street: e.owner.address.street,
                streetNumber: e.owner.address.streetNumber,
                complementary: e.owner.address.complementary === null || e.owner.address.complementary === undefined ? null : e.owner.address.complementary,
                neighborhood: e.owner.address.neighborhood,
                city: e.owner.address.city,
                state: e.owner.address.state,
                country: e.owner.address.country,
                countryCode: e.owner.address.countryCode,
                latLng: {
                    lat: e.owner.address.latLng.lat,
                    lng: e.owner.address.latLng.lng,
                },
            },
            type: e.owner.type,
        },
        cargoOrderId: e.cargoOrderId === null || e.cargoOrderId === undefined ? null : e.cargoOrderId,
        transportOrderId: e.transportOrderId === null || e.transportOrderId === undefined ? null : e.transportOrderId,
        shippingId: e.shippingId === null || e.shippingId === undefined ? null : e.shippingId,
    }));
}

export async function getCargoOrder(cargoOrderId: string, progress?: (progress: number) => void): Promise<CargoOrder> {
    const args = {
        cargoOrderId: cargoOrderId,
    };
    const ret = await makeRequest({name: "getCargoOrder", args, progress});
    return {
        id: ret.id,
        owner: {
            id: ret.owner.id,
            ratingAverage: ret.owner.ratingAverage || 0,
            vehicles: ret.owner.vehicles === null || ret.owner.vehicles === undefined ? null : ret.owner.vehicles.map((e: any) => ({
                id: e.id,
                model: e.model,
                axisType: e.axisType,
            })),
            createdAt: new Date(ret.owner.createdAt + "Z"),
            editedAt: new Date(ret.owner.editedAt + "Z"),
            name: ret.owner.name,
            email: ret.owner.email,
            phone: ret.owner.phone,
            cpf: ret.owner.cpf === null || ret.owner.cpf === undefined ? null : ret.owner.cpf,
            image: ret.owner.image === null || ret.owner.image === undefined ? null : {
                thumb: {
                    width: ret.owner.image.thumb.width || 0,
                    height: ret.owner.image.thumb.height || 0,
                    url: ret.owner.image.thumb.url,
                },
                width: ret.owner.image.width || 0,
                height: ret.owner.image.height || 0,
                url: ret.owner.image.url,
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                zipcode: ret.owner.address.zipcode,
                street: ret.owner.address.street,
                streetNumber: ret.owner.address.streetNumber,
                complementary: ret.owner.address.complementary === null || ret.owner.address.complementary === undefined ? null : ret.owner.address.complementary,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                state: ret.owner.address.state,
                country: ret.owner.address.country,
                countryCode: ret.owner.address.countryCode,
                latLng: {
                    lat: ret.owner.address.latLng.lat,
                    lng: ret.owner.address.latLng.lng,
                },
            },
            type: ret.owner.type,
        },
        accepter: ret.accepter === null || ret.accepter === undefined ? null : {
            id: ret.accepter.id,
            ratingAverage: ret.accepter.ratingAverage || 0,
            vehicles: ret.accepter.vehicles === null || ret.accepter.vehicles === undefined ? null : ret.accepter.vehicles.map((e: any) => ({
                id: e.id,
                model: e.model,
                axisType: e.axisType,
            })),
            createdAt: new Date(ret.accepter.createdAt + "Z"),
            editedAt: new Date(ret.accepter.editedAt + "Z"),
            name: ret.accepter.name,
            email: ret.accepter.email,
            phone: ret.accepter.phone,
            cpf: ret.accepter.cpf === null || ret.accepter.cpf === undefined ? null : ret.accepter.cpf,
            image: ret.accepter.image === null || ret.accepter.image === undefined ? null : {
                thumb: {
                    width: ret.accepter.image.thumb.width || 0,
                    height: ret.accepter.image.thumb.height || 0,
                    url: ret.accepter.image.thumb.url,
                },
                width: ret.accepter.image.width || 0,
                height: ret.accepter.image.height || 0,
                url: ret.accepter.image.url,
            },
            address: ret.accepter.address === null || ret.accepter.address === undefined ? null : {
                zipcode: ret.accepter.address.zipcode,
                street: ret.accepter.address.street,
                streetNumber: ret.accepter.address.streetNumber,
                complementary: ret.accepter.address.complementary === null || ret.accepter.address.complementary === undefined ? null : ret.accepter.address.complementary,
                neighborhood: ret.accepter.address.neighborhood,
                city: ret.accepter.address.city,
                state: ret.accepter.address.state,
                country: ret.accepter.address.country,
                countryCode: ret.accepter.address.countryCode,
                latLng: {
                    lat: ret.accepter.address.latLng.lat,
                    lng: ret.accepter.address.latLng.lng,
                },
            },
            type: ret.accepter.type,
        },
        origin: {
            latitude: ret.origin.latitude,
            longitude: ret.origin.longitude,
            address: ret.origin.address,
        },
        destiny: {
            latitude: ret.destiny.latitude,
            longitude: ret.destiny.longitude,
            address: ret.destiny.address,
        },
        startDate: new Date(ret.startDate + "Z"),
        endDate: new Date(ret.endDate + "Z"),
        price: ret.price || 0,
        weight: ret.weight === null || ret.weight === undefined ? null : ret.weight || 0,
        quantity: ret.quantity || 0,
        animalType: ret.animalType,
        proposals: ret.proposals.map((e: any) => ({
            id: e.id,
            proposer: {
                id: e.proposer.id,
                ratingAverage: e.proposer.ratingAverage || 0,
                vehicles: e.proposer.vehicles === null || e.proposer.vehicles === undefined ? null : e.proposer.vehicles.map((e: any) => ({
                    id: e.id,
                    model: e.model,
                    axisType: e.axisType,
                })),
                createdAt: new Date(e.proposer.createdAt + "Z"),
                editedAt: new Date(e.proposer.editedAt + "Z"),
                name: e.proposer.name,
                email: e.proposer.email,
                phone: e.proposer.phone,
                cpf: e.proposer.cpf === null || e.proposer.cpf === undefined ? null : e.proposer.cpf,
                image: e.proposer.image === null || e.proposer.image === undefined ? null : {
                    thumb: {
                        width: e.proposer.image.thumb.width || 0,
                        height: e.proposer.image.thumb.height || 0,
                        url: e.proposer.image.thumb.url,
                    },
                    width: e.proposer.image.width || 0,
                    height: e.proposer.image.height || 0,
                    url: e.proposer.image.url,
                },
                address: e.proposer.address === null || e.proposer.address === undefined ? null : {
                    zipcode: e.proposer.address.zipcode,
                    street: e.proposer.address.street,
                    streetNumber: e.proposer.address.streetNumber,
                    complementary: e.proposer.address.complementary === null || e.proposer.address.complementary === undefined ? null : e.proposer.address.complementary,
                    neighborhood: e.proposer.address.neighborhood,
                    city: e.proposer.address.city,
                    state: e.proposer.address.state,
                    country: e.proposer.address.country,
                    countryCode: e.proposer.address.countryCode,
                    latLng: {
                        lat: e.proposer.address.latLng.lat,
                        lng: e.proposer.address.latLng.lng,
                    },
                },
                type: e.proposer.type,
            },
            createdAt: new Date(e.createdAt + "Z"),
            proposalStatus: e.proposalStatus,
            price: e.price || 0,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllCargoOrders(page: number, progress?: (progress: number) => void): Promise<CargoOrder[]> {
    const args = {
        page: page || 0,
    };
    const ret = await makeRequest({name: "getAllCargoOrders", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        owner: {
            id: e.owner.id,
            ratingAverage: e.owner.ratingAverage || 0,
            vehicles: e.owner.vehicles === null || e.owner.vehicles === undefined ? null : e.owner.vehicles.map((e: any) => ({
                id: e.id,
                model: e.model,
                axisType: e.axisType,
            })),
            createdAt: new Date(e.owner.createdAt + "Z"),
            editedAt: new Date(e.owner.editedAt + "Z"),
            name: e.owner.name,
            email: e.owner.email,
            phone: e.owner.phone,
            cpf: e.owner.cpf === null || e.owner.cpf === undefined ? null : e.owner.cpf,
            image: e.owner.image === null || e.owner.image === undefined ? null : {
                thumb: {
                    width: e.owner.image.thumb.width || 0,
                    height: e.owner.image.thumb.height || 0,
                    url: e.owner.image.thumb.url,
                },
                width: e.owner.image.width || 0,
                height: e.owner.image.height || 0,
                url: e.owner.image.url,
            },
            address: e.owner.address === null || e.owner.address === undefined ? null : {
                zipcode: e.owner.address.zipcode,
                street: e.owner.address.street,
                streetNumber: e.owner.address.streetNumber,
                complementary: e.owner.address.complementary === null || e.owner.address.complementary === undefined ? null : e.owner.address.complementary,
                neighborhood: e.owner.address.neighborhood,
                city: e.owner.address.city,
                state: e.owner.address.state,
                country: e.owner.address.country,
                countryCode: e.owner.address.countryCode,
                latLng: {
                    lat: e.owner.address.latLng.lat,
                    lng: e.owner.address.latLng.lng,
                },
            },
            type: e.owner.type,
        },
        accepter: e.accepter === null || e.accepter === undefined ? null : {
            id: e.accepter.id,
            ratingAverage: e.accepter.ratingAverage || 0,
            vehicles: e.accepter.vehicles === null || e.accepter.vehicles === undefined ? null : e.accepter.vehicles.map((e: any) => ({
                id: e.id,
                model: e.model,
                axisType: e.axisType,
            })),
            createdAt: new Date(e.accepter.createdAt + "Z"),
            editedAt: new Date(e.accepter.editedAt + "Z"),
            name: e.accepter.name,
            email: e.accepter.email,
            phone: e.accepter.phone,
            cpf: e.accepter.cpf === null || e.accepter.cpf === undefined ? null : e.accepter.cpf,
            image: e.accepter.image === null || e.accepter.image === undefined ? null : {
                thumb: {
                    width: e.accepter.image.thumb.width || 0,
                    height: e.accepter.image.thumb.height || 0,
                    url: e.accepter.image.thumb.url,
                },
                width: e.accepter.image.width || 0,
                height: e.accepter.image.height || 0,
                url: e.accepter.image.url,
            },
            address: e.accepter.address === null || e.accepter.address === undefined ? null : {
                zipcode: e.accepter.address.zipcode,
                street: e.accepter.address.street,
                streetNumber: e.accepter.address.streetNumber,
                complementary: e.accepter.address.complementary === null || e.accepter.address.complementary === undefined ? null : e.accepter.address.complementary,
                neighborhood: e.accepter.address.neighborhood,
                city: e.accepter.address.city,
                state: e.accepter.address.state,
                country: e.accepter.address.country,
                countryCode: e.accepter.address.countryCode,
                latLng: {
                    lat: e.accepter.address.latLng.lat,
                    lng: e.accepter.address.latLng.lng,
                },
            },
            type: e.accepter.type,
        },
        origin: {
            latitude: e.origin.latitude,
            longitude: e.origin.longitude,
            address: e.origin.address,
        },
        destiny: {
            latitude: e.destiny.latitude,
            longitude: e.destiny.longitude,
            address: e.destiny.address,
        },
        startDate: new Date(e.startDate + "Z"),
        endDate: new Date(e.endDate + "Z"),
        price: e.price || 0,
        weight: e.weight === null || e.weight === undefined ? null : e.weight || 0,
        quantity: e.quantity || 0,
        animalType: e.animalType,
        proposals: e.proposals.map((e: any) => ({
            id: e.id,
            proposer: {
                id: e.proposer.id,
                ratingAverage: e.proposer.ratingAverage || 0,
                vehicles: e.proposer.vehicles === null || e.proposer.vehicles === undefined ? null : e.proposer.vehicles.map((e: any) => ({
                    id: e.id,
                    model: e.model,
                    axisType: e.axisType,
                })),
                createdAt: new Date(e.proposer.createdAt + "Z"),
                editedAt: new Date(e.proposer.editedAt + "Z"),
                name: e.proposer.name,
                email: e.proposer.email,
                phone: e.proposer.phone,
                cpf: e.proposer.cpf === null || e.proposer.cpf === undefined ? null : e.proposer.cpf,
                image: e.proposer.image === null || e.proposer.image === undefined ? null : {
                    thumb: {
                        width: e.proposer.image.thumb.width || 0,
                        height: e.proposer.image.thumb.height || 0,
                        url: e.proposer.image.thumb.url,
                    },
                    width: e.proposer.image.width || 0,
                    height: e.proposer.image.height || 0,
                    url: e.proposer.image.url,
                },
                address: e.proposer.address === null || e.proposer.address === undefined ? null : {
                    zipcode: e.proposer.address.zipcode,
                    street: e.proposer.address.street,
                    streetNumber: e.proposer.address.streetNumber,
                    complementary: e.proposer.address.complementary === null || e.proposer.address.complementary === undefined ? null : e.proposer.address.complementary,
                    neighborhood: e.proposer.address.neighborhood,
                    city: e.proposer.address.city,
                    state: e.proposer.address.state,
                    country: e.proposer.address.country,
                    countryCode: e.proposer.address.countryCode,
                    latLng: {
                        lat: e.proposer.address.latLng.lat,
                        lng: e.proposer.address.latLng.lng,
                    },
                },
                type: e.proposer.type,
            },
            createdAt: new Date(e.createdAt + "Z"),
            proposalStatus: e.proposalStatus,
            price: e.price || 0,
        })),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getTransportOrder(transportOrderId: string, progress?: (progress: number) => void): Promise<TransportOrder> {
    const args = {
        transportOrderId: transportOrderId,
    };
    const ret = await makeRequest({name: "getTransportOrder", args, progress});
    return {
        id: ret.id,
        owner: {
            id: ret.owner.id,
            ratingAverage: ret.owner.ratingAverage || 0,
            vehicles: ret.owner.vehicles === null || ret.owner.vehicles === undefined ? null : ret.owner.vehicles.map((e: any) => ({
                id: e.id,
                model: e.model,
                axisType: e.axisType,
            })),
            createdAt: new Date(ret.owner.createdAt + "Z"),
            editedAt: new Date(ret.owner.editedAt + "Z"),
            name: ret.owner.name,
            email: ret.owner.email,
            phone: ret.owner.phone,
            cpf: ret.owner.cpf === null || ret.owner.cpf === undefined ? null : ret.owner.cpf,
            image: ret.owner.image === null || ret.owner.image === undefined ? null : {
                thumb: {
                    width: ret.owner.image.thumb.width || 0,
                    height: ret.owner.image.thumb.height || 0,
                    url: ret.owner.image.thumb.url,
                },
                width: ret.owner.image.width || 0,
                height: ret.owner.image.height || 0,
                url: ret.owner.image.url,
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                zipcode: ret.owner.address.zipcode,
                street: ret.owner.address.street,
                streetNumber: ret.owner.address.streetNumber,
                complementary: ret.owner.address.complementary === null || ret.owner.address.complementary === undefined ? null : ret.owner.address.complementary,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                state: ret.owner.address.state,
                country: ret.owner.address.country,
                countryCode: ret.owner.address.countryCode,
                latLng: {
                    lat: ret.owner.address.latLng.lat,
                    lng: ret.owner.address.latLng.lng,
                },
            },
            type: ret.owner.type,
        },
        accepter: ret.accepter === null || ret.accepter === undefined ? null : {
            id: ret.accepter.id,
            ratingAverage: ret.accepter.ratingAverage || 0,
            vehicles: ret.accepter.vehicles === null || ret.accepter.vehicles === undefined ? null : ret.accepter.vehicles.map((e: any) => ({
                id: e.id,
                model: e.model,
                axisType: e.axisType,
            })),
            createdAt: new Date(ret.accepter.createdAt + "Z"),
            editedAt: new Date(ret.accepter.editedAt + "Z"),
            name: ret.accepter.name,
            email: ret.accepter.email,
            phone: ret.accepter.phone,
            cpf: ret.accepter.cpf === null || ret.accepter.cpf === undefined ? null : ret.accepter.cpf,
            image: ret.accepter.image === null || ret.accepter.image === undefined ? null : {
                thumb: {
                    width: ret.accepter.image.thumb.width || 0,
                    height: ret.accepter.image.thumb.height || 0,
                    url: ret.accepter.image.thumb.url,
                },
                width: ret.accepter.image.width || 0,
                height: ret.accepter.image.height || 0,
                url: ret.accepter.image.url,
            },
            address: ret.accepter.address === null || ret.accepter.address === undefined ? null : {
                zipcode: ret.accepter.address.zipcode,
                street: ret.accepter.address.street,
                streetNumber: ret.accepter.address.streetNumber,
                complementary: ret.accepter.address.complementary === null || ret.accepter.address.complementary === undefined ? null : ret.accepter.address.complementary,
                neighborhood: ret.accepter.address.neighborhood,
                city: ret.accepter.address.city,
                state: ret.accepter.address.state,
                country: ret.accepter.address.country,
                countryCode: ret.accepter.address.countryCode,
                latLng: {
                    lat: ret.accepter.address.latLng.lat,
                    lng: ret.accepter.address.latLng.lng,
                },
            },
            type: ret.accepter.type,
        },
        origin: {
            latitude: ret.origin.latitude,
            longitude: ret.origin.longitude,
            address: ret.origin.address,
        },
        destiny: {
            latitude: ret.destiny.latitude,
            longitude: ret.destiny.longitude,
            address: ret.destiny.address,
        },
        vehicle: {
            id: ret.vehicle.id,
            model: ret.vehicle.model,
            axisType: ret.vehicle.axisType,
        },
        originDistanceRadius: ret.originDistanceRadius || 0,
        destinyDistanceRadius: ret.destinyDistanceRadius || 0,
        startDate: new Date(ret.startDate + "Z"),
        endDate: new Date(ret.endDate + "Z"),
        price: ret.price || 0,
        weight: ret.weight || 0,
        proposals: ret.proposals.map((e: any) => ({
            id: e.id,
            proposer: {
                id: e.proposer.id,
                ratingAverage: e.proposer.ratingAverage || 0,
                vehicles: e.proposer.vehicles === null || e.proposer.vehicles === undefined ? null : e.proposer.vehicles.map((e: any) => ({
                    id: e.id,
                    model: e.model,
                    axisType: e.axisType,
                })),
                createdAt: new Date(e.proposer.createdAt + "Z"),
                editedAt: new Date(e.proposer.editedAt + "Z"),
                name: e.proposer.name,
                email: e.proposer.email,
                phone: e.proposer.phone,
                cpf: e.proposer.cpf === null || e.proposer.cpf === undefined ? null : e.proposer.cpf,
                image: e.proposer.image === null || e.proposer.image === undefined ? null : {
                    thumb: {
                        width: e.proposer.image.thumb.width || 0,
                        height: e.proposer.image.thumb.height || 0,
                        url: e.proposer.image.thumb.url,
                    },
                    width: e.proposer.image.width || 0,
                    height: e.proposer.image.height || 0,
                    url: e.proposer.image.url,
                },
                address: e.proposer.address === null || e.proposer.address === undefined ? null : {
                    zipcode: e.proposer.address.zipcode,
                    street: e.proposer.address.street,
                    streetNumber: e.proposer.address.streetNumber,
                    complementary: e.proposer.address.complementary === null || e.proposer.address.complementary === undefined ? null : e.proposer.address.complementary,
                    neighborhood: e.proposer.address.neighborhood,
                    city: e.proposer.address.city,
                    state: e.proposer.address.state,
                    country: e.proposer.address.country,
                    countryCode: e.proposer.address.countryCode,
                    latLng: {
                        lat: e.proposer.address.latLng.lat,
                        lng: e.proposer.address.latLng.lng,
                    },
                },
                type: e.proposer.type,
            },
            createdAt: new Date(e.createdAt + "Z"),
            proposalStatus: e.proposalStatus,
            price: e.price || 0,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllShipping(pageOffset: number, filter: ShippingFilter, progress?: (progress: number) => void): Promise<Shipping[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filter: {
            driverId: filter.driverId === null || filter.driverId === undefined ? null : filter.driverId,
            status: filter.status === null || filter.status === undefined ? null : filter.status,
            startPeriodDate: filter.startPeriodDate === null || filter.startPeriodDate === undefined ? null : {
                startDate: typeof(filter.startPeriodDate.startDate) === "string" ? new Date(new Date(filter.startPeriodDate.startDate).getTime() - new Date(filter.startPeriodDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startPeriodDate.startDate.getTime() - filter.startPeriodDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: typeof(filter.startPeriodDate.endDate) === "string" ? new Date(new Date(filter.startPeriodDate.endDate).getTime() - new Date(filter.startPeriodDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startPeriodDate.endDate.getTime() - filter.startPeriodDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            endPeriodDate: filter.endPeriodDate === null || filter.endPeriodDate === undefined ? null : {
                startDate: typeof(filter.endPeriodDate.startDate) === "string" ? new Date(new Date(filter.endPeriodDate.startDate).getTime() - new Date(filter.endPeriodDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endPeriodDate.startDate.getTime() - filter.endPeriodDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: typeof(filter.endPeriodDate.endDate) === "string" ? new Date(new Date(filter.endPeriodDate.endDate).getTime() - new Date(filter.endPeriodDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endPeriodDate.endDate.getTime() - filter.endPeriodDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            city: filter.city === null || filter.city === undefined ? null : filter.city,
        },
    };
    const ret = await makeRequest({name: "getAllShipping", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        owner: {
            id: e.owner.id,
            ratingAverage: e.owner.ratingAverage || 0,
            vehicles: e.owner.vehicles === null || e.owner.vehicles === undefined ? null : e.owner.vehicles.map((e: any) => ({
                id: e.id,
                model: e.model,
                axisType: e.axisType,
            })),
            createdAt: new Date(e.owner.createdAt + "Z"),
            editedAt: new Date(e.owner.editedAt + "Z"),
            name: e.owner.name,
            email: e.owner.email,
            phone: e.owner.phone,
            cpf: e.owner.cpf === null || e.owner.cpf === undefined ? null : e.owner.cpf,
            image: e.owner.image === null || e.owner.image === undefined ? null : {
                thumb: {
                    width: e.owner.image.thumb.width || 0,
                    height: e.owner.image.thumb.height || 0,
                    url: e.owner.image.thumb.url,
                },
                width: e.owner.image.width || 0,
                height: e.owner.image.height || 0,
                url: e.owner.image.url,
            },
            address: e.owner.address === null || e.owner.address === undefined ? null : {
                zipcode: e.owner.address.zipcode,
                street: e.owner.address.street,
                streetNumber: e.owner.address.streetNumber,
                complementary: e.owner.address.complementary === null || e.owner.address.complementary === undefined ? null : e.owner.address.complementary,
                neighborhood: e.owner.address.neighborhood,
                city: e.owner.address.city,
                state: e.owner.address.state,
                country: e.owner.address.country,
                countryCode: e.owner.address.countryCode,
                latLng: {
                    lat: e.owner.address.latLng.lat,
                    lng: e.owner.address.latLng.lng,
                },
            },
            type: e.owner.type,
        },
        accepter: {
            id: e.accepter.id,
            ratingAverage: e.accepter.ratingAverage || 0,
            vehicles: e.accepter.vehicles === null || e.accepter.vehicles === undefined ? null : e.accepter.vehicles.map((e: any) => ({
                id: e.id,
                model: e.model,
                axisType: e.axisType,
            })),
            createdAt: new Date(e.accepter.createdAt + "Z"),
            editedAt: new Date(e.accepter.editedAt + "Z"),
            name: e.accepter.name,
            email: e.accepter.email,
            phone: e.accepter.phone,
            cpf: e.accepter.cpf === null || e.accepter.cpf === undefined ? null : e.accepter.cpf,
            image: e.accepter.image === null || e.accepter.image === undefined ? null : {
                thumb: {
                    width: e.accepter.image.thumb.width || 0,
                    height: e.accepter.image.thumb.height || 0,
                    url: e.accepter.image.thumb.url,
                },
                width: e.accepter.image.width || 0,
                height: e.accepter.image.height || 0,
                url: e.accepter.image.url,
            },
            address: e.accepter.address === null || e.accepter.address === undefined ? null : {
                zipcode: e.accepter.address.zipcode,
                street: e.accepter.address.street,
                streetNumber: e.accepter.address.streetNumber,
                complementary: e.accepter.address.complementary === null || e.accepter.address.complementary === undefined ? null : e.accepter.address.complementary,
                neighborhood: e.accepter.address.neighborhood,
                city: e.accepter.address.city,
                state: e.accepter.address.state,
                country: e.accepter.address.country,
                countryCode: e.accepter.address.countryCode,
                latLng: {
                    lat: e.accepter.address.latLng.lat,
                    lng: e.accepter.address.latLng.lng,
                },
            },
            type: e.accepter.type,
        },
        price: e.price || 0,
        city: e.city,
        startDate: new Date(e.startDate + "Z"),
        endDate: new Date(e.endDate + "Z"),
        cargoOrder: e.cargoOrder === null || e.cargoOrder === undefined ? null : {
            id: e.cargoOrder.id,
            owner: {
                id: e.cargoOrder.owner.id,
                ratingAverage: e.cargoOrder.owner.ratingAverage || 0,
                vehicles: e.cargoOrder.owner.vehicles === null || e.cargoOrder.owner.vehicles === undefined ? null : e.cargoOrder.owner.vehicles.map((e: any) => ({
                    id: e.id,
                    model: e.model,
                    axisType: e.axisType,
                })),
                createdAt: new Date(e.cargoOrder.owner.createdAt + "Z"),
                editedAt: new Date(e.cargoOrder.owner.editedAt + "Z"),
                name: e.cargoOrder.owner.name,
                email: e.cargoOrder.owner.email,
                phone: e.cargoOrder.owner.phone,
                cpf: e.cargoOrder.owner.cpf === null || e.cargoOrder.owner.cpf === undefined ? null : e.cargoOrder.owner.cpf,
                image: e.cargoOrder.owner.image === null || e.cargoOrder.owner.image === undefined ? null : {
                    thumb: {
                        width: e.cargoOrder.owner.image.thumb.width || 0,
                        height: e.cargoOrder.owner.image.thumb.height || 0,
                        url: e.cargoOrder.owner.image.thumb.url,
                    },
                    width: e.cargoOrder.owner.image.width || 0,
                    height: e.cargoOrder.owner.image.height || 0,
                    url: e.cargoOrder.owner.image.url,
                },
                address: e.cargoOrder.owner.address === null || e.cargoOrder.owner.address === undefined ? null : {
                    zipcode: e.cargoOrder.owner.address.zipcode,
                    street: e.cargoOrder.owner.address.street,
                    streetNumber: e.cargoOrder.owner.address.streetNumber,
                    complementary: e.cargoOrder.owner.address.complementary === null || e.cargoOrder.owner.address.complementary === undefined ? null : e.cargoOrder.owner.address.complementary,
                    neighborhood: e.cargoOrder.owner.address.neighborhood,
                    city: e.cargoOrder.owner.address.city,
                    state: e.cargoOrder.owner.address.state,
                    country: e.cargoOrder.owner.address.country,
                    countryCode: e.cargoOrder.owner.address.countryCode,
                    latLng: {
                        lat: e.cargoOrder.owner.address.latLng.lat,
                        lng: e.cargoOrder.owner.address.latLng.lng,
                    },
                },
                type: e.cargoOrder.owner.type,
            },
            accepter: e.cargoOrder.accepter === null || e.cargoOrder.accepter === undefined ? null : {
                id: e.cargoOrder.accepter.id,
                ratingAverage: e.cargoOrder.accepter.ratingAverage || 0,
                vehicles: e.cargoOrder.accepter.vehicles === null || e.cargoOrder.accepter.vehicles === undefined ? null : e.cargoOrder.accepter.vehicles.map((e: any) => ({
                    id: e.id,
                    model: e.model,
                    axisType: e.axisType,
                })),
                createdAt: new Date(e.cargoOrder.accepter.createdAt + "Z"),
                editedAt: new Date(e.cargoOrder.accepter.editedAt + "Z"),
                name: e.cargoOrder.accepter.name,
                email: e.cargoOrder.accepter.email,
                phone: e.cargoOrder.accepter.phone,
                cpf: e.cargoOrder.accepter.cpf === null || e.cargoOrder.accepter.cpf === undefined ? null : e.cargoOrder.accepter.cpf,
                image: e.cargoOrder.accepter.image === null || e.cargoOrder.accepter.image === undefined ? null : {
                    thumb: {
                        width: e.cargoOrder.accepter.image.thumb.width || 0,
                        height: e.cargoOrder.accepter.image.thumb.height || 0,
                        url: e.cargoOrder.accepter.image.thumb.url,
                    },
                    width: e.cargoOrder.accepter.image.width || 0,
                    height: e.cargoOrder.accepter.image.height || 0,
                    url: e.cargoOrder.accepter.image.url,
                },
                address: e.cargoOrder.accepter.address === null || e.cargoOrder.accepter.address === undefined ? null : {
                    zipcode: e.cargoOrder.accepter.address.zipcode,
                    street: e.cargoOrder.accepter.address.street,
                    streetNumber: e.cargoOrder.accepter.address.streetNumber,
                    complementary: e.cargoOrder.accepter.address.complementary === null || e.cargoOrder.accepter.address.complementary === undefined ? null : e.cargoOrder.accepter.address.complementary,
                    neighborhood: e.cargoOrder.accepter.address.neighborhood,
                    city: e.cargoOrder.accepter.address.city,
                    state: e.cargoOrder.accepter.address.state,
                    country: e.cargoOrder.accepter.address.country,
                    countryCode: e.cargoOrder.accepter.address.countryCode,
                    latLng: {
                        lat: e.cargoOrder.accepter.address.latLng.lat,
                        lng: e.cargoOrder.accepter.address.latLng.lng,
                    },
                },
                type: e.cargoOrder.accepter.type,
            },
            origin: {
                latitude: e.cargoOrder.origin.latitude,
                longitude: e.cargoOrder.origin.longitude,
                address: e.cargoOrder.origin.address,
            },
            destiny: {
                latitude: e.cargoOrder.destiny.latitude,
                longitude: e.cargoOrder.destiny.longitude,
                address: e.cargoOrder.destiny.address,
            },
            startDate: new Date(e.cargoOrder.startDate + "Z"),
            endDate: new Date(e.cargoOrder.endDate + "Z"),
            price: e.cargoOrder.price || 0,
            weight: e.cargoOrder.weight === null || e.cargoOrder.weight === undefined ? null : e.cargoOrder.weight || 0,
            quantity: e.cargoOrder.quantity || 0,
            animalType: e.cargoOrder.animalType,
            proposals: e.cargoOrder.proposals.map((e: any) => ({
                id: e.id,
                proposer: {
                    id: e.proposer.id,
                    ratingAverage: e.proposer.ratingAverage || 0,
                    vehicles: e.proposer.vehicles === null || e.proposer.vehicles === undefined ? null : e.proposer.vehicles.map((e: any) => ({
                        id: e.id,
                        model: e.model,
                        axisType: e.axisType,
                    })),
                    createdAt: new Date(e.proposer.createdAt + "Z"),
                    editedAt: new Date(e.proposer.editedAt + "Z"),
                    name: e.proposer.name,
                    email: e.proposer.email,
                    phone: e.proposer.phone,
                    cpf: e.proposer.cpf === null || e.proposer.cpf === undefined ? null : e.proposer.cpf,
                    image: e.proposer.image === null || e.proposer.image === undefined ? null : {
                        thumb: {
                            width: e.proposer.image.thumb.width || 0,
                            height: e.proposer.image.thumb.height || 0,
                            url: e.proposer.image.thumb.url,
                        },
                        width: e.proposer.image.width || 0,
                        height: e.proposer.image.height || 0,
                        url: e.proposer.image.url,
                    },
                    address: e.proposer.address === null || e.proposer.address === undefined ? null : {
                        zipcode: e.proposer.address.zipcode,
                        street: e.proposer.address.street,
                        streetNumber: e.proposer.address.streetNumber,
                        complementary: e.proposer.address.complementary === null || e.proposer.address.complementary === undefined ? null : e.proposer.address.complementary,
                        neighborhood: e.proposer.address.neighborhood,
                        city: e.proposer.address.city,
                        state: e.proposer.address.state,
                        country: e.proposer.address.country,
                        countryCode: e.proposer.address.countryCode,
                        latLng: {
                            lat: e.proposer.address.latLng.lat,
                            lng: e.proposer.address.latLng.lng,
                        },
                    },
                    type: e.proposer.type,
                },
                createdAt: new Date(e.createdAt + "Z"),
                proposalStatus: e.proposalStatus,
                price: e.price || 0,
            })),
            createdAt: new Date(e.cargoOrder.createdAt + "Z"),
        },
        transportOrder: e.transportOrder === null || e.transportOrder === undefined ? null : {
            id: e.transportOrder.id,
            owner: {
                id: e.transportOrder.owner.id,
                ratingAverage: e.transportOrder.owner.ratingAverage || 0,
                vehicles: e.transportOrder.owner.vehicles === null || e.transportOrder.owner.vehicles === undefined ? null : e.transportOrder.owner.vehicles.map((e: any) => ({
                    id: e.id,
                    model: e.model,
                    axisType: e.axisType,
                })),
                createdAt: new Date(e.transportOrder.owner.createdAt + "Z"),
                editedAt: new Date(e.transportOrder.owner.editedAt + "Z"),
                name: e.transportOrder.owner.name,
                email: e.transportOrder.owner.email,
                phone: e.transportOrder.owner.phone,
                cpf: e.transportOrder.owner.cpf === null || e.transportOrder.owner.cpf === undefined ? null : e.transportOrder.owner.cpf,
                image: e.transportOrder.owner.image === null || e.transportOrder.owner.image === undefined ? null : {
                    thumb: {
                        width: e.transportOrder.owner.image.thumb.width || 0,
                        height: e.transportOrder.owner.image.thumb.height || 0,
                        url: e.transportOrder.owner.image.thumb.url,
                    },
                    width: e.transportOrder.owner.image.width || 0,
                    height: e.transportOrder.owner.image.height || 0,
                    url: e.transportOrder.owner.image.url,
                },
                address: e.transportOrder.owner.address === null || e.transportOrder.owner.address === undefined ? null : {
                    zipcode: e.transportOrder.owner.address.zipcode,
                    street: e.transportOrder.owner.address.street,
                    streetNumber: e.transportOrder.owner.address.streetNumber,
                    complementary: e.transportOrder.owner.address.complementary === null || e.transportOrder.owner.address.complementary === undefined ? null : e.transportOrder.owner.address.complementary,
                    neighborhood: e.transportOrder.owner.address.neighborhood,
                    city: e.transportOrder.owner.address.city,
                    state: e.transportOrder.owner.address.state,
                    country: e.transportOrder.owner.address.country,
                    countryCode: e.transportOrder.owner.address.countryCode,
                    latLng: {
                        lat: e.transportOrder.owner.address.latLng.lat,
                        lng: e.transportOrder.owner.address.latLng.lng,
                    },
                },
                type: e.transportOrder.owner.type,
            },
            accepter: e.transportOrder.accepter === null || e.transportOrder.accepter === undefined ? null : {
                id: e.transportOrder.accepter.id,
                ratingAverage: e.transportOrder.accepter.ratingAverage || 0,
                vehicles: e.transportOrder.accepter.vehicles === null || e.transportOrder.accepter.vehicles === undefined ? null : e.transportOrder.accepter.vehicles.map((e: any) => ({
                    id: e.id,
                    model: e.model,
                    axisType: e.axisType,
                })),
                createdAt: new Date(e.transportOrder.accepter.createdAt + "Z"),
                editedAt: new Date(e.transportOrder.accepter.editedAt + "Z"),
                name: e.transportOrder.accepter.name,
                email: e.transportOrder.accepter.email,
                phone: e.transportOrder.accepter.phone,
                cpf: e.transportOrder.accepter.cpf === null || e.transportOrder.accepter.cpf === undefined ? null : e.transportOrder.accepter.cpf,
                image: e.transportOrder.accepter.image === null || e.transportOrder.accepter.image === undefined ? null : {
                    thumb: {
                        width: e.transportOrder.accepter.image.thumb.width || 0,
                        height: e.transportOrder.accepter.image.thumb.height || 0,
                        url: e.transportOrder.accepter.image.thumb.url,
                    },
                    width: e.transportOrder.accepter.image.width || 0,
                    height: e.transportOrder.accepter.image.height || 0,
                    url: e.transportOrder.accepter.image.url,
                },
                address: e.transportOrder.accepter.address === null || e.transportOrder.accepter.address === undefined ? null : {
                    zipcode: e.transportOrder.accepter.address.zipcode,
                    street: e.transportOrder.accepter.address.street,
                    streetNumber: e.transportOrder.accepter.address.streetNumber,
                    complementary: e.transportOrder.accepter.address.complementary === null || e.transportOrder.accepter.address.complementary === undefined ? null : e.transportOrder.accepter.address.complementary,
                    neighborhood: e.transportOrder.accepter.address.neighborhood,
                    city: e.transportOrder.accepter.address.city,
                    state: e.transportOrder.accepter.address.state,
                    country: e.transportOrder.accepter.address.country,
                    countryCode: e.transportOrder.accepter.address.countryCode,
                    latLng: {
                        lat: e.transportOrder.accepter.address.latLng.lat,
                        lng: e.transportOrder.accepter.address.latLng.lng,
                    },
                },
                type: e.transportOrder.accepter.type,
            },
            origin: {
                latitude: e.transportOrder.origin.latitude,
                longitude: e.transportOrder.origin.longitude,
                address: e.transportOrder.origin.address,
            },
            destiny: {
                latitude: e.transportOrder.destiny.latitude,
                longitude: e.transportOrder.destiny.longitude,
                address: e.transportOrder.destiny.address,
            },
            vehicle: {
                id: e.transportOrder.vehicle.id,
                model: e.transportOrder.vehicle.model,
                axisType: e.transportOrder.vehicle.axisType,
            },
            originDistanceRadius: e.transportOrder.originDistanceRadius || 0,
            destinyDistanceRadius: e.transportOrder.destinyDistanceRadius || 0,
            startDate: new Date(e.transportOrder.startDate + "Z"),
            endDate: new Date(e.transportOrder.endDate + "Z"),
            price: e.transportOrder.price || 0,
            weight: e.transportOrder.weight || 0,
            proposals: e.transportOrder.proposals.map((e: any) => ({
                id: e.id,
                proposer: {
                    id: e.proposer.id,
                    ratingAverage: e.proposer.ratingAverage || 0,
                    vehicles: e.proposer.vehicles === null || e.proposer.vehicles === undefined ? null : e.proposer.vehicles.map((e: any) => ({
                        id: e.id,
                        model: e.model,
                        axisType: e.axisType,
                    })),
                    createdAt: new Date(e.proposer.createdAt + "Z"),
                    editedAt: new Date(e.proposer.editedAt + "Z"),
                    name: e.proposer.name,
                    email: e.proposer.email,
                    phone: e.proposer.phone,
                    cpf: e.proposer.cpf === null || e.proposer.cpf === undefined ? null : e.proposer.cpf,
                    image: e.proposer.image === null || e.proposer.image === undefined ? null : {
                        thumb: {
                            width: e.proposer.image.thumb.width || 0,
                            height: e.proposer.image.thumb.height || 0,
                            url: e.proposer.image.thumb.url,
                        },
                        width: e.proposer.image.width || 0,
                        height: e.proposer.image.height || 0,
                        url: e.proposer.image.url,
                    },
                    address: e.proposer.address === null || e.proposer.address === undefined ? null : {
                        zipcode: e.proposer.address.zipcode,
                        street: e.proposer.address.street,
                        streetNumber: e.proposer.address.streetNumber,
                        complementary: e.proposer.address.complementary === null || e.proposer.address.complementary === undefined ? null : e.proposer.address.complementary,
                        neighborhood: e.proposer.address.neighborhood,
                        city: e.proposer.address.city,
                        state: e.proposer.address.state,
                        country: e.proposer.address.country,
                        countryCode: e.proposer.address.countryCode,
                        latLng: {
                            lat: e.proposer.address.latLng.lat,
                            lng: e.proposer.address.latLng.lng,
                        },
                    },
                    type: e.proposer.type,
                },
                createdAt: new Date(e.createdAt + "Z"),
                proposalStatus: e.proposalStatus,
                price: e.price || 0,
            })),
            createdAt: new Date(e.transportOrder.createdAt + "Z"),
        },
        status: {
            statusName: e.status.statusName,
            changeDate: new Date(e.status.changeDate + "Z"),
        },
        statusHistory: e.statusHistory.map((e: any) => ({
            statusName: e.statusName,
            changeDate: new Date(e.changeDate + "Z"),
        })),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getShipping(shippingId: string, progress?: (progress: number) => void): Promise<Shipping> {
    const args = {
        shippingId: shippingId,
    };
    const ret = await makeRequest({name: "getShipping", args, progress});
    return {
        id: ret.id,
        owner: {
            id: ret.owner.id,
            ratingAverage: ret.owner.ratingAverage || 0,
            vehicles: ret.owner.vehicles === null || ret.owner.vehicles === undefined ? null : ret.owner.vehicles.map((e: any) => ({
                id: e.id,
                model: e.model,
                axisType: e.axisType,
            })),
            createdAt: new Date(ret.owner.createdAt + "Z"),
            editedAt: new Date(ret.owner.editedAt + "Z"),
            name: ret.owner.name,
            email: ret.owner.email,
            phone: ret.owner.phone,
            cpf: ret.owner.cpf === null || ret.owner.cpf === undefined ? null : ret.owner.cpf,
            image: ret.owner.image === null || ret.owner.image === undefined ? null : {
                thumb: {
                    width: ret.owner.image.thumb.width || 0,
                    height: ret.owner.image.thumb.height || 0,
                    url: ret.owner.image.thumb.url,
                },
                width: ret.owner.image.width || 0,
                height: ret.owner.image.height || 0,
                url: ret.owner.image.url,
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                zipcode: ret.owner.address.zipcode,
                street: ret.owner.address.street,
                streetNumber: ret.owner.address.streetNumber,
                complementary: ret.owner.address.complementary === null || ret.owner.address.complementary === undefined ? null : ret.owner.address.complementary,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                state: ret.owner.address.state,
                country: ret.owner.address.country,
                countryCode: ret.owner.address.countryCode,
                latLng: {
                    lat: ret.owner.address.latLng.lat,
                    lng: ret.owner.address.latLng.lng,
                },
            },
            type: ret.owner.type,
        },
        accepter: {
            id: ret.accepter.id,
            ratingAverage: ret.accepter.ratingAverage || 0,
            vehicles: ret.accepter.vehicles === null || ret.accepter.vehicles === undefined ? null : ret.accepter.vehicles.map((e: any) => ({
                id: e.id,
                model: e.model,
                axisType: e.axisType,
            })),
            createdAt: new Date(ret.accepter.createdAt + "Z"),
            editedAt: new Date(ret.accepter.editedAt + "Z"),
            name: ret.accepter.name,
            email: ret.accepter.email,
            phone: ret.accepter.phone,
            cpf: ret.accepter.cpf === null || ret.accepter.cpf === undefined ? null : ret.accepter.cpf,
            image: ret.accepter.image === null || ret.accepter.image === undefined ? null : {
                thumb: {
                    width: ret.accepter.image.thumb.width || 0,
                    height: ret.accepter.image.thumb.height || 0,
                    url: ret.accepter.image.thumb.url,
                },
                width: ret.accepter.image.width || 0,
                height: ret.accepter.image.height || 0,
                url: ret.accepter.image.url,
            },
            address: ret.accepter.address === null || ret.accepter.address === undefined ? null : {
                zipcode: ret.accepter.address.zipcode,
                street: ret.accepter.address.street,
                streetNumber: ret.accepter.address.streetNumber,
                complementary: ret.accepter.address.complementary === null || ret.accepter.address.complementary === undefined ? null : ret.accepter.address.complementary,
                neighborhood: ret.accepter.address.neighborhood,
                city: ret.accepter.address.city,
                state: ret.accepter.address.state,
                country: ret.accepter.address.country,
                countryCode: ret.accepter.address.countryCode,
                latLng: {
                    lat: ret.accepter.address.latLng.lat,
                    lng: ret.accepter.address.latLng.lng,
                },
            },
            type: ret.accepter.type,
        },
        price: ret.price || 0,
        city: ret.city,
        startDate: new Date(ret.startDate + "Z"),
        endDate: new Date(ret.endDate + "Z"),
        cargoOrder: ret.cargoOrder === null || ret.cargoOrder === undefined ? null : {
            id: ret.cargoOrder.id,
            owner: {
                id: ret.cargoOrder.owner.id,
                ratingAverage: ret.cargoOrder.owner.ratingAverage || 0,
                vehicles: ret.cargoOrder.owner.vehicles === null || ret.cargoOrder.owner.vehicles === undefined ? null : ret.cargoOrder.owner.vehicles.map((e: any) => ({
                    id: e.id,
                    model: e.model,
                    axisType: e.axisType,
                })),
                createdAt: new Date(ret.cargoOrder.owner.createdAt + "Z"),
                editedAt: new Date(ret.cargoOrder.owner.editedAt + "Z"),
                name: ret.cargoOrder.owner.name,
                email: ret.cargoOrder.owner.email,
                phone: ret.cargoOrder.owner.phone,
                cpf: ret.cargoOrder.owner.cpf === null || ret.cargoOrder.owner.cpf === undefined ? null : ret.cargoOrder.owner.cpf,
                image: ret.cargoOrder.owner.image === null || ret.cargoOrder.owner.image === undefined ? null : {
                    thumb: {
                        width: ret.cargoOrder.owner.image.thumb.width || 0,
                        height: ret.cargoOrder.owner.image.thumb.height || 0,
                        url: ret.cargoOrder.owner.image.thumb.url,
                    },
                    width: ret.cargoOrder.owner.image.width || 0,
                    height: ret.cargoOrder.owner.image.height || 0,
                    url: ret.cargoOrder.owner.image.url,
                },
                address: ret.cargoOrder.owner.address === null || ret.cargoOrder.owner.address === undefined ? null : {
                    zipcode: ret.cargoOrder.owner.address.zipcode,
                    street: ret.cargoOrder.owner.address.street,
                    streetNumber: ret.cargoOrder.owner.address.streetNumber,
                    complementary: ret.cargoOrder.owner.address.complementary === null || ret.cargoOrder.owner.address.complementary === undefined ? null : ret.cargoOrder.owner.address.complementary,
                    neighborhood: ret.cargoOrder.owner.address.neighborhood,
                    city: ret.cargoOrder.owner.address.city,
                    state: ret.cargoOrder.owner.address.state,
                    country: ret.cargoOrder.owner.address.country,
                    countryCode: ret.cargoOrder.owner.address.countryCode,
                    latLng: {
                        lat: ret.cargoOrder.owner.address.latLng.lat,
                        lng: ret.cargoOrder.owner.address.latLng.lng,
                    },
                },
                type: ret.cargoOrder.owner.type,
            },
            accepter: ret.cargoOrder.accepter === null || ret.cargoOrder.accepter === undefined ? null : {
                id: ret.cargoOrder.accepter.id,
                ratingAverage: ret.cargoOrder.accepter.ratingAverage || 0,
                vehicles: ret.cargoOrder.accepter.vehicles === null || ret.cargoOrder.accepter.vehicles === undefined ? null : ret.cargoOrder.accepter.vehicles.map((e: any) => ({
                    id: e.id,
                    model: e.model,
                    axisType: e.axisType,
                })),
                createdAt: new Date(ret.cargoOrder.accepter.createdAt + "Z"),
                editedAt: new Date(ret.cargoOrder.accepter.editedAt + "Z"),
                name: ret.cargoOrder.accepter.name,
                email: ret.cargoOrder.accepter.email,
                phone: ret.cargoOrder.accepter.phone,
                cpf: ret.cargoOrder.accepter.cpf === null || ret.cargoOrder.accepter.cpf === undefined ? null : ret.cargoOrder.accepter.cpf,
                image: ret.cargoOrder.accepter.image === null || ret.cargoOrder.accepter.image === undefined ? null : {
                    thumb: {
                        width: ret.cargoOrder.accepter.image.thumb.width || 0,
                        height: ret.cargoOrder.accepter.image.thumb.height || 0,
                        url: ret.cargoOrder.accepter.image.thumb.url,
                    },
                    width: ret.cargoOrder.accepter.image.width || 0,
                    height: ret.cargoOrder.accepter.image.height || 0,
                    url: ret.cargoOrder.accepter.image.url,
                },
                address: ret.cargoOrder.accepter.address === null || ret.cargoOrder.accepter.address === undefined ? null : {
                    zipcode: ret.cargoOrder.accepter.address.zipcode,
                    street: ret.cargoOrder.accepter.address.street,
                    streetNumber: ret.cargoOrder.accepter.address.streetNumber,
                    complementary: ret.cargoOrder.accepter.address.complementary === null || ret.cargoOrder.accepter.address.complementary === undefined ? null : ret.cargoOrder.accepter.address.complementary,
                    neighborhood: ret.cargoOrder.accepter.address.neighborhood,
                    city: ret.cargoOrder.accepter.address.city,
                    state: ret.cargoOrder.accepter.address.state,
                    country: ret.cargoOrder.accepter.address.country,
                    countryCode: ret.cargoOrder.accepter.address.countryCode,
                    latLng: {
                        lat: ret.cargoOrder.accepter.address.latLng.lat,
                        lng: ret.cargoOrder.accepter.address.latLng.lng,
                    },
                },
                type: ret.cargoOrder.accepter.type,
            },
            origin: {
                latitude: ret.cargoOrder.origin.latitude,
                longitude: ret.cargoOrder.origin.longitude,
                address: ret.cargoOrder.origin.address,
            },
            destiny: {
                latitude: ret.cargoOrder.destiny.latitude,
                longitude: ret.cargoOrder.destiny.longitude,
                address: ret.cargoOrder.destiny.address,
            },
            startDate: new Date(ret.cargoOrder.startDate + "Z"),
            endDate: new Date(ret.cargoOrder.endDate + "Z"),
            price: ret.cargoOrder.price || 0,
            weight: ret.cargoOrder.weight === null || ret.cargoOrder.weight === undefined ? null : ret.cargoOrder.weight || 0,
            quantity: ret.cargoOrder.quantity || 0,
            animalType: ret.cargoOrder.animalType,
            proposals: ret.cargoOrder.proposals.map((e: any) => ({
                id: e.id,
                proposer: {
                    id: e.proposer.id,
                    ratingAverage: e.proposer.ratingAverage || 0,
                    vehicles: e.proposer.vehicles === null || e.proposer.vehicles === undefined ? null : e.proposer.vehicles.map((e: any) => ({
                        id: e.id,
                        model: e.model,
                        axisType: e.axisType,
                    })),
                    createdAt: new Date(e.proposer.createdAt + "Z"),
                    editedAt: new Date(e.proposer.editedAt + "Z"),
                    name: e.proposer.name,
                    email: e.proposer.email,
                    phone: e.proposer.phone,
                    cpf: e.proposer.cpf === null || e.proposer.cpf === undefined ? null : e.proposer.cpf,
                    image: e.proposer.image === null || e.proposer.image === undefined ? null : {
                        thumb: {
                            width: e.proposer.image.thumb.width || 0,
                            height: e.proposer.image.thumb.height || 0,
                            url: e.proposer.image.thumb.url,
                        },
                        width: e.proposer.image.width || 0,
                        height: e.proposer.image.height || 0,
                        url: e.proposer.image.url,
                    },
                    address: e.proposer.address === null || e.proposer.address === undefined ? null : {
                        zipcode: e.proposer.address.zipcode,
                        street: e.proposer.address.street,
                        streetNumber: e.proposer.address.streetNumber,
                        complementary: e.proposer.address.complementary === null || e.proposer.address.complementary === undefined ? null : e.proposer.address.complementary,
                        neighborhood: e.proposer.address.neighborhood,
                        city: e.proposer.address.city,
                        state: e.proposer.address.state,
                        country: e.proposer.address.country,
                        countryCode: e.proposer.address.countryCode,
                        latLng: {
                            lat: e.proposer.address.latLng.lat,
                            lng: e.proposer.address.latLng.lng,
                        },
                    },
                    type: e.proposer.type,
                },
                createdAt: new Date(e.createdAt + "Z"),
                proposalStatus: e.proposalStatus,
                price: e.price || 0,
            })),
            createdAt: new Date(ret.cargoOrder.createdAt + "Z"),
        },
        transportOrder: ret.transportOrder === null || ret.transportOrder === undefined ? null : {
            id: ret.transportOrder.id,
            owner: {
                id: ret.transportOrder.owner.id,
                ratingAverage: ret.transportOrder.owner.ratingAverage || 0,
                vehicles: ret.transportOrder.owner.vehicles === null || ret.transportOrder.owner.vehicles === undefined ? null : ret.transportOrder.owner.vehicles.map((e: any) => ({
                    id: e.id,
                    model: e.model,
                    axisType: e.axisType,
                })),
                createdAt: new Date(ret.transportOrder.owner.createdAt + "Z"),
                editedAt: new Date(ret.transportOrder.owner.editedAt + "Z"),
                name: ret.transportOrder.owner.name,
                email: ret.transportOrder.owner.email,
                phone: ret.transportOrder.owner.phone,
                cpf: ret.transportOrder.owner.cpf === null || ret.transportOrder.owner.cpf === undefined ? null : ret.transportOrder.owner.cpf,
                image: ret.transportOrder.owner.image === null || ret.transportOrder.owner.image === undefined ? null : {
                    thumb: {
                        width: ret.transportOrder.owner.image.thumb.width || 0,
                        height: ret.transportOrder.owner.image.thumb.height || 0,
                        url: ret.transportOrder.owner.image.thumb.url,
                    },
                    width: ret.transportOrder.owner.image.width || 0,
                    height: ret.transportOrder.owner.image.height || 0,
                    url: ret.transportOrder.owner.image.url,
                },
                address: ret.transportOrder.owner.address === null || ret.transportOrder.owner.address === undefined ? null : {
                    zipcode: ret.transportOrder.owner.address.zipcode,
                    street: ret.transportOrder.owner.address.street,
                    streetNumber: ret.transportOrder.owner.address.streetNumber,
                    complementary: ret.transportOrder.owner.address.complementary === null || ret.transportOrder.owner.address.complementary === undefined ? null : ret.transportOrder.owner.address.complementary,
                    neighborhood: ret.transportOrder.owner.address.neighborhood,
                    city: ret.transportOrder.owner.address.city,
                    state: ret.transportOrder.owner.address.state,
                    country: ret.transportOrder.owner.address.country,
                    countryCode: ret.transportOrder.owner.address.countryCode,
                    latLng: {
                        lat: ret.transportOrder.owner.address.latLng.lat,
                        lng: ret.transportOrder.owner.address.latLng.lng,
                    },
                },
                type: ret.transportOrder.owner.type,
            },
            accepter: ret.transportOrder.accepter === null || ret.transportOrder.accepter === undefined ? null : {
                id: ret.transportOrder.accepter.id,
                ratingAverage: ret.transportOrder.accepter.ratingAverage || 0,
                vehicles: ret.transportOrder.accepter.vehicles === null || ret.transportOrder.accepter.vehicles === undefined ? null : ret.transportOrder.accepter.vehicles.map((e: any) => ({
                    id: e.id,
                    model: e.model,
                    axisType: e.axisType,
                })),
                createdAt: new Date(ret.transportOrder.accepter.createdAt + "Z"),
                editedAt: new Date(ret.transportOrder.accepter.editedAt + "Z"),
                name: ret.transportOrder.accepter.name,
                email: ret.transportOrder.accepter.email,
                phone: ret.transportOrder.accepter.phone,
                cpf: ret.transportOrder.accepter.cpf === null || ret.transportOrder.accepter.cpf === undefined ? null : ret.transportOrder.accepter.cpf,
                image: ret.transportOrder.accepter.image === null || ret.transportOrder.accepter.image === undefined ? null : {
                    thumb: {
                        width: ret.transportOrder.accepter.image.thumb.width || 0,
                        height: ret.transportOrder.accepter.image.thumb.height || 0,
                        url: ret.transportOrder.accepter.image.thumb.url,
                    },
                    width: ret.transportOrder.accepter.image.width || 0,
                    height: ret.transportOrder.accepter.image.height || 0,
                    url: ret.transportOrder.accepter.image.url,
                },
                address: ret.transportOrder.accepter.address === null || ret.transportOrder.accepter.address === undefined ? null : {
                    zipcode: ret.transportOrder.accepter.address.zipcode,
                    street: ret.transportOrder.accepter.address.street,
                    streetNumber: ret.transportOrder.accepter.address.streetNumber,
                    complementary: ret.transportOrder.accepter.address.complementary === null || ret.transportOrder.accepter.address.complementary === undefined ? null : ret.transportOrder.accepter.address.complementary,
                    neighborhood: ret.transportOrder.accepter.address.neighborhood,
                    city: ret.transportOrder.accepter.address.city,
                    state: ret.transportOrder.accepter.address.state,
                    country: ret.transportOrder.accepter.address.country,
                    countryCode: ret.transportOrder.accepter.address.countryCode,
                    latLng: {
                        lat: ret.transportOrder.accepter.address.latLng.lat,
                        lng: ret.transportOrder.accepter.address.latLng.lng,
                    },
                },
                type: ret.transportOrder.accepter.type,
            },
            origin: {
                latitude: ret.transportOrder.origin.latitude,
                longitude: ret.transportOrder.origin.longitude,
                address: ret.transportOrder.origin.address,
            },
            destiny: {
                latitude: ret.transportOrder.destiny.latitude,
                longitude: ret.transportOrder.destiny.longitude,
                address: ret.transportOrder.destiny.address,
            },
            vehicle: {
                id: ret.transportOrder.vehicle.id,
                model: ret.transportOrder.vehicle.model,
                axisType: ret.transportOrder.vehicle.axisType,
            },
            originDistanceRadius: ret.transportOrder.originDistanceRadius || 0,
            destinyDistanceRadius: ret.transportOrder.destinyDistanceRadius || 0,
            startDate: new Date(ret.transportOrder.startDate + "Z"),
            endDate: new Date(ret.transportOrder.endDate + "Z"),
            price: ret.transportOrder.price || 0,
            weight: ret.transportOrder.weight || 0,
            proposals: ret.transportOrder.proposals.map((e: any) => ({
                id: e.id,
                proposer: {
                    id: e.proposer.id,
                    ratingAverage: e.proposer.ratingAverage || 0,
                    vehicles: e.proposer.vehicles === null || e.proposer.vehicles === undefined ? null : e.proposer.vehicles.map((e: any) => ({
                        id: e.id,
                        model: e.model,
                        axisType: e.axisType,
                    })),
                    createdAt: new Date(e.proposer.createdAt + "Z"),
                    editedAt: new Date(e.proposer.editedAt + "Z"),
                    name: e.proposer.name,
                    email: e.proposer.email,
                    phone: e.proposer.phone,
                    cpf: e.proposer.cpf === null || e.proposer.cpf === undefined ? null : e.proposer.cpf,
                    image: e.proposer.image === null || e.proposer.image === undefined ? null : {
                        thumb: {
                            width: e.proposer.image.thumb.width || 0,
                            height: e.proposer.image.thumb.height || 0,
                            url: e.proposer.image.thumb.url,
                        },
                        width: e.proposer.image.width || 0,
                        height: e.proposer.image.height || 0,
                        url: e.proposer.image.url,
                    },
                    address: e.proposer.address === null || e.proposer.address === undefined ? null : {
                        zipcode: e.proposer.address.zipcode,
                        street: e.proposer.address.street,
                        streetNumber: e.proposer.address.streetNumber,
                        complementary: e.proposer.address.complementary === null || e.proposer.address.complementary === undefined ? null : e.proposer.address.complementary,
                        neighborhood: e.proposer.address.neighborhood,
                        city: e.proposer.address.city,
                        state: e.proposer.address.state,
                        country: e.proposer.address.country,
                        countryCode: e.proposer.address.countryCode,
                        latLng: {
                            lat: e.proposer.address.latLng.lat,
                            lng: e.proposer.address.latLng.lng,
                        },
                    },
                    type: e.proposer.type,
                },
                createdAt: new Date(e.createdAt + "Z"),
                proposalStatus: e.proposalStatus,
                price: e.price || 0,
            })),
            createdAt: new Date(ret.transportOrder.createdAt + "Z"),
        },
        status: {
            statusName: ret.status.statusName,
            changeDate: new Date(ret.status.changeDate + "Z"),
        },
        statusHistory: ret.statusHistory.map((e: any) => ({
            statusName: e.statusName,
            changeDate: new Date(e.changeDate + "Z"),
        })),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllShippingStatistics(filter: ShippingFilter, progress?: (progress: number) => void): Promise<ShippingStatistics> {
    const args = {
        filter: {
            driverId: filter.driverId === null || filter.driverId === undefined ? null : filter.driverId,
            status: filter.status === null || filter.status === undefined ? null : filter.status,
            startPeriodDate: filter.startPeriodDate === null || filter.startPeriodDate === undefined ? null : {
                startDate: typeof(filter.startPeriodDate.startDate) === "string" ? new Date(new Date(filter.startPeriodDate.startDate).getTime() - new Date(filter.startPeriodDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startPeriodDate.startDate.getTime() - filter.startPeriodDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: typeof(filter.startPeriodDate.endDate) === "string" ? new Date(new Date(filter.startPeriodDate.endDate).getTime() - new Date(filter.startPeriodDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startPeriodDate.endDate.getTime() - filter.startPeriodDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            endPeriodDate: filter.endPeriodDate === null || filter.endPeriodDate === undefined ? null : {
                startDate: typeof(filter.endPeriodDate.startDate) === "string" ? new Date(new Date(filter.endPeriodDate.startDate).getTime() - new Date(filter.endPeriodDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endPeriodDate.startDate.getTime() - filter.endPeriodDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: typeof(filter.endPeriodDate.endDate) === "string" ? new Date(new Date(filter.endPeriodDate.endDate).getTime() - new Date(filter.endPeriodDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endPeriodDate.endDate.getTime() - filter.endPeriodDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            city: filter.city === null || filter.city === undefined ? null : filter.city,
        },
    };
    const ret = await makeRequest({name: "getAllShippingStatistics", args, progress});
    return {
        endShippingCount: ret.endShippingCount || 0,
        currentShippingCount: ret.currentShippingCount || 0,
    };
}

export async function getNotCanceledShippingsInfo(filter: ShippingFilter, progress?: (progress: number) => void): Promise<NotCanceledShippingInfo> {
    const args = {
        filter: {
            driverId: filter.driverId === null || filter.driverId === undefined ? null : filter.driverId,
            status: filter.status === null || filter.status === undefined ? null : filter.status,
            startPeriodDate: filter.startPeriodDate === null || filter.startPeriodDate === undefined ? null : {
                startDate: typeof(filter.startPeriodDate.startDate) === "string" ? new Date(new Date(filter.startPeriodDate.startDate).getTime() - new Date(filter.startPeriodDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startPeriodDate.startDate.getTime() - filter.startPeriodDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: typeof(filter.startPeriodDate.endDate) === "string" ? new Date(new Date(filter.startPeriodDate.endDate).getTime() - new Date(filter.startPeriodDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startPeriodDate.endDate.getTime() - filter.startPeriodDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            endPeriodDate: filter.endPeriodDate === null || filter.endPeriodDate === undefined ? null : {
                startDate: typeof(filter.endPeriodDate.startDate) === "string" ? new Date(new Date(filter.endPeriodDate.startDate).getTime() - new Date(filter.endPeriodDate.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endPeriodDate.startDate.getTime() - filter.endPeriodDate.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
                endDate: typeof(filter.endPeriodDate.endDate) === "string" ? new Date(new Date(filter.endPeriodDate.endDate).getTime() - new Date(filter.endPeriodDate.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endPeriodDate.endDate.getTime() - filter.endPeriodDate.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
            city: filter.city === null || filter.city === undefined ? null : filter.city,
        },
    };
    const ret = await makeRequest({name: "getNotCanceledShippingsInfo", args, progress});
    return {
        totalPrice: ret.totalPrice || 0,
        shippingsCount: ret.shippingsCount || 0,
    };
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
