import React from "react";
import { useHistory } from "react-router";

import "./style.scss";

import { CardHeader, Divider, IconButton, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

interface IProps {
	onBackPress?: () => void;
	title: string;
}

export const BackButtonHeader = (props: IProps) => {
	const history = useHistory();

	return (
		<>
			<CardHeader
				className="rowWidth"
				title={<Typography variant="h6">{props.title}</Typography>}
				avatar={
					<IconButton
						onClick={
							props.onBackPress
								? props.onBackPress
								: () => history.goBack()
						}
					>
						<ArrowBack />
					</IconButton>
				}
			/>
			<Divider />
		</>
	);
};
