import React from "react";
import { Switch, Route } from "react-router-dom";

import { observer } from "mobx-react-lite";

import TableView from "./TableView";
import DetailsView from "./DetailsView";

interface IProps {}

const Shipping: React.FC<IProps> = () => (
	<Switch>
		<Route exact path="/dashboard/shipping" component={TableView} />
		<Route path="/dashboard/shipping/details/:id" component={DetailsView} />
	</Switch>
);

export default observer(Shipping);
