import React from "react";

// MARK: Components
import { useHistory } from "react-router";

import {
	List,
	ListItem,
	ListItemText,
	Popover,
	IconButton,
	Avatar,
} from "@material-ui/core";

// MARK: Resources
import Strings from "../../../modules/Strings";
import { useGlobalStore } from "../../../pages/context";

// Hooks
export const AvatarWithOptions = () => {
	const history = useHistory();
	const { authStore } = useGlobalStore();
	const [open] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null,
	);
	const openAvatar = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleAvatarClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className="userAvatar">
			<IconButton onClick={handleAvatarClick}>
				{authStore.adminUser && (
					<Avatar className="avatar">
						{authStore.adminUser?.name.substring(0, 1)}
					</Avatar>
				)}
			</IconButton>

			<Popover
				id={id}
				open={openAvatar}
				anchorEl={anchorEl}
				onClose={handleAvatarClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<List>
					<ListItem
						button
						onClick={() => authStore.logout(() => history.replace("/login"))}
					>
						<ListItemText primary={Strings.nav.logout} />
					</ListItem>
				</List>
			</Popover>
		</div>
	);
};
