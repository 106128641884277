import { IRouterPusher } from "../../../../interfaces/IRouter";
import api from "../../../../modules/API";
import AuthStore from "../../../../stores/AuthStore";
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import UIStore from "../../../../stores/UIStore";

export default class TableStore extends PaginatedListStore<api.Secure> {
	private authStore: AuthStore;

	private uiStore: UIStore;

	public shippingStatics: api.ShippingStatistics;

	public loading = false;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(uiStore, router);
		this.fetchPage(0);
		this.authStore = new AuthStore(uiStore);
		this.uiStore = uiStore;
	}

	protected getDataItemsPerPage(page: number): Promise<api.Secure[]> {
		return api.getAllSecures(page);
	}
}
