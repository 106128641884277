import React from "react";

import { DetailRow } from "../DetailRow";
import { Formatting } from "../../modules/Formatting";
import { Typography } from "@material-ui/core";

import Strings from "../../modules/Strings";
import API from "../../modules/API";
import useStyles from "./styles";
import moment from "moment";

interface ICargoProps {
	cargo: API.CargoOrder;
}
const CargoOrder: React.FC<ICargoProps> = (props) => {
	const { cargo } = props;
	const classes = useStyles();
	const ComponentStrings = Strings.components.cargoOrder;
	return (
		<>
			<Typography variant="h6" className={classes.title}>
				{ComponentStrings.title}
			</Typography>
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={Strings.fields.createBy}
				value={cargo.owner.name}
			/>
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.originAddress}
				value={cargo.origin.address}
			/>
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.destinyAddress}
				value={cargo.destiny.address}
			/>
			{cargo.weight && (
				<DetailRow
					mainCardStyle={classes.detailsRowCard}
					label={ComponentStrings.weight}
					value={cargo.weight}
				/>
			)}
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.animalType}
				value={API.translateAnimalType(cargo.animalType)}
			/>
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.departureDate}
				value={moment(cargo.startDate).format("DD/MM/YYYY")}
			/>
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.arrivedDate}
				value={moment(cargo.endDate).format("DD/MM/YYYY")}
			/>
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.createDate}
				value={moment(cargo.createdAt).format("DD/MM/YYYY")}
			/>

			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.price}
				value={Formatting.formatCurrencyBR(cargo.price)}
			/>
		</>
	);
};
export default CargoOrder;
