import React from "react";
import useStyles from "./style";

import { observer } from "mobx-react-lite";

import {
	Card,
	CardContent,
	CardActions,
	Paper,
	AppBar,
} from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "../../components/CircularProgress";
import TextField from "../../components/TextField";
import Button from "../../components/Button";

import Strings from "../../modules/Strings";

import { useHistory, useParams } from "react-router";

import { useGlobalStore } from "../context";

export const Recovery = observer(() => {
	const [hasToken, setHasToken] = React.useState(false);

	const { token } = useParams<{ token: string }>();
	const { authStore } = useGlobalStore();
	const classes = useStyles();
	const history = useHistory();

	React.useEffect(() => {
		if (token && !authStore.receivedToken) {
			authStore.validateTokenUrl(
				() => {},
				() => history.push("/recovery/token"),
				token,
			);
		}
	}, []);

	return (
		<>
			<AppBar position="static" className={classes.appBar}>
				<Typography className={classes.pageTitle} variant="h6">
					{Strings.pageLogin.pagetitle}
				</Typography>
			</AppBar>
			{authStore.loader.isLoading ? (
				<CircularProgress />
			) : (
				<>
					<Paper className={classes.pageContent} elevation={0}>
						<Card className={classes.card}>
							{authStore.receivedToken && authStore.receivedToken.length > 0 ? (
								<CardContent>
									<Typography className={classes.recoveryTitle} variant="h5">
										{Strings.recoveryPage.newAccess}
									</Typography>
									<TextField
										error={!!authStore.fieldError.password}
										helperText={authStore.fieldError.password}
										inputProps={{
											className: classes.input,
										}}
										InputLabelProps={{
											className: classes.label,
										}}
										{...authStore.formController.field("newPassword")}
										label={Strings.recoveryPage.newPassword}
										type="password"
										disabled={authStore.loader.isLoading}
									/>
									<TextField
										error={!!authStore.fieldError.confirmNewPassword}
										helperText={authStore.fieldError.confirmNewPassword}
										inputProps={{
											className: classes.input,
										}}
										InputLabelProps={{
											className: classes.label,
										}}
										{...authStore.formController.field("confirmNewPassword")}
										label={Strings.recoveryPage.confirmNewPassword}
										type="password"
										disabled={authStore.loader.isLoading}
									/>
									<CardActions>
										<Button
											variant="outlined"
											className={classes.loginButton}
											onClick={() => {
												setHasToken(!hasToken);
												authStore.resetPassword(() => {
													history.push("/dashboard");
												});
											}}
										>
											{Strings.recoveryPage.newPasswordButton}
										</Button>
									</CardActions>
								</CardContent>
							) : (
								<CardContent>
									<Typography className={classes.recoveryTitle} variant="h5">
										{Strings.recoveryPage.title}
									</Typography>
									{hasToken ? (
										<TextField
											error={!!authStore.fieldError.token}
											helperText={authStore.fieldError.token}
											inputProps={{
												className: classes.input,
											}}
											InputLabelProps={{
												className: classes.label,
											}}
											disabled={authStore.loader.isLoading}
											{...authStore.formController.field("token")}
											label={Strings.fields.token}
											type="text"
										/>
									) : (
										<TextField
											error={!!authStore.fieldError.email}
											helperText={authStore.fieldError.email}
											inputProps={{
												className: classes.input,
											}}
											InputLabelProps={{
												className: classes.label,
											}}
											{...authStore.formController.field("email")}
											label={Strings.fields.email}
											disabled={authStore.loader.isLoading}
											type="email"
										/>
									)}
									<CardActions className={classes.cardActions}>
										<Button
											variant="outlined"
											className={classes.loginButton}
											onClick={() => {
												if (hasToken) {
													authStore.validateToken(() => {
														history.push(
															`/recovery/token/${authStore.receivedToken}`,
														);
													});
												} else {
													authStore.sendNewPassword(() => {
														history.push("/recovery/token");
														setHasToken(!hasToken);
													});
												}
											}}
										>
											{hasToken
												? Strings.recoveryPage.confirmTokenButton
												: Strings.recoveryPage.recoverPasswordButton}
										</Button>
										<Button
											variant="contained"
											className={classes.tokenButton}
											onClick={() => {
												setHasToken(!hasToken);
												authStore.clearErrors();
											}}
										>
											{hasToken
												? Strings.recoveryPage.noTokenButton
												: Strings.recoveryPage.tokenButton}
										</Button>
										<Button
											variant="contained"
											className={classes.backButton}
											onClick={() => history.push("/login")}
										>
											{Strings.recoveryPage.backButton}
										</Button>
									</CardActions>
								</CardContent>
							)}
						</Card>
					</Paper>
				</>
			)}
		</>
	);
});
