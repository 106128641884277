import React from "react";
import { FormControl, Select, InputLabel  } from "@material-ui/core";
import { observer } from "mobx-react-lite";

interface IProps<EnumType> {
	onChange: (fieldName: EnumType | undefined) => void;
	allValuesEnum: () => string[];
	translateEnum: (enumValue: EnumType) => string;
	error?: boolean;
	className?: string;
	title: string;
	value?: EnumType;
}

const EnumPicker = <EnumType extends string>(props: IProps<EnumType>) => {
	const {
		allValuesEnum,
		error,
		onChange,
		translateEnum,
		title,
		className,
		value,
	} = props;
	return (
		<FormControl className={className}>
			<InputLabel>{title}</InputLabel >
			<Select
				native
				variant="standard"
				onChange={(event) => {
					if(!event.target.value) {
						onChange(undefined);
					}
					onChange(event.target.value as EnumType);
				}}
				error={error}
				value={value ? value : ""}
			>
				<option aria-selected value={undefined} />
				{allValuesEnum().map((enumValue: EnumType, index: number) => (
					<option key={index} aria-selected value={enumValue}>
						{translateEnum(enumValue)}
					</option>
				))}
			</Select>
		</FormControl>
	);
};

export default observer(EnumPicker);
