import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import useStyles from "./styles";

interface IProps {
	title: string;
	value: string | number;
	classNameStyle?: string;
}

const MiniCard: React.FC<IProps> = (props) => {
	const classes = useStyles();
	const { title, value, classNameStyle } = props;
	return (
		<Card className={[classes.cardNumber, classNameStyle].join(" ")}>
			<CardContent>
				<Typography variant="h6" className={classes.title}>
					{title}
				</Typography>
				<Typography variant="h5" className={classes.text}>
					{value}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default MiniCard;
