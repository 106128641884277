import { createStyles, Theme, makeStyles } from "@material-ui/core";


export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawerClosed: {
			position: "absolute",
			left: "-100%",
			width: 280,
			padding: 10,
			transition: "left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
			transitionProperty: "left",
			transitionDuration: "195ms",
		},
		drawerOpen: {
			position: "absolute",
			left: "0",
			width: 280,
			padding: 10,
			transition: "left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
			transitionProperty: "left",
			transitionDuration: "195ms",
		},
	}),
);

