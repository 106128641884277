import React from "react";

import { observer, useLocalStore } from "mobx-react-lite";
import CargoOrderDetails from "../../../../../../components/CargoOrderDetails";
import { Box, CircularProgress } from "@material-ui/core";

import Store from "./store";
interface ICargoProps {
	cargoId: string;
}
const CargoOrder: React.FC<ICargoProps> = (props) => {
	const { cargoId } = props;
	const store = useLocalStore(() => new Store(cargoId));
	return (
		<>
			{store.cargo ? (
				<CargoOrderDetails cargo={store.cargo} />
			) : (
				<Box
					style={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						width: "100%",
					}}
				>
					<CircularProgress />
				</Box>
			)}
		</>
	);
};
export default observer(CargoOrder);
