import React from "react";
import { Route, Switch } from "react-router-dom";

import { observer } from "mobx-react-lite";

import { useAuthRoute } from "../../hooks/useAuthRoute";
import { MainLayout } from "../../layouts/MainLayout";
import AdminUsers from "./AdminUsers";
import Home from "./Home";
import Users from "./Users";
import Insured from "./Insured";
import Shipping from "./Shipping";
import CargoOrder from "./CargoOrder";

export const Dashboard = (): JSX.Element => {
	useAuthRoute();
	return (
		<MainLayout>
			<Switch>
				<Route exact path="/dashboard" component={Home} />
				<Route path="/dashboard/adminUsers" component={AdminUsers} />
				<Route path="/dashboard/users" component={Users} />
				<Route path="/dashboard/insured" component={Insured} />
				<Route path="/dashboard/shipping" component={Shipping} />
				<Route path="/dashboard/cargoOrder" component={CargoOrder} />
			</Switch>
		</MainLayout>
	);
};

export default observer(Dashboard);
