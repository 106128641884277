import React, { useState } from "react";

// MARK: Mobx

// MARK: Hooks

// MARK: Components
import { Paper } from "@material-ui/core";
import { observer } from "mobx-react-lite";

import { useIsMobile } from "../../hooks/useIsMobile";
import { BaseLayout } from "../BaseLayout";
import DesktopDrawer from "./DesktopDrawer";
import MobileDrawer from "./MobileDrawer";
import Navbar from "./Navbar";
import { useStyles } from "./style";

export const MainLayout = observer((props) => {
	const classes = useStyles();
	const isMobile = useIsMobile();
	const [open, setOpen] = useState(false);

	return (
		<>
			<Navbar toggleDrawer={() => setOpen(!open)} />
			{isMobile ? (
				<>
					<MobileDrawer open={open} setOpen={setOpen} />
					<Paper className={classes.mainLayoutMobile}>
						{props.children}
					</Paper>
				</>
			) : (
				<>
					<DesktopDrawer open={open} />
					<Paper
						className={
							open && !isMobile
								? classes.mainLayoutContracted
								: classes.mainLayout
						}
					>
						{props.children}
					</Paper>
				</>
			)}
			<BaseLayout />
		</>
	);
});

export default observer(MainLayout);
