import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { observer, useLocalStore } from "mobx-react-lite";

import { DetailRow } from "../../../../components/DetailRow";
import DetailsForm from "../../../../components/DetailsForm";
import Strings from "../../../../modules/Strings";
import { useGlobalStore } from "../../../context";
import { CargoOrder, TransportOrder } from "./components";
import Store from "./store";
import API from "../../../../modules/API";
import useStyles from "./styles";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { uiStore } = useGlobalStore();
	const classes = useStyles();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));
	const ScreenStrings = Strings.shipping.details;

	return (
		<DetailsForm
			title={ScreenStrings.title}
			modelContent={!!store.model}
			loading={store.loader.isLoading}
			contentStyle={classes.detailsFormContent}
		>
			{store.model && (
				<>
					<DetailRow
						mainCardStyle={classes.detailsRowCard}
						label={ScreenStrings.id}
						value={store.model.id}
					/>
					<DetailRow
						mainCardStyle={classes.detailsRowCard}
						label={ScreenStrings.currentStatus}
						value={API.translateShippingStatusOptions(
							store.model.status.statusName,
						)}
					/>
					{store.model.transportOrder && (
						<TransportOrder transport={store.model.transportOrder} />
					)}
					{store.model.cargoOrder && (
						<CargoOrder cargo={store.model.cargoOrder} />
					)}
				</>
			)}
		</DetailsForm>
	);
};
export default observer(DetailsView);
