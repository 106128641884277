import { runInAction, makeObservable, observable } from "mobx";

import { Errors } from "../modules/Errors";
import LoaderShelf from "../shelves/LoaderShelf";
import UIStore from "./UIStore";

export default abstract class ModelStore<ModelType, IdType = string> {
	public model: ModelType | null = null;

	public loader: LoaderShelf;

	public error: string | null = null;

	protected id: IdType;

	protected uiStore: UIStore;

	constructor(id: IdType, uiStore: UIStore) {
		this.uiStore = uiStore;
		this.id = id;
		this.loader = new LoaderShelf();
		this.fetchModel(id);
		makeObservable(this, {
			model: observable,
			error: observable,
		});
	}

	protected abstract getModel(id: IdType): Promise<ModelType>;

	private fetchModel = async (id: IdType) => {
		if (this.error) {
			runInAction(() => this.error = null);
		}
		try {
			this.loader.start();
			const resultModel = await this.getModel(id);
			runInAction(() => (this.model = resultModel));
			this.afterModelFetch(resultModel);
		} catch (e) {
			runInAction(() => (this.error = Errors.handleError(e)));
			this.afterModelFetchError();
		} finally {
			this.loader.end();
			this.afterAll();
		}
	};

	protected afterModelFetch(model: ModelType) {}

	protected afterModelFetchError() {}

	protected afterAll() {}
}
