import { grey, green, red } from "@material-ui/core/colors";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let darkTheme = createMuiTheme({
	title: "darkTheme",
	palette: {
		type: "dark",
		primary: {
			light: grey[700],
			main: grey[900],
			contrastText: grey[100],
		},

		secondary: {
			light: grey[700],
			main: grey[100],
			contrastText: grey[900],
		},

		success: {
			light: green[400],
			main: green[500],
		},

		error: {
			light: red.A700,
			main: red.A700,
		},

		neutral: {
			light: grey[100],
			main: grey[900],
			contrastText: grey[900],
		},
	},
});

darkTheme = responsiveFontSizes(darkTheme);

export default darkTheme;
