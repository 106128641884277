import { IRouterPusher } from "../../../../interfaces/IRouter";
import API from "../../../../modules/API";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";

export default class Store extends ModelStore<API.User> {
	private router: IRouterPusher;

	public adminUser: API.AdminUser | null = null;

	constructor(id: string, uiStore: UIStore, router: IRouterPusher) {
		super(id, uiStore);
		this.router = router;
	}

	protected async getModel(id: string): Promise<API.User> {
		return await API.getUserById(id);
	}
}
