// tslint:disable
import React from "react";

import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select as MuiSelect,
	SelectProps,
} from "@material-ui/core";

import { observer } from "mobx-react";

export interface IField {
	name: string;
	value: string | number;
}

interface IProps extends SelectProps {
	fields: IField[];
	title: string;
	helperText?: string;
	width?: string | number;
	height?: string | number;
}

const Select: React.FC<IProps> = ({
	fields,
	title,
	className,
	helperText,
	width,
	height,
	defaultValue,
	...props
}) => {
	const sizes = { width: width || "200px", height: height || 0 };

	return (
		<FormControl style={{ ...sizes }} className={className} error={props.error}>
			<InputLabel htmlFor="grouped-native-select">{title}</InputLabel>
			<MuiSelect
				defaultValue={defaultValue}
				id="grouped-native-select"
				{...props}
			>
				{fields.map((field, index) => (
					<MenuItem key={`${index}-${field.value}`} value={field.value}>
						{field.name}
					</MenuItem>
				))}
			</MuiSelect>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};

export default observer(Select);
