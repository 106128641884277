import { makeAutoObservable } from "mobx";

export default class LoaderShelf {
	private _loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	public start() {
		this._loading = true;
	}

	public end() {
		this._loading = false;
	}

	public get isLoading() {
		return this._loading;
	}

	public async run<T>(
		asyncCall: () => Promise<T>,
		onError: (e: any) => void = (e: any) => {},
	) {
		try {
			this.start();
			await asyncCall();
		} catch (e) {
			onError(e);
		} finally {
			this.end();
		}
	}
}
