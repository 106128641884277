import { makeObservable, observable, runInAction } from "mobx";

import { IRouterPusher } from "../interfaces/IRouter";
import API from "../modules/API";
import Strings from "../modules/Strings";
import { CustomError } from "../modules/CustomError";
import UIStore from "./UIStore";

export default abstract class PaginatedListStore<ItemType> {
	private router: IRouterPusher;

	private _uiStore: UIStore;

	public _loading = false;

	public _page = 0;

	public _items: ItemType[] = [];

	public _error: string | null = null;

	public _isHaveNextPage = false;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		this.router = router;
		this._uiStore = uiStore;

		makeObservable(this, {
			_loading: observable,
			_page: observable,
			_items: observable,
			_error: observable,
			_isHaveNextPage: observable,
		});
	}

	public isHaveNextPage = () => {
		return this._isHaveNextPage;
	};

	public page = () => {
		return this._page;
	};

	protected abstract getDataItemsPerPage(page: number): Promise<ItemType[]>;

	public fetchPage = async (page: number) => {
		if (page < 0) {
			return;
		}

		if (this._loading) {
			throw new CustomError(
				API.ErrorType.Fatal,
				Strings.error.mustWaitWhileStillLoading,
			);
		}

		runInAction(() => (this._loading = true));

		try {
			const newItems: ItemType[] = await this.getDataItemsPerPage(page);
			if (newItems.length === 0) {
				runInAction(() => {
					this._uiStore.showSnackbar(Strings.components.table.noMoreResults);
					this._isHaveNextPage = false;
				});
			} else {
				runInAction(() => this._isHaveNextPage = true);
			}
			runInAction(() => {
				this._items = newItems;
				this._page = page;
			});
		} catch (e) {
			if (e.type && e.type === API.ErrorType.NotLoggedIn) {
				this.router.push("/");
			}
			runInAction(() => {
				this._error = e.message || "error";
			});

			throw e;
		} finally {
			runInAction(() => (this._loading = false));
		}
	};

	public refresh = (page?: number) => {
		this.fetchPage(page || this.page());
	};

	public clear = () => {
		runInAction(() => (this._items = []));
	};

	public applyFilter = (
		predicate: (item: ItemType, index: number, array: ItemType[]) => void,
	) => {
		runInAction(() => (this._items = this._items.filter(predicate)));
	};

	public nextPage = async () => await this.fetchPage(this._page + 1);

	public previousPage = async () => await this.fetchPage(this._page - 1);

	public getPagination() {
		return {
			page: this.page(),
			onNextClick: () => this.nextPage(),
			onPreviousClick: () => this.previousPage(),
		};
	}

	public setLoading = (isLoading: boolean) => {
		runInAction(() => (this._loading = isLoading));
		return null;
	};
}
