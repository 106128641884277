/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-invalid-this */
import { makeAutoObservable, runInAction } from "mobx";

import API from "../../modules/API";
import { Errors } from "../../modules/Errors";
import Strings from "../../modules/Strings";
import LoaderShelf from "../../shelves/LoaderShelf";
import FormStore from "../FormStore";
import UIStore from "../UIStore";

export default class AuthStore {
	private uiStore: UIStore;

	public adminUser: API.AdminUser | null = null;

	public loader: LoaderShelf;

	public fieldError = {
		email: "",
		password: "",
		newPassword: "",
		confirmNewPassword: "",
		token: "",
	};

	public receivedToken = "";

	public formController = new FormStore({
		email: "",
		password: "",
		newPassword: "",
		confirmNewPassword: "",
		token: "",
	});

	protected localStorageKey = "%CampoLog_AdminUser%";

	constructor(uiStore: UIStore) {
		this.uiStore = uiStore;
		this.loader = new LoaderShelf();
		this.updateUser();
		makeAutoObservable(this);
	}

	public isLoginFormReady() {
		const data = this.formController.getValues();
		return data.email.length > 5 && data.password.length >= 6;
	}

	private updateUser = () => {
		const adminUser = localStorage.getItem(this.localStorageKey);
		runInAction(
			() =>
				(this.adminUser = adminUser
					? JSON.parse(localStorage.getItem(this.localStorageKey) as string)
					: null),
		);
	};

	public login = async (onSuccess?: () => void) => {
		const data = this.formController.getValues();
		this.loader.start();
		try {
			const resultAdminUser = await API.login(
				data.email.trim(),
				data.password.trim(),
			);
			runInAction(() => (this.adminUser = resultAdminUser));

			localStorage.setItem(
				this.localStorageKey,
				JSON.stringify(this.adminUser),
			);

			if (onSuccess) {
				onSuccess();
			}

			if ("Notification" in window) {
				if (Notification.permission === "granted") {
					const notification = new Notification(Strings.hiAdmin);
				}
			}

			this.clear();
		} catch (e) {
			this.uiStore.showSnackbar(Errors.handleError(e));
		} finally {
			this.loader.end();
		}
	};

	public logout = async (onSuccess?: () => void) => {
		this.uiStore.showDialog(Strings.logoutDialog, async () => {
			try {
				await API.logout();
				runInAction(() => (this.adminUser = null));
				localStorage.removeItem(this.localStorageKey);
				if (onSuccess) {
					onSuccess();
				}
			} catch (e) {
				this.uiStore.showErrorSnackbar(e);
			}
		});
	};

	public isLogged = async () => {
		this.loader.start();

		try {
			const resultAdminUser = await API.getCurrentAdminUser();
			runInAction(() => (this.adminUser = resultAdminUser));
		} catch (e) {
			if (e.type !== API.ErrorType.DeviceNotFound) {
				this.uiStore.showSnackbar(Errors.handleError(e), "warning");
			}
			localStorage.removeItem(this.localStorageKey);
		} finally {
			this.loader.end();
		}

		return !!this.adminUser;
	};

	public sendNewPassword = async (onSuccess: () => void) => {
		this.clearErrors();
		const data = this.formController.getValues();
		this.loader.start();

		try {
			await API.sendResetPasswordAdminUser(data.email);
			this.uiStore.showSuccessSnackbar();
			onSuccess();
		} catch (e) {
			runInAction(() => (this.fieldError.email = e.message));
			this.uiStore.showErrorSnackbar(e);
		} finally {
			this.loader.end();
		}
	};

	public resetPassword = async (onSuccess: () => void) => {
		this.clearErrors();
		const data = this.formController.getValues();

		if (this.loader.isLoading) {
			return;
		}

		this.loader.start();

		try {
			if (data.newPassword !== data.confirmNewPassword) {
				runInAction(() => {
					this.fieldError.password = Strings.recoveryPage.samePasswordError;
					this.fieldError.confirmNewPassword =
						Strings.recoveryPage.samePasswordError;
				});
			} else {
				await API.resetPasswordEmailAdminUser(
					this.receivedToken,
					data.newPassword,
				);
				runInAction(() => {
					data.password = data.newPassword;
				});
				this.login();
				this.clear();
				this.uiStore.showSuccessSnackbar();
				onSuccess();
			}
		} catch (e) {
			const error = JSON.parse(e.message);
			error.map((message) => {
				runInAction(() => (this.fieldError[message.field] = message.message));
				this.uiStore.showErrorSnackbar(message.message);
			});
		} finally {
			this.loader.end();
		}
	};

	public validateToken = async (onSuccess: () => void) => {
		this.clearErrors();
		const data = this.formController.getValues();
		if (this.loader.isLoading) {
			return;
		}

		this.loader.start();

		try {
			const resultValidate = await API.validateToken(data.token);

			if (resultValidate) {
				runInAction(() => {
					this.receivedToken = data.token;
				});
			}
			this.uiStore.showSnackbar(Strings.recoveryPage.validToken, "success");
			onSuccess();
		} catch (e) {
			runInAction(() => (this.fieldError.token = e.message));
			this.uiStore.showErrorSnackbar(e);
		} finally {
			this.loader.end();
		}
	};

	public validateTokenUrl = async (
		onSuccess: () => void,
		onError: () => void,
		token: string,
	) => {
		this.clearErrors();
		if (this.loader.isLoading) {
			return;
		}

		this.loader.start();

		try {
			const resultValidateUrl = await API.validateToken(token);
			if (resultValidateUrl) {
				runInAction(() => (this.receivedToken = token));
			}

			onSuccess();
		} catch (e) {
			onError();
		} finally {
			this.loader.end();
		}
	};

	public authenticate = async (
		onSuccess: () => void = () => {},
		onFail: () => void = () => {},
	) => {
		if (!(await this.isLogged())) {
			onFail();
		} else {
			onSuccess();
		}
	};

	public setToken = async (token: string) => {
		runInAction(() => (this.receivedToken = token));
		await API.setPushToken(token);
	};

	public clearErrors = () => {
		runInAction(() => {
			this.fieldError.email = "";
			this.fieldError.token = "";
			this.fieldError.password = "";
			this.fieldError.confirmNewPassword = "";
		});
	};

	private clear = () => {
		const data = this.formController.getValues();
		runInAction(() => {
			data.email = "";
			data.password = "";
			this.receivedToken = "";
			data.token = "";
		});
	};
}
