import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { IconButton, TableCell, TableRow } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { useLocalStore, observer } from "mobx-react-lite";

import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";
import Strings from "../../../../modules/Strings";
import { useGlobalStore } from "../../../context";
import TableStore from "./store";
import moment from "moment";
import API from "../../../../modules/API";

const TableView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(() => new TableStore(uiStore, history));

	const pushToDetails = useCallback(
		(id: string) => history.push(`/dashboard/cargoOrder/details/${id}`),
		[history],
	);

	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore._loading}
				title={Strings.cargoOrder.table.title}
				pagination={tableStore.getPagination()}
				isHaveNexPage={tableStore._isHaveNextPage}
				rows={tableStore._items}
				headers={[
					Strings.fields.id,
					Strings.components.table.startDate,
					Strings.components.table.endDate,
					Strings.fields.email,
					Strings.components.table.cargoType,
					Strings.components.table.showDetails,
				]}
				renderItem={(item) => (
					<TableRow hover key={item.id}>
						<TableCell align="center">{item.id}</TableCell>
						<TableCell align="center">{moment(item.startDate).format("DD/MM/YYYY")}</TableCell>
						<TableCell align="center">{moment(item.endDate).format("DD/MM/YYYY")}</TableCell>
						<TableCell align="center">{item.owner.email}</TableCell>
						<TableCell align="center">{API.translateAnimalType(item.animalType)}</TableCell>
						<TableCell align="center">
							<IconButton onClick={() => pushToDetails(item.id)}>
								<Visibility />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
