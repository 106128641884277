import React from "react";

import { Typography } from "@material-ui/core";
import { DetailRow } from "../../../../../../components/DetailRow";
import { Formatting } from "../../../../../../modules/Formatting";

import Strings from "../../../../../../modules/Strings";
import API from "../../../../../../modules/API";
import useStyles from "./styles";
import moment from "moment";

interface ITransportProps {
	transport: API.TransportOrder;
}

const TransportOrder: React.FC<ITransportProps> = (props) => {
	const ComponentStrings = Strings.components.transportOrder;
	const classes = useStyles();
	const { transport } = props;
	return (
		<>
			<Typography variant="h6" className={classes.title}>
				{ComponentStrings.title}
			</Typography>
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.owner}
				value={transport.owner.name}
			/>
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.accepter}
				value={transport.accepter ? transport.accepter.name : ""}
			/>
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.originAddress}
				value={transport.origin.address}
			/>
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.destinyAddress}
				value={transport.destiny.address}
			/>
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.weight}
				value={transport.weight}
			/>
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.departureDate}
				value={moment(transport.startDate).format("DD/MM/YYYY")}
			/>
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.createDate}
				value={moment(transport.createdAt).format("DD/MM/YYYY")}
			/>
			<DetailRow
				mainCardStyle={classes.detailsRowCard}
				label={ComponentStrings.price}
				value={Formatting.formatCurrencyBR(transport.price)}
			/>
		</>
	);
};
export default TransportOrder;
