import { makeAutoObservable, runInAction } from "mobx";
import LoaderShelf from "../../../../../../shelves/LoaderShelf";

import API from "../../../../../../modules/API";
import { Errors } from "../../../../../../modules/Errors";

export default class Store {

	public shipping: API.Shipping | null = null;

	public error: string | null = null;

	public loader: LoaderShelf = new LoaderShelf();

	constructor(id: string) {
		makeAutoObservable(this);
		this.getShipping(id);
	}

	private getShipping = async (id: string) => {
		try {
			this.loader.start();
			const resultModel = await API.getShipping(id);
			runInAction(() => (this.shipping = resultModel));
		} catch (e) {
			runInAction(() => this.error = Errors.handleError(e));
		} finally {
			this.loader.end();
		}
	};
}
