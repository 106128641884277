import { makeAutoObservable } from "mobx";

import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";
import Strings from "../../../../modules/Strings";
import LoaderShelf from "../../../../shelves/LoaderShelf";
import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import FileStore from "../../../../stores/FileStore";
import FormStore from "../../../../stores/FormStore";
import UIStore from "../../../../stores/UIStore";

interface IReplacer {
	replace: (path: string) => void;
}

export default class Store {
	private uiStore: UIStore;
	private router: IReplacer;
	public loader = new LoaderShelf();
	public avatar = new FileStore("image");
	public formController = new FormStore({
		email: "",
		name: "",
		type: API.UserType.contractor,
		password: "",
		phone: "",
	});
	public fieldError: FieldErrorShelf<API.UserDetails>;

	constructor(uiStore: UIStore, router: IReplacer) {
		this.uiStore = uiStore;
		this.router = router;
		this.fieldError = new FieldErrorShelf();
		makeAutoObservable(this);
	}

	public submit = async () => {
		try {
			this.loader.start();

			const {
				email,
				name,
				type,
				password,
				phone,
			} = this.formController.getValues();

			await API.createUser({
				email,
				name,
				type,
				password,
				phone,
				cpf: null,
				image: this.avatar.getImage(),
				address: null,
			});
			this.onSuccess();
		} catch (e) {
			this.handleSubmitError(e);
		} finally {
			this.loader.end();
		}
	};

	private handleSubmitError(e: any) {
		this.uiStore.showErrorSnackbar(Errors.handleError(e));
	}

	private onSuccess() {
		this.uiStore.showSnackbar(Strings.register.successUser, "success");
		this.router.replace("/dashboard/users");
	}
}
