import React from "react";
import "./style.scss";

import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";

import { PageContainer } from "../../../components/PageContainer";
import Strings from "../../../modules/Strings";

const Home = observer(() => {
	return (
		<PageContainer>
			<Box
				className="welcomeContainer"
				display="flex"
				flexDirection="column"
			>
				{Strings.home.welcome}
			</Box>
		</PageContainer>
	);
});

export default Home;
