import { createStyles, Theme, makeStyles } from "@material-ui/core";

const navbarHeigth = 80;
const sidebarOpenWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainLayout: {
			width: "calc(100% - 57px)",
			height: "100%",
			minHeight: "100vh",
			paddingTop: `${navbarHeigth}px`,
			zIndex: 1201,
			marginLeft: "57px",
			backgroundColor: theme.palette.primary.light,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			[theme.breakpoints.up("sm")]: {
				overflowX: "hidden",
			},
		},
		mainLayoutContracted: {
			width: "calc(100% - 240px)",
			height: "100%",
			minHeight: "100vh",
			paddingTop: `${navbarHeigth}px`,
			marginLeft: `${sidebarOpenWidth}px`,
			backgroundColor: theme.palette.primary.light,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			[theme.breakpoints.up("sm")]: {
				overflowX: "hidden",
			},
		},
		mainLayoutMobile: {
			backgroundColor: theme.palette.primary.light,
			paddingTop: `${navbarHeigth}px`,
			minHeight: "100vh",
			height: "100%",
		},
	}),
);
