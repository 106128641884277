import API from "./API";

interface IError {
	type: API.ErrorType;
	message: string;
}

export class CustomError extends Error {
	public error: IError;
	constructor(type: API.ErrorType, message: string) {
		super(message);
		this.error = { type, message };

		this.handleError();
	}
	public handleError = () => {
		throw this.error;
	};
}
