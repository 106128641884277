import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(1),
		},

		fields: {
			"& .MuiTextField-root": {
				margin: theme.spacing(1),
				maxWidth: 200,
				width: "100%",
			},
		},
		fieldSelect: {
			margin: theme.spacing(1),
			maxWidth: 180,
			width: "100%",
			[theme.breakpoints.down("xs")]: {
				maxWidth: 300,
				margin: theme.spacing(1),
			},
		},
	}),
);

export default useStyles;
