import React from "react";
import { useHistory } from "react-router";

import useStyles from "./styles";
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import Store from "./store";
import FileCase from "../../../../components/FileCase";

import {
	Button,
	CardContent,
	CircularProgress,
	TextField,
	FormControl,
	InputLabel,
} from "@material-ui/core";

import { observer, useLocalStore } from "mobx-react-lite";
import CardForm from "../../../../components/CardForm";
import { SelectEnum } from "../../../../components/SelectEnum";
import { useGlobalStore } from "../../../context";

const CreateView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const classes = useStyles();
	const history = useHistory();
	const store = useLocalStore(() => new Store(uiStore, history));

	return (
		<CardForm
			title={Strings.users.create.title}
			actionsFooter={
				<Button
					disabled={store.loader.isLoading}
					type="submit"
					variant="contained"
					color="default"
					onClick={store.submit}
				>
					{store.loader.isLoading ? (
						<CircularProgress />
					) : (
						Strings.components.table.create
					)}
				</Button>
			}
		>
			<CardContent className={classes.fields}>
				<TextField
					disabled={store.loader.isLoading}
					variant="standard"
					type="text"
					label={Strings.fields.name}
					{...store.formController.field("name")}
				/>
				{
					/* Hide cpf
					<TextField
						disabled={store.loader.isLoading}
						variant="standard"
						type="text"
						label={Strings.fields.cpf}
						{...store.formController.field("cpf")}
					/>
					*/
				}
				<TextField
					disabled={store.loader.isLoading}
					variant="standard"
					type="text"
					label={Strings.fields.phone}
					{...store.formController.field("phone")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					variant="standard"
					type="email"
					label={Strings.fields.email}
					{...store.formController.field("email")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					variant="standard"
					type="password"
					label={Strings.fields.password}
					{...store.formController.field("password")}
				/>
				<FormControl className={classes.fieldSelect}>
					<InputLabel>
						{Strings.fields.type}
					</InputLabel>
					<SelectEnum
						name={"type"}
						fields={store.formController.field}
						allValuesEnum={API.allValuesUserType}
						translateEnum={API.translateUserType}
						error={store.fieldError.hasFieldError("type")}
					/>
				</FormControl>
				<FileCase
					disableActions={store.loader.isLoading}
					title={Strings.fields.image}
					{...store.avatar.toCase()}
				/>
			</CardContent>
		</CardForm>
	);
};

export default observer(CreateView);
