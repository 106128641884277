import {
	action,
	computed,
	makeObservable,
	observable,
	runInAction,
} from "mobx";

import { IRouterPusher } from "../../../../../../interfaces/IRouter";
import api from "../../../../../../modules/API";
import AuthStore from "../../../../../../stores/AuthStore";
import PaginatedListStore from "../../../../../../stores/PaginatedListStore";
import UIStore from "../../../../../../stores/UIStore";
import { CustomError } from "../../../../../../modules/CustomError";
import Strings from "../../../../../../modules/Strings";
import FilterShelf from "./shelves/FilterShelf";

export default class Store extends PaginatedListStore<api.Shipping> {
	private readonly driverId: string;

	private authStore: AuthStore;

	private uiStore: UIStore;

	public shippingStatisticsNotCanceled: api.NotCanceledShippingInfo | null =
	null;

	public loading = false;

	public filterShelf = new FilterShelf();

	constructor(uiStore: UIStore, router: IRouterPusher, driverId: string) {
		super(uiStore, router);
		this.driverId = driverId;
		this.fetchPage(0);
		this.authStore = new AuthStore(uiStore);
		this.uiStore = uiStore;
		makeObservable(this, {
			loading: observable,
			shippingStatisticsNotCanceled: observable,
			getShippingNotCanceled: action,
			filterOptions: computed,
		});
		this.getShippingNotCanceled();
	}

	public get filterOptions(): api.ShippingFilter {
		const filter = this.filterShelf;
		const initialPeriod = filter.initialPeriod;
		const finalPeriod = filter.finalPeriod;
		return {
			driverId: this.driverId,
			status: null,
			startPeriodDate: initialPeriod,
			endPeriodDate: finalPeriod,
			city: null,
		};
	}

	protected getDataItemsPerPage(page: number): Promise<api.Shipping[]> {
		return api.getAllShipping(page, this.filterOptions);
	}

	public getShippingNotCanceled = async () => {
		if (this.loading) {
			throw new CustomError(
				api.ErrorType.Fatal,
				Strings.error.mustWaitWhileStillLoading,
			);
		}

		runInAction(() => (this.loading = true));

		try {
			const newItems = await api.getNotCanceledShippingsInfo(
				this.filterOptions,
			);
			runInAction(() => (this.shippingStatisticsNotCanceled = newItems));
		} catch (e) {
			throw e;
		} finally {
			runInAction(() => (this.loading = false));
		}
	};

	public refreshStatistics = () => {
		this.getShippingNotCanceled();
	};
}
