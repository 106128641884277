import { makeAutoObservable, runInAction } from "mobx";

import api from "../../../../../modules/API";
import DatePickerShelf from "../../../../../shelves/DatePickerShelf";

interface FormModel {
	city: string;
}
export default class FilterStore {
	public selectedStatus: api.ShippingStatusOptions | undefined = undefined;

	public formController: FormModel = {
		city: "",
	};

	public initPeriodStartDate = new DatePickerShelf();

	public initPeriodEndDate = new DatePickerShelf();

	public finalPeriodStartDate = new DatePickerShelf();

	public finalPeriodEndDate = new DatePickerShelf();

	constructor() {
		makeAutoObservable(this);
	}

	public setSelectedStatus(status: api.ShippingStatusOptions | undefined) {
		this.selectedStatus = status;
	}

	public handleForm = (field: keyof FormModel) => {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			runInAction(() => {
				this.formController = {
					...this.formController,
					[field]: e.target.value,
				};
			});
		};
	};

	public get initialPeriod(): api.PeriodDate | null {
		const startDate = this.initPeriodStartDate.date;
		const endDate = this.initPeriodEndDate.date;
		if (!startDate || !endDate) {
			return null;
		}
		return {
			startDate,
			endDate,
		};
	}

	public get finalPeriod(): api.PeriodDate | null {
		const startDate = this.finalPeriodStartDate.date;
		const endDate = this.finalPeriodEndDate.date;
		if (!startDate || !endDate) {
			return null;
		}
		return {
			startDate,
			endDate,
		};
	}

	public clear() {
		this.selectedStatus = undefined;
		this.initPeriodStartDate.clear();
		this.initPeriodEndDate.clear();
		this.finalPeriodStartDate.clear();
		this.finalPeriodEndDate.clear();
		this.formController = {
			city: "",
		};
	}
}
