import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { observer, useLocalStore } from "mobx-react-lite";

import CardForm from "../../../../components/CardForm";
import { DetailRow } from "../../../../components/DetailRow";
import LinearProgress from "../../../../components/LinearProgress";
import Strings from "../../../../modules/Strings";
import { useGlobalStore } from "../../../context";
import Store from "./store";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	return (
		<CardForm title={Strings.admin.details.title}>
			{!store.adminUser ? (
				<LinearProgress />
			) : (
				<>
					<DetailRow
						label={Strings.fields.name}
						value={store.adminUser.name}
					/>
					<DetailRow
						label={Strings.fields.email}
						value={store.adminUser.email}
					/>
				</>
			)}
		</CardForm>
	);
};
export default observer(DetailsView);
