import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { observer, useLocalStore } from "mobx-react-lite";

import DetailsForm from "../../../../components/DetailsForm";
import LinearProgress from "../../../../components/LinearProgress";
import Strings from "../../../../modules/Strings";
import { useGlobalStore } from "../../../context";
import { DetailRow } from "../../../../components/DetailRow";
import { CargoOrder, TransportOrder, Shipping } from "./components";
import Store from "./store";
import useStyles from "./styles";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));
	const classes = useStyles();

	if (store.loader.isLoading) {
		return <LinearProgress />;
	}
	return (
		<DetailsForm
			title={Strings.insured.details.title}
			modelContent={!!store.model}
			loading={store.loader.isLoading}
			contentStyle={classes.detailsFormContent}
		>
			{store.model && (
				<>
					<DetailRow
						label={Strings.fields.ownerID}
						value={store.model.owner.id}
					/>
					<DetailRow
						label={Strings.fields.ownerName}
						value={store.model.owner.name}
					/>
					<DetailRow
						label={Strings.fields.ownerPhone}
						value={store.model.owner.phone}
					/>
					<DetailRow
						label={Strings.fields.ownerEmail}
						value={store.model.owner.email}
					/>
					{store.model.transportOrderId && !store.model.shippingId && (
						<TransportOrder transportId={store.model.transportOrderId} />
					)}
					{store.model.cargoOrderId && !store.model.shippingId && (
						<CargoOrder cargoId={store.model.cargoOrderId} />
					)}
					{store.model.shippingId && (
						<Shipping shippingId={store.model.shippingId} />
					)}
				</>
			)}
		</DetailsForm>
	);
};
export default observer(DetailsView);
