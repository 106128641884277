import React from "react";

import { Theme } from "@material-ui/core";

import themes from "../themes";
import usePersistState from "./usePersistState";

interface IReturnUseProviderTheme {
	currentTheme: Theme;
	isDark: boolean;
	toggleThemeByTitle: (themeTitle?: string) => void;
	toggleTheme: () => void;
}

export type ThemeStore = IReturnUseProviderTheme;

const useProviderTheme = (): ThemeStore => {
	const [themeTitle, setThemeTitle] = usePersistState<string>({
		InitialState: themes.defaultTheme.title,
		key: "@startapp-theme",
	});
	const [isDark, setIsDark] = React.useState(themeTitle === "darkTheme");

	React.useDebugValue(themes.darkTheme.palette.primary.main);

	const toggleThemeByTitle = React.useCallback(
		(currentThemeTitle = "defaultTheme") => {
			if (!themes[currentThemeTitle]) {
				throw new Error("This theme not exists");
			}

			setThemeTitle(currentThemeTitle);
		},
		[themes, setThemeTitle],
	);

	const toggleTheme = React.useCallback(() => {
		setThemeTitle(
			themeTitle === "darkTheme"
				? themes.defaultTheme.title
				: themes.darkTheme.title,
		);
		setIsDark(themeTitle === "darkTheme");
	}, [themes, themeTitle, setIsDark, setThemeTitle]);

	return {
		currentTheme: themes[themeTitle],
		toggleThemeByTitle,
		toggleTheme,
		isDark,
	};
};

export default useProviderTheme;
