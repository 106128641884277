import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		contractorDataContainer: {
			marginTop: theme.spacing(4),
		},

		contractorTitle: {
			marginBottom: theme.spacing(2),
		},
	}),
);

export default useStyles;
