import React from "react";

import { observer } from "mobx-react-lite";
import { Button, Paper, Box, SwipeableDrawer } from "@material-ui/core";
import Strings from "../../modules/Strings";
import { useIsMobile } from "../../hooks/useIsMobile";

import { useStyles } from "./style";

interface IProps {
	state: boolean;
	onClose: () => void;
	buttonStyle?: string;
	containerStyle?: string;
	drawerStyle?: string;
}

const FilterDrawer: React.FC<IProps> = (props) => {
	const classes = useStyles();
	const isMobile = useIsMobile();
	const componentStrings = Strings.components.filderDrawer;
	const { state, onClose, buttonStyle, containerStyle, drawerStyle } = props;

	if (isMobile) {
		return (
			<SwipeableDrawer
				anchor="left"
				open={state}
				onClose={onClose}
				onOpen={() => {}}
			>
				<Box className={containerStyle}>
					{props.children}

					{onClose && (
						<Button
							onClick={onClose}
							variant="outlined"
							color="primary"
							className={buttonStyle}
						>
							{componentStrings.close}
						</Button>
					)}
				</Box>
			</SwipeableDrawer>
		);
	}
	return (
		<Paper
			className={
				state
					? [classes.drawerOpen, drawerStyle].join(" ")
					: [classes.drawerClosed, drawerStyle].join(" ")
			}
		>
			<Box className={containerStyle}>
				{props.children}

				{onClose && (
					<Button
						onClick={onClose}
						variant="outlined"
						color="primary"
						className={buttonStyle}
					>
						{componentStrings.close}
					</Button>
				)}
			</Box>
		</Paper>
	);
};

export default observer(FilterDrawer);
