import React from "react";

import { observer, useLocalStore } from "mobx-react-lite";
import TransportOrderDetails from "../../../../../../components/TransportOrderDetails";
import { Box, CircularProgress } from "@material-ui/core";

import Store from "./store";

interface ITransportProps {
	transportId: string;
}

const TransportOrder: React.FC<ITransportProps> = (props) => {
	const { transportId } = props;
	const store = useLocalStore(() => new Store(transportId));
	return (
		<>
			{store.transport ? (
				<TransportOrderDetails transport={store.transport} />
			) : (
				<Box
					style={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						width: "100%",
					}}
				>
					<CircularProgress />
				</Box>
			)}
		</>
	);
};
export default observer(TransportOrder);
