import {
	action,
	computed,
	makeObservable,
	observable,
	runInAction,
} from "mobx";

import { IRouterPusher } from "../../../../interfaces/IRouter";
import api from "../../../../modules/API";
import AuthStore from "../../../../stores/AuthStore";
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import Strings from "../../../../modules/Strings";
import UIStore from "../../../../stores/UIStore";
import { CustomError } from "../../../../modules/CustomError";
import FilterShelf from "./FilterShelf";

export default class ShippingTableStore extends PaginatedListStore<api.Shipping> {
	private authStore: AuthStore;

	private uiStore: UIStore;

	public shippingStatics: api.ShippingStatistics | null = null;

	public loading = false;

	public filterShelf = new FilterShelf();

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(uiStore, router);
		this.fetchPage(0);
		this.authStore = new AuthStore(uiStore);
		this.uiStore = uiStore;
		makeObservable(this, {
			shippingStatics: observable,
			loading: observable,
			getShippingStatics: action,
			filterOptions: computed,
		});
		this.getShippingStatics();
	}

	protected getDataItemsPerPage(page: number): Promise<api.Shipping[]> {
		return api.getAllShipping(page, this.filterOptions);
	}

	public get filterOptions(): api.ShippingFilter {
		const filter = this.filterShelf;
		const formValues = filter.formController;
		const initialPeriod = filter.initialPeriod;
		const finalPeriod = filter.finalPeriod;
		return {
			driverId: null,
			status: filter.selectedStatus || null,
			startPeriodDate: initialPeriod,
			endPeriodDate: finalPeriod,
			city: formValues.city.length === 0 ? null : formValues.city.trim(),
		};
	}

	public getShippingStatics = async () => {
		if (this.loading) {
			throw new CustomError(
				api.ErrorType.Fatal,
				Strings.error.mustWaitWhileStillLoading,
			);
		}

		runInAction(() => (this.loading = true));

		try {
			const newItems = await api.getAllShippingStatistics(this.filterOptions);
			runInAction(() => (this.shippingStatics = newItems));
		} catch (e) {
			throw e;
		} finally {
			runInAction(() => (this.loading = false));
		}
	};

	public refreshStatistics = () => {
		this.getShippingStatics();
	};
}
