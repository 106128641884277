import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import {
	Box,
	IconButton,
	TableCell,
	TableRow,
} from "@material-ui/core";


import { Visibility, FilterList } from "@material-ui/icons";
import { useLocalStore, observer } from "mobx-react-lite";

import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";
import MiniCard from "../../../../components/MiniCard";
import Strings from "../../../../modules/Strings";
import { useGlobalStore } from "../../../context";
import ShippingTableStore from "./store";
import useStyles from "./styles";
import moment from "moment";
import API from "../../../../modules/API";
import Filter from "./components/Filter";

const TableView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const MiniCardStrings = Strings.components.ShowStatics;
	const ScreenStrings = Strings.shipping.table;
	const classes = useStyles();
	const history = useHistory();
	const tableStore = useLocalStore(
		() => new ShippingTableStore(uiStore, history),
	);
	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	const pushToDetails = useCallback(
		(id: string) => history.push(`shipping/details/${id}`),
		[history],
	);

	const search = () => {
		setIsOpen(false);
		tableStore.fetchPage(0);
	};

	const clear = () => {
		tableStore.filterShelf.clear();
		setIsOpen(false);
		tableStore.fetchPage(0);
	};

	return (
		<Box style={{ position: "relative" }}>
			<Filter
				state={isOpen}
				setState={setIsOpen}
				search={search}
				clear={clear}
				filterShelf={tableStore.filterShelf}
				loading={tableStore._loading}
			/>
			<PageTableContainer>
				{tableStore.shippingStatics && (
					<Box className={classes.statisticsBox}>
						<MiniCard
							title={MiniCardStrings.freightInProgress}
							value={tableStore.shippingStatics.currentShippingCount}
						/>
						<MiniCard
							classNameStyle={classes.miniCard}
							title={MiniCardStrings.finishedFreight}
							value={tableStore.shippingStatics.endShippingCount}
						/>
					</Box>
				)}
			</PageTableContainer>
			<PageTableContainer
				classStyles={
					isOpen
						? classes.pageTableContainerAndFilter
						: classes.pageTableContainerAndFilterClosed
				}
			>
				<SimpleTable
					loading={tableStore._loading}
					title={ScreenStrings.title}
					pagination={tableStore.getPagination()}
					isHaveNexPage={tableStore._isHaveNextPage}
					headerToolbar={
						<IconButton
							onClick={() => setIsOpen(!isOpen)}
							color="primary"
							className={isOpen ? classes.filterIconOpen : undefined}
						>
							<FilterList />
						</IconButton>
					}
					rows={tableStore._items}
					headers={[
						Strings.fields.id,
						Strings.fields.contractor,
						Strings.fields.driver,
						Strings.fields.departureDate,
						Strings.fields.status,
						Strings.components.table.showDetails,
					]}
					renderItem={(item) => {
						const driver =
							item.owner.type === API.UserType.driver
								? item.owner
								: item.accepter;
						const contractor =
							item.owner.type === API.UserType.contractor
								? item.owner
								: item.accepter;
						return (
							<TableRow hover key={item.id}>
								<TableCell align="center">{item.id}</TableCell>
								<TableCell align="center">{contractor.name}</TableCell>
								<TableCell align="center">{driver.name}</TableCell>
								<TableCell align="center">
									{moment(item.createdAt).format("DD/MM/YYYY")}
								</TableCell>
								<TableCell align="center">
									{API.translateShippingStatusOptions(item.status.statusName)}
								</TableCell>
								<TableCell align="center">
									<IconButton onClick={() => pushToDetails(item.id)}>
										<Visibility />
									</IconButton>
								</TableCell>
							</TableRow>
						);
					}}
				/>
			</PageTableContainer>
		</Box>
	);
};

export default observer(TableView);
