/* eslint-disable no-throw-literal */
import { inspect } from "util";

import API from "./API";
import Strings from "./Strings";

export const Errors = {
	handleError: (e: any): string => {
		if (process.env.NODE_ENV !== "production") {
			// tslint:disable-next-line:no-console
			console.error(inspect(e));
		}
		const apiError: { type?: API.ErrorType; message?: string } = e;

		if (
			apiError.message &&
			apiError.type &&
			apiError.type !== API.ErrorType.Connection &&
			apiError.type !== API.ErrorType.Fatal
		) {
			return apiError.message;
		}

		return process.env.NODE_ENV === "production"
			? Strings.error.default
			: inspect(e);
	},

	create: {
		stillLoading: () => {
			throw {
				type: API.ErrorType.Fatal,
				message: Strings.error.stillLoading,
			};
		},
	},
};
