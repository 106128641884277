import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import {
	IconButton,
	TableCell,
	TableRow,
	Box,
} from "@material-ui/core";
import { Visibility, FilterList } from "@material-ui/icons";
import { useLocalStore, observer } from "mobx-react-lite";


import PageTableContainer from "../../../../../../components/PageTableContainer";
import SimpleTable from "../../../../../../components/SimpleTable";
import MiniCard from "../../../../../../components/MiniCard";
import { useGlobalStore } from "../../../../../context";
import Strings from "../../../../../../modules/Strings";
import { Formatting } from "../../../../../../modules/Formatting";
import api from "../../../../../../modules/API";
import Filter from "./components/Filter";
import Store from "./store";
import useStyles from "./styles";
import moment from "moment";

interface IProps {
	driverId: string;
}

const MyShippingTable: React.FC<IProps> = (props) => {
	const { driverId } = props;
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(() => new Store(uiStore, history, driverId));
	const classes = useStyles();
	const ScreenStrings = Strings.users.details;

	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	const pushToDetails = useCallback(
		(id: string) => history.push(`/dashboard/shipping/details/${id}`),
		[history],
	);

	const clear = () => {
		tableStore.filterShelf.clear();
		setIsOpen(false);
		tableStore.fetchPage(0);
	};

	const search = () => {
		setIsOpen(false);
		tableStore.fetchPage(0);
	};

	return (
		<Box style={{ position: "relative" }}>
			{tableStore.shippingStatisticsNotCanceled && (
				<Box className={classes.statisticsBox}>
					<MiniCard
						classNameStyle={classes.statisticCard}
						title={ScreenStrings.miniCard.amount}
						value={tableStore.shippingStatisticsNotCanceled.shippingsCount}
					/>
					<MiniCard
						classNameStyle={classes.statisticCard}
						title={ScreenStrings.miniCard.earnedValue}
						value={Formatting.formatCurrencyBR(
							tableStore.shippingStatisticsNotCanceled.totalPrice,
						)}
					/>
				</Box>
			)}
			<PageTableContainer
				classStyles={
					isOpen
						? classes.pageTableContainerAndFilter
						: classes.pageTableContainerAndFilterClosed
				}
			>
				<SimpleTable
					loading={tableStore._loading}
					title={Strings.users.details.myShippings}
					pagination={tableStore.getPagination()}
					isHaveNexPage={tableStore._isHaveNextPage}
					headerToolbar={
						<IconButton
							onClick={() => setIsOpen(!isOpen)}
							color="primary"
							className={isOpen ? classes.filterIconOpen : undefined}
						>
							<FilterList />
						</IconButton>
					}
					rows={tableStore._items}
					headers={[
						Strings.fields.id,
						Strings.fields.contractor,
						Strings.fields.driver,
						Strings.fields.departureDate,
						Strings.fields.status,
						Strings.components.table.showDetails,
					]}
					renderItem={(item) => {
						const driver =
							item.owner.type === api.UserType.driver
								? item.owner
								: item.accepter;
						const contractor =
							item.owner.type === api.UserType.contractor
								? item.owner
								: item.accepter;
						return (
							<TableRow hover key={item.id}>
								<TableCell align="center">{item.id}</TableCell>
								<TableCell align="center">{contractor.name}</TableCell>
								<TableCell align="center">{driver.name}</TableCell>
								<TableCell align="center">
									{moment(item.createdAt).format("DD/MM/YYYY")}
								</TableCell>
								<TableCell align="center">
									{api.translateShippingStatusOptions(item.status.statusName)}
								</TableCell>
								<TableCell align="center">
									<IconButton onClick={() => pushToDetails(item.id)}>
										<Visibility />
									</IconButton>
								</TableCell>
							</TableRow>
						);
					}}
				/>
			</PageTableContainer>

			<Filter
				clear={clear}
				filterShelf={tableStore.filterShelf}
				search={search}
				loading={tableStore._loading}
				setState={setIsOpen}
				state={isOpen}
			/>
		</Box>
	);
};

export default observer(MyShippingTable);
