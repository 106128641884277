import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	detailsFormContent: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "space-between",
	},
	detailsRowCard: {
		width: "45%",
	},
}));

export default useStyles;
