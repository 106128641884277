import React from "react";
import { useHistory } from "react-router";

import {
	AppBar,
	Card,
	CardContent,
	CardActions,
	Typography,
	Paper,
	Link,
} from "@material-ui/core";
import { HelpOutline as HelpOutlineIcon } from "@material-ui/icons";
import { observer } from "mobx-react-lite";

import Button from "../../components/Button";
import CircularProgress from "../../components/CircularProgress";
import TextField from "../../components/TextField";
import { useAuthRoute } from "../../hooks/useAuthRoute";
import { useIsMobile } from "../../hooks/useIsMobile";
import { BaseLayout } from "../../layouts/BaseLayout";
import Strings from "../../modules/Strings";
import { useGlobalStore } from "../context";
import useStyles from "./styles";

export const Login = observer(() => {
	const isMobile = useIsMobile();
	const classes = useStyles();
	const { authStore } = useGlobalStore();
	const history = useHistory();
	const login = Strings.pageLogin;
	useAuthRoute(() => history.push("/dashboard"));

	return (
		<>
			<AppBar position="static" className={classes.appBar}>
				<Typography className={classes.pageTitle} variant="h6">
					{login.pagetitle}
				</Typography>
			</AppBar>
			{authStore.loader.isLoading ? (
				<CircularProgress />
			) : (
				<Paper className={classes.pageContent} elevation={0}>
					<Card className={classes.card}>
						<CardContent>
							<Typography
								className={classes.loginTitle}
								variant="h5"
							>
								{login.title}
							</Typography>
							<TextField
								inputProps={{ className: classes.input }}
								InputLabelProps={{ className: classes.label }}
								{...authStore.formController.field("email")}
								label={Strings.fields.email}
								disabled={authStore.loader.isLoading}
								type="email"
								name="email"
							/>
							<TextField
								inputProps={{ className: classes.input }}
								InputLabelProps={{ className: classes.label }}
								{...authStore.formController.field("password")}
								label={Strings.fields.password}
								disabled={authStore.loader.isLoading}
								type="password"
								name="password"
							/>
						</CardContent>
						<CardActions>
							<Button
								variant="outlined"
								className={classes.loginButton}
								onClick={() =>
									authStore.login(() =>
										history.push("/dashboard"),
									)
								}
							>
								{login.buttonLogin}
							</Button>
						</CardActions>
					</Card>
					<Link
						className={classes.acessLink}
						onClick={() => history.push("./recovery/token")}
					>
						{login.recoveryPassword}
					</Link>
					{isMobile ? (
						<>
							<Typography className={classes.mobileFooter}>
								{login.messageFooter}
							</Typography>
							<HelpOutlineIcon
								className={classes.mobileHelpIcon}
							/>
						</>
					) : (
						<>
							<Typography className={classes.desktopLeftFooter}>
								{login.messageFooter}
							</Typography>
							<Typography className={classes.desktopRightFooter}>
								{login.needHelp}
							</Typography>
							<HelpOutlineIcon
								className={classes.desktopHelpIcon}
							/>
						</>
					)}
				</Paper>
			)}
			<BaseLayout />
		</>
	);
});
