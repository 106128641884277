export const Formatting = {
	formatCurrencyBR: (centsValue: number) => {
		return (centsValue / 100).toLocaleString("pt-BR", {
			currency: "BRL",
			style: "currency",
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		});
	},
};
