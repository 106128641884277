import React from "react";

interface IParams<IT> {
	key: string;
	InitialState: IT;
}

type IResponse<T> = [T, React.Dispatch<React.SetStateAction<T>>];

const usePersistState = <T extends unknown>({
	InitialState,
	key,
}: IParams<T>): IResponse<T> => {
	const [state, setState] = React.useState(() => {
		const storageValue = localStorage.getItem(key);

		if (storageValue) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return JSON.parse(storageValue);
		}

		return InitialState;
	});

	React.useEffect(() => {
		localStorage.setItem(key, JSON.stringify(state));
	});

	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return [state, setState];
};

export default usePersistState;
