import React from "react";
import "./style.scss";

// MARK: Pages
import { Route, Switch, BrowserRouter } from "react-router-dom";

import { CssBaseline } from "@material-ui/core";

import { BaseLayout } from "../layouts/BaseLayout";
import { Auth } from "./Auth";
import { GlobalContextProvider } from "./context";
import { Dashboard } from "./Dashboard";
import { Login } from "./Login";
import { Recovery } from "./Recovery";

// MARK: Routing

// MARK: GlobalState

// MARK: Components

export const App = () => {
	return (
		<GlobalContextProvider>
			<CssBaseline />
			<BaseLayout />
			<BrowserRouter>
				<Switch>
					<Route path="/dashboard">
						<Dashboard />
					</Route>
					<Route exact path="/">
						<Auth />
					</Route>
					<Route path="/login">
						<Login />
					</Route>
					<Route path="/recovery/token/:token">
						<Recovery />
					</Route>
					<Route path="/recovery/token">
						<Recovery />
					</Route>
				</Switch>
			</BrowserRouter>
		</GlobalContextProvider>
	);
};
