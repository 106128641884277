import React from "react";
import "./style.scss";

// MARK: Resources

// MARK: Components
import {
	DialogActions as MDialogActions,
	Dialog as MDialog,
	DialogContent as MDialogContent,
	DialogContentText as MDialogContentText,
	DialogTitle as MDialogTitle,
	Button as MButton,
} from "@material-ui/core";

import Strings from "../../modules/Strings";

interface IProps {
	onAlertConfirm?: (() => void) | null;
	clearAlert: () => void;
	alertMessage?: string | null;
	alertTitle?: string | null;
}

export default class Alert extends React.Component<IProps> {
	private onConfirmClick = () => {
		const { onAlertConfirm, clearAlert } = this.props;

		if (onAlertConfirm) {
			onAlertConfirm();
		}

		clearAlert();
	};

	public render() {
		const { clearAlert, alertMessage, alertTitle } = this.props;

		return (
			<MDialog
				open={
					(!!alertMessage && alertMessage !== "") ||
					(!!alertTitle && alertTitle !== "")
				}
				onClose={clearAlert}
			>
				{alertTitle && <MDialogTitle>{alertTitle}</MDialogTitle>}
				{alertMessage && (
					<MDialogContent>
						<MDialogContentText>{alertMessage}</MDialogContentText>
					</MDialogContent>
				)}
				<MDialogActions>
					<MButton
						onClick={() => this.onConfirmClick()}
						variant="contained"
						autoFocus
					>
						{Strings.ok}
					</MButton>
				</MDialogActions>
			</MDialog>
		);
	}
}
