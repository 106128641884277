import { makeAutoObservable, runInAction } from "mobx";
import LoaderShelf from "../../../../../../shelves/LoaderShelf";

import API from "../../../../../../modules/API";
import { Errors } from "../../../../../../modules/Errors";

export default class Store {

	public cargo: API.CargoOrder | null = null;

	public error: string | null = null;

	public loader: LoaderShelf = new LoaderShelf();

	constructor(id: string) {
		makeAutoObservable(this);
		this.getCargoOrder(id);
	}

	private getCargoOrder = async (id: string) => {
		try {
			this.loader.start();
			const resultModel = await API.getCargoOrder(id);
			runInAction(() => (this.cargo = resultModel));
		} catch (e) {
			runInAction(() => (this.error = Errors.handleError(e)));
		} finally {
			this.loader.end();
		}
	};
}
