// Mobx
import { makeObservable, observable, runInAction } from "mobx";

// Tools
import * as Files from "./Files";
import FileUploader, { FileType, IImage } from "./FileUploader";

// Modules

// Stores

export default class FileStore {
	public progress = 0;
	public loading = false;
	public fileUploaders: FileUploader[] = [];
	public fileType: FileType;
	public error: string | null = null;

	constructor(fileType: FileType, files?: IImage) {
		this.fileType = fileType;
		if (files) {
			this.fileUploaders = [FileUploader.createUploaded(files, this.fileType)];
		} else {
			this.fileUploaders = [];
		}
		makeObservable(this, {
			fileUploaders: observable,
		});
	}

	public getImage = () => {
		return this.fileUploaders.length > 0
			? this.fileUploaders[0].getImage()
			: null;
	};

	public getUncertainfiedMultipleImage = () => {
		return this.fileUploaders.map((uploader) => uploader.getImage());
	};

	public retryUpload = (fileId: string) => {
		try {
			for (const uploader of this.fileUploaders) {
				if (uploader.id === fileId) {
					uploader.uploadImage();
				}
			}
		} catch (error) {
			this.error = error.message;
		}
	};

	public clear = (fileId: string) => {
		try {
			runInAction(
				() =>
					(this.fileUploaders = this.fileUploaders.filter(
						(uploader) => uploader.id !== fileId,
					)),
			);
		} catch (error) {
			this.error = error.message;
		}
	};

	public openSelectImageDialog = (multiple = false) => {
		if (!multiple) {
			Files.openInputUploadDialog(
				 (file) => {
					runInAction(() => (this.fileUploaders[0] = new FileUploader(file)));
					this.fileUploaders[0].uploadImage();
				},
				(error) => {
					throw new Error(error);
				},
				"image/*",
			);
		} else {
			Files.openMultipleInputDialog(
				async (imageFiles) => {
					runInAction(
						() =>
							(this.fileUploaders = this.fileUploaders.concat(
								imageFiles.map((imageFile) => {
									return new FileUploader(imageFile);
								}),
							)),
					);

					await Promise.all(
						this.fileUploaders.map((uploader) => {
							if (uploader.file) {
								return uploader.uploadImage();
							}
						}),
					);
				},
				(errMsg) => {
					throw new Error(errMsg);
				},
				"image/*",
			);
		}
	};

	public getUploader = () => {
		return this.fileUploaders;
	};

	public toCase = (multiple = false, isHighQuality = false) => {
		return {
			onAddItem: () => this.openSelectImageDialog(multiple),
			onRemoveItem: this.clear,
			onReloadItem: this.retryUpload,
			fileUploaders: this.getUploader(),
		};
	};
}
