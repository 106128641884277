import { IRouterPusher } from "../../../../interfaces/IRouter";

import API from "../../../../modules/API";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";

export default class Store extends ModelStore<API.Shipping> {
	private router: IRouterPusher;

	constructor(id: string, uiStore: UIStore, router: IRouterPusher) {
		super(id, uiStore);
		this.router = router;
	}

	protected async getModel(id: string): Promise<API.Shipping> {
		return await API.getShipping(id);
	}

	protected afterModelFetch(modal: API.Shipping): void {
	}

}
