/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import "./style.scss";

// MARK: Material
import {
	TextField as MTextField,
	Typography as MTypography,
} from "@material-ui/core";
import { TextFieldProps as MTextFieldProps } from "@material-ui/core/TextField";

export type TextFieldProps = MTextFieldProps & { errorMessage?: string | null };

export default class TextField extends React.Component<TextFieldProps, {}> {
	public render = () => {
		const { errorMessage, variant, style, ...restProps } = this.props;

		return (
			<div className="textFieldContainer">
				<MTextField
					{...restProps}
					margin="normal"
					style={{ width: "100%", ...style }}
					variant={variant || ("outlined" as any)}
				>
					{restProps.children}
				</MTextField>
				{errorMessage && (
					<MTypography className="errorTypography" variant="caption">
						{errorMessage}
					</MTypography>
				)}
			</div>
		);
	};
}
