import {
	Home as HomeIcon,
	SupervisorAccount as AdminIcon,
	Person as UserIcon,
	ListAlt as ListAltIcon,
	LocalShipping as LocalShippingIcon,
	ViewList as InputIcon,
} from "@material-ui/icons";
import Strings from "../modules/Strings";

export const useMainRoutes = () => [
	{
		Icon: HomeIcon,
		text: Strings.nav.home,
		path: "/dashboard",
	},
	{
		Icon: AdminIcon,
		text: Strings.nav.adminUsers,
		path: "/dashboard/adminUsers",
	},
	{
		Icon: UserIcon,
		text: Strings.nav.users,
		path: "/dashboard/users",
	},
	{
		Icon: ListAltIcon,
		text: Strings.nav.insured,
		path: "/dashboard/insured",
	},
	{
		Icon: LocalShippingIcon,
		text: Strings.nav.shipping,
		path: "/dashboard/shipping",
	},
	{
		Icon: InputIcon,
		text: Strings.nav.cargoOrder,
		path: "/dashboard/cargoOrder",
	},
];
