import { makeAutoObservable, runInAction } from "mobx";

export default class DatePickerShelf {
	public date: Date | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	public setDate(date?: Date) {
		if (date) {
			runInAction(() => (this.date = date));
		}
	}

	public clear = () => {
		this.date = null;
	};
}
