import * as API from "@startapp/campolog-admin-api";
import { LocalizedStringsMethods } from "localized-strings";

const productionUrl = "https://api.campolog.startapp.one/admin";
const stagingUrl = "https://api.campolog.startapp.one/admin-staging";
const developUrl = "https://api.campolog.startapp.one/admin-develop";

const bootAPI = (strings: LocalizedStringsMethods) => {
	let apiURL: string;

	switch (process.env.NODE_ENV) {
		case "production": {
			apiURL = productionUrl;
			break;
		}
		case "develop": {
			apiURL = developUrl;
			break;
		}
		case "staging":
		default: {
			apiURL = stagingUrl;
		}
	}

	API.setStrings(strings);
	API.setUrl(apiURL);
};

export default API;

export { bootAPI };

