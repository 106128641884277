import {
	uploadImage,
	ImageFormat,
} from "@startapp/campolog-admin-api";
import { makeAutoObservable, runInAction } from "mobx";

import * as Files from "./Files";

type FileUploaderStatus =
	| "failed"
	| "loading"
	| "success"
	| "uploading"
	| "created";

export interface IImage {
	width: number;
	height: number;
	url: string;
	thumb: {
		width: number;
		height: number;
		url: string;
	};
}

export type FileType = "image" | "file";

export default class FileUploader {
	public status: FileUploaderStatus = "created";

	public FileType: FileType = "file";

	public uploadedFile: IImage;

	public id = "";

	public name = "";

	public onUpload = false;

	public progress = 0;

	public filePath = "";

	public file: File | null = null;

	constructor(file?: File) {
		if (file) {
			this.file = file;
			this.name = file.name;
			this.FileType = this.setFile(this.name);
			this.filePath = URL.createObjectURL(file);
			this.id = this.filePath;
		}
		makeAutoObservable(this);
	}

	public getSrc() {
		if (this.uploadedFile) {
			return this.uploadedFile.url;
		}
		return this.filePath;
	}

	public getUploadedUncertainFile() {
		return this.uploadedFile
			? { file: this.uploadedFile, bytes: null }
			: null;
	}

	private reasonableUploadPercentage = 5;

	public static createUploaded(file: IImage, fileType: FileType) {
		const uploaded = new FileUploader();

		uploaded.name = uploaded.setUploadedName(file.url);

		uploaded.uploadedFile = file;
		uploaded.status = "success";
		uploaded.filePath = file.url;
		uploaded.id = uploaded.filePath;
		uploaded.FileType = uploaded.setFile(uploaded.name);

		return uploaded;
	}

	public setUploadedName = (name: string) => {
		return name.substr(name.lastIndexOf("/") + 1);
	};

	public setFile = (name: string) => {
		const format = name.substr(name.lastIndexOf(".") + 1);
		if (
			format === ImageFormat.png ||
			format === ImageFormat.jpeg ||
			format === "jpg"
		) {
			return "image";
		}
		return "file";
	};

	public uploadImage = (
		format: ImageFormat = ImageFormat.png,
	) => {
		runInAction(() => (this.status = "loading"));
		if (this.file) {
			Files.craftImageBuffer(this.file, 2000, async (buffer) => {
				try {
					this.uploadedFile = await uploadImage(buffer, format, null);
					runInAction(() => (this.status = "success"));
				} catch (error) {
					runInAction(() => {
						this.status = "failed";
						this.progress = 0;
					});
				}
			});
		} else {
			runInAction(() => {
				this.status = "failed";
				this.progress = 0;
			});
		}
	};

	public updateProgress = (progress: number) => {
		if (
			progress > this.reasonableUploadPercentage &&
			this.status !== "failed"
		) {
			runInAction(() => (this.status = "uploading"));
		}
		runInAction(() => (this.progress = progress));
	};

	public getImage() {
		return this.uploadedFile || null;
	}
}
