import LocalizedStrings from "localized-strings";

// MARK: Implementation
const Strings = new LocalizedStrings({
	ptBR: {
		enum: {
			ShippingStatusOptions: {
				accepted: "Aceito",
				initiated: "Iniciado",
				deliveredToDriver: "Carga entregue ao Motorista",
				deliveredToDestination: "Carga entregue ao destino final",
				received: "Recebimento de carga ao destino final",
				canceled: "Cancelado",
			},
			VehicleAxis: {
				threeQuarters: "3/4",
				stump: "Toco",
				truck: "Truck",
				doubleTruck: "Bitruck",
				semiTrailerHigh: "Carreta alta",
				semiTrailerLow: "Carreta baixa",
				doubleTrainHigh: "Bitrem alta",
				doubleTrainLow: "Bitrem baixa",
				roadTrainHigh: "Rodotrem alta",
				roadTrainLow: "Rodotrem baixa",
			},
			UserType: {
				contractor: "Contratante",
				driver: "Motorista",
			},
			AnimalType: {
				cow: "Vaca",
				calvingCow: "Vaca parida",
				heifer: "Novilha",
				ox: "Boi",
				calf: "Bezerro",
				buffalo: "Búfalo",
				buffaloFemale: "Búfala",
				pig: "Porco",
				pigFemale: "Porca",
				pigYoung: "Leitão",
				goatMale: "Bode",
				goatFemale: "Cabra",
				goatYoung: "Cabrito",
				ram: "Carneiro",
				sheep: "Ovelha",
				lamb: "Borrego",
				horse: "Cavalo",
				mare: "Égua",
				calvingMare: "Égua parida",
				horseYoung: "Potro",
				jack: "Jumento",
				donkey: "Burro",
				mule: "Mula",
				hen: "Galinha",
				chick: "Pinto",
				chicken: "Frango",
				quail: "Codorna",
				peru: "Peru",
			},
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		actions: {
			save: "Salvar",
			delete: "Apagar",
			create: "Criar",
			cancel: "Cancelar",
		},
		fields: {
			id: "id",
			archive: "Arquivar",
			document: "Documentação",
			type: "Tipo",
			image: "Imagem",
			url: "Url",
			images: "Imagens",
			phone: "Telefone",
			price: "Preço",
			whatsapp: "Whatsapp",
			category: "Categoria",
			name: "Nome",
			cnpj: "CNPJ",
			nick: "Apelido",
			birthdate: "Data de Nascimento",
			cpf: "CPF",
			seeMore: "Veja mais",
			exit: "Sair",
			notRegistered: "Não cadastrado",
			save: "Salvar",
			email: "Email",
			token: "Token",
			account: "Conta",
			text: "Texto",
			message: "Mensagem",
			// tslint:disable-next-line:no-hardcoded-credentials
			password: "Senha",
			recovery: "Recuperar Senha",
			title: "Título",
			description: "Descrição",
			createdAt: "Criado em",
			block: "Data de bloqueio",
			user: "Usuário",
			status: "Status",
			currentStatus: "Status atual",
			driver: "Motorista",
			contractor: "Contratante",
			departureDate: "Data de embarque",
			arrivedDate: "Data de chegada",
			contractorId: "Id do contratante",
			city: "Cidade",
			driverId: "Id do motorista",
			createBy: "Criado Por",
			ownerName: "Nome do Proprietário",
			ownerPhone: "Telefone do Proprietário",
			ownerEmail: "E-mail do Proprietário",
			ownerID: "ID do Proprietário",
			origin: "Origem",
			destiny: "Destino",
			animalType: "Tipo de animal",
			weightKg: "Peso total da carga (kg)",
			priceProposal: "Proposta de preço",
			quantity: "Quantidade",
		},
		components: {
			filderDrawer: {
				close: "Fechar",
			},
			table: {
				startDate: "Data de embarque",
				endDate: "Data de chegada",
				cargoType: "Tipo de carga",
				titleNumberOfUsers: "Total de usuários",
				noMoreResults: "Sem resultados",
				create: "Criar",
				delete: "Deletar",
				details: "Detalhes",
				edit: "Editar",
				export: "Exportar",
				accept: "Aceitar",
				reject: "Reject",
				fallbackData: "Não há dados correspondentes",
				editAndDelete: "Editar / Deletar",
				detailsAndDelete: "Detalhes / Deletar",
				detailsAndEdit: "Detalhes / Editar",
				blockAndEditAndDelete: "Editar / Bloquear / Deletar",
				detailsAndblockAndEditAndDelete:
					"Vizualizar / Editar / Bloquear / Deletar",
				showDetails: "Vizualizar",
				detailsAndEditAndDelete: "Editar / Detalhes / Deletar",
				AcceptAndReject: "Aceitar / Recusar",
				AcceptAndRejectaandDetails: "Detalhes / Aceitar / Recusar ",
				Active: "Ativo",
				deletedSuccessfully: "Deletado com sucesso",

				title: {
					isActive: "Ativo atualmente, clique para desativar.",
					isNotActive: "Desativado atualmente, clique para ativar.",
					selectedColumns: "Colunas Selecionadas",
					availableColumns: "Colunas Disponíveis",
				},

				titleIcon: {
					openFilter: "Abrir Opções de Filtragem",
					exportCSV: "Exportar CSV",
					editTable: "Editar Tabela",
				},
			},
			datePicker: {
				format: "dd/MM/yyyy",
				invalidDate: "Data inválida",
			},
			transportOrder: {
				title: "Frete Disponível",
				id: "Id",
				vehicle: "Veículo",
				owner: "Motorista",
				accepter: "Contratante",
				weight: "Peso da carga (kg)",
				price: "Preço",
				departureDate: "Data de Embarque",
				originAddress: "Origem",
				destinyAddress: "Destino",
				createDate: "Data de criação",
			},
			cargoOrder: {
				title: "Pedido de frete",
				id: "Id",
				owner: "Contratante",
				accepter: "Motorista",
				weight: "Peso da carga (kg)",
				price: "Preço",
				departureDate: "Data de Embarque",
				arrivedDate: "Data de Chegada",
				originAddress: "Origem",
				destinyAddress: "Destino",
				createDate: "Data de criação",
				animalType: "Categoria Animal",
			},
			shipping: {
				id: "Id",
				currentStatus: "Status atual",
				createDate: "Data de criação",
			},
			ShowStatics: {
				freightInProgress: "Fretes em Andamento:",
				finishedFreight: "Fretes Finalizados:",
				error: "Não foi possível realizar a chamada",
			},
		},
		cancel: "Cancelar",
		confirm: "Confirmar",
		ok: "Ok",
		logoutDialog: {
			title: "Logout",
			message: "Tem certeza que deseja sair?",
		},

		dialogs: {
			deleteContact: (name: string) => ({
				title: "Apagar Contato",
				message: `Tem certeza que deseja apagar o contato ${name} ?, essa operação não pode ser revertida.`,
			}),
			delete: {
				title: "Confirmação de exclusão.",
				message: "Deseja mesmo excluir este usuário?",
			},
			operationHasBeenASuccess: "Operação realizada com sucesso.",
		},

		loading: "Carregando",
		hiAdmin: "Olá, Administrador(a)",
		error: {
			needLongerText: (numOfChars: number) =>
				`Precisa ser maior que ${numOfChars} caracteres.`,
			invalidEmail: "Email inválido",
			invalidPhone: "Telefone inválido",
			stillLoading: "Ainda Carregando, aguarde.",
			nameIsEmpty: "O nome está vazio",
			default: "Houve um erro, tente novamente mais tarde.",
			audioError: "Para cadastrar um treino insira um audio.",
			emptyDate: "Houve um erro, data incorreta",
			mustWaitWhileStillLoading: "Ainda em carregamento, aguarde um momento.",
			missingImage: "É necessario anexar a imagem",
			missingDate: "Insira uma data.",
			invalidDate: "Data inválida.",
			missingType: "Escolha um tipo.",
			aleredySelected: "Já Selecionado!",
			clientNotFound: (id?: string) => `Cliente [${id || ""}] não encontrado.`,
		},

		nav: {
			title: "campolog",
			home: "Inicio",
			users: "Usuários",
			adminUsers: "Usuários Admin",
			logout: "Sair",
			configure: "Configurações",
			about: "Sobre",
			disconected: "Você está descontectado da internet",
			insured: "Assegurados",
			shipping: "Fretes",
			cargoOrder: "Pedidos de Frete",
		},

		pageLogin: {
			pagetitle: "campolog",
			title: "Acesso",
			buttonLogin: "Entrar",
			noAccess: "Ainda não possui acesso?",
			requestAccess: "Solicite Aqui",
			recoveryPassword: "Esqueceu a senha?",
			messageFooter: "Desenvolvido de ponta a ponta pela Startapp",
			needHelp: "Precisa de ajuda?",
		},
		home: {
			welcome: "Bem vindo ao dashboard do campolog",
		},
		cargoOrder: {
			table: {
				title: "Tabela de Pedidos de Frete",
			},
			details: {
				title: "Detalhes do Pedido de Frete",
				contractorData: "Dados do contratante",
			},
		},
		admin: {
			table: {
				title: "Tabela de administradores",
			},
			details: {
				title: "Detalhes do administrador",
				header: { name: "Nome", email: "Email" },
			},
			create: {
				title: "Criar administrador",
				createButton: "Criar",
			},
			edit: {
				title: "editar administrador",
			},
		},
		insured: {
			table: {
				title: "Tabela de Assegurados",
			},
			details: {
				title: "Detalhes do Assegurado",
				header: { name: "Nome", email: "Email" },
				owner: "Proprietário",
			},
			create: {
				title: "Criar administrador",
				createButton: "Criar",
			},
			edit: {
				title: "editar administrador",
			},
		},
		adminUsers: {
			title: "Administradores",
			editor: {
				getTitle: (onEditMode = true) =>
					onEditMode ? "Editar Administrador" : "Cadastrar Administrador",
			},
			edit: {
				title: (adminUserName?: string) =>
					`Edição do Administrador ${adminUserName || ""}`,
				onClick: "Usuário foi editado",
				saveChanges: "Salvar",
				success: (adminUserName: string) =>
					`${adminUserName} modificado com sucesso!`,
			},
			table: {
				header: {
					name: "Nome",
					phone: "Telefone",
					email: "Email",
					editDelete: "Editar / Deletar",
				},
				tableIsEmpty: "Não foi possivel encontrar mais administradores",
			},
			deleteDialogAdmin: {
				title: "Deletar Administrador",
				message: "Tem certeza que deseja deletar este administrador?",
			},
			csvHeaders: [
				{ label: "Id", key: "id" },
				{ label: "Nome", key: "name" },
				{ label: "E-mail", key: "email" },
			],
		},
		users: {
			table: {
				title: "Tabela de usuários",
			},
			details: {
				title: "Detalhes do usuário",
				header: { name: "Nome", email: "Email" },
				myShippings: "Meus Fretes",
				filter: {
					title: "Filtro",
					onFilter: "Filtrar",
					clearFilter: "Limpar Filtro",
					initPeriodDate: "Periodo Inicial",
					finalPeriodDate: "Periodo Final",
					startDate: "Data Inicial",
					endDate: "Data Final",
				},
				miniCard: {
					amount: "Quantidade",
					earnedValue: "Valor ganho",
				},
			},
			create: {
				title: "Criar usuário",
				createButton: "Criar",
			},
			edit: {
				title: (userName?: string) => `Edição do Usuário ${userName || ""}`,
				onClick: "Usuário foi editado",
				saveChanges: "Salvar",
				success: (userName: string) => `${userName} modificado com sucesso!`,
			},
			blockDialogUser: {
				title: (blockUser: boolean) =>
					`${blockUser ? "Bloquear" : "Desbloquear"} usuário`,
				message: (blockUser: boolean) =>
					`Tem certeza que deseja ${
						blockUser ? "bloquear" : "desbloquear"
					} este usuário?`,
			},
		},
		enterprises: {
			table: {
				title: "Tabela de Empresas",
			},
			create: {
				title: "Criar empresas",
				createButton: "Criar",
				successMessage: "Empresas criada com sucesso!",
			},
			edit: {
				title: (name?: string) => `Edição da Empresa ${name || ""}`,
				saveChanges: "Salvar",
				onClick: "Empresa foi editada",
				success: (name: string) => `${name} modificado com sucesso!`,
			},
			delete: {
				title: "Confirmação de exclusão",
				message: "Deseja mesmo excluir esta empresa?",
			},
		},
		shipping: {
			table: {
				title: "Tabela de Fretes",
				filter: {
					title: "Filtro",
					driver: "Selecionar motorista",
					contractor: "Selecionar Contratante",
					status: "Status",
					userType: "Tipo de Usuário",
					onFilter: "Filtrar",
					clearFilter: "Limpar Filtro",
					initPeriodDate: "Periodo Inicial",
					finalPeriodDate: "Periodo Final",
					startDate: "Data Inicial",
					endDate: "Data Final",
				},
			},
			details: {
				title: "Detalhes do frete",
				currentStatus: "Status atual",
				id: "Id:",
			},
		},
		imageCase: {
			addImagePlaceholder: "Clique em adicionar para escolher uma imagem",
			addImagesPlaceholder:
				"Clique em adicionar para escolher uma ou mais imagens",
			addFilePlaceholder: "Clique em adicionar para escolher um arquivo",
			addFilesPlaceholder:
				"Clique em adicionar para escolher um ou mais arquivos",
			addImageBtn: "Adicionar",
			missingImage: "A imagem é obrigatória",
		},
		register: {
			success: "Administrador criado com sucesso",
			successUser: "Usúario criado com sucesso",
			successLevel: "Nível criado com sucesso",
		},
		recoveryPage: {
			title: "Recuperação de Senha",
			newAccess: "Novo Acesso",
			sendSuccess: "E-mail enviado com sucesso!",
			recoverPasswordButton: "Enviar e-mail",
			newPasswordButton: "Alterar Senha",
			backButton: "Voltar",
			email: "Email",
			validToken: "Token válido!",
			confirmTokenButton: "Confirmar Token",
			tokenButton: "Já possui um token?",
			noTokenButton: "Não possuo token",
			newPassword: "Nova senha",
			confirmNewPassword: "Confirme sua nova senha",
			samePasswordError: "As senhas não correspondem",
		},
	},
});

export default Strings;
