// MARK: React
import React from "react";

// MARK: Mobx
import {
	Box,
	Card,
	CardContent,
	CardActions,
	Divider,
	LinearProgress,
	Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";

// MARK: Resources

// MARK: Componentes
import { BackButtonHeader } from "../BackButtonHeader";
import { PageContainer } from "../PageContainer";
import { useStyles } from "./styles";

interface IProps {
	title: string;
	loading?: boolean;
	actionsFooter?: React.ReactElement | null;
	hasAError?: string;
	modelContent: boolean;
	contentStyle?: string;
}

const DetailsForm: React.FC<IProps> = (props) => {
	const classes = useStyles();
	const {
		title,
		loading,
		actionsFooter,
		hasAError,
		modelContent,
		contentStyle,
	} = props;

	return (
		<PageContainer>
			{loading ? <LinearProgress /> : null}
			<Card className={classes.root}>
				<BackButtonHeader title={title} />
				<Box className={classes.box} display="flex" flexDirection="column">
					<Divider />
					<CardContent
						className={[classes.cardContent, contentStyle].join(" ")}
					>
						{modelContent ? (
							props.children
						) : (
							<Typography>{hasAError}</Typography>
						)}
					</CardContent>
				</Box>
				<Divider />
				<CardActions>{actionsFooter}</CardActions>
			</Card>
		</PageContainer>
	);
};

export default observer(DetailsForm);
