import React from "react";
import "./style.scss";

export const PageContainer: React.FC = (props) => {
	return (
		<div className="pageContainer">
			<div className="pageContainerContent">{props.children}</div>
		</div>
	);
};
