/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import {
	Button,
	IconButton,
	TableCell,
	TableRow,
	Card,
	CardContent,
	Typography,
} from "@material-ui/core";
import Select from "../../../../components/Select";
import { Visibility, Edit, Delete, Add as AddIcon } from "@material-ui/icons";
import { useLocalStore, observer } from "mobx-react-lite";

import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";
import Strings from "../../../../modules/Strings";
import { useGlobalStore } from "../../../context";
import UserTableStore from "./store";
import useStyles from "./styles";

import API from "../../../../modules/API";

const TableView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const classes = useStyles();
	const tableStore = useLocalStore(() => new UserTableStore(uiStore, history));

	const pushToDetails = useCallback(
		(id: string) => history.push(`users/details/${id}`),
		[history],
	);

	const pushToCreate = useCallback(
		() => history.push("users/create"),
		[history],
	);

	const pushToEdit = useCallback(
		(id: string) => history.push(`users/edit/${id}`),
		[history],
	);

	const handleDeleteUser = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.dialogs.delete.message,
					title: Strings.dialogs.delete.title,
				},
				() => tableStore.deleteUser(id),
			);
		},
		[uiStore, tableStore],
	);

	return (
		<>
			{tableStore.numberOfUsers && (
				<Card className={classes.cardNumber}>
					<CardContent>
						<Typography variant="h6" className={classes.title}>{Strings.components.table.titleNumberOfUsers}</Typography>
						<Typography variant="h5" className={classes.text}>{tableStore.numberOfUsers}</Typography>
					</CardContent>
				</Card>
			)}
			<PageTableContainer>
				<SimpleTable
					loading={tableStore._loading}
					title={Strings.users.table.title}
					pagination={tableStore.getPagination()}
					isHaveNexPage={tableStore._isHaveNextPage}
					rows={tableStore._items}
					headers={[
						Strings.fields.id,
						Strings.fields.name,
						Strings.fields.email,
						Strings.fields.type,
						Strings.components.table.detailsAndEditAndDelete,
					]}
					headerToolbar={
						<Select
							defaultValue=""
							disabled={tableStore._loading}
							title="Tipo de usuário"
							fields={tableStore.selectFields}
							onChange={(e) => {
								tableStore.changeFilter(e.target.value as string);
								tableStore.getTotalNumberUsers(e.target.value as string);
							}}
						/>
					}
					renderItem={(item) => (
						<TableRow hover key={item.id}>
							<TableCell align="center">{item.id}</TableCell>
							<TableCell align="center">{item.name}</TableCell>
							<TableCell align="center">{item.email}</TableCell>
							<TableCell align="center">
								{API.translateUserType(item.type)}
							</TableCell>
							<TableCell align="center">
								<IconButton onClick={() => pushToEdit(item.id)}>
									<Edit />
								</IconButton>
								<IconButton onClick={() => pushToDetails(item.id)}>
									<Visibility />
								</IconButton>
								<IconButton onClick={() => handleDeleteUser(item.id)}>
									<Delete />
								</IconButton>
							</TableCell>
						</TableRow>
					)}
					bottomToolbar={
						<Button startIcon={<AddIcon />} onClick={pushToCreate}>
							{Strings.actions.create}
						</Button>
					}
				/>
			</PageTableContainer>
		</>
	);
};

export default observer(TableView);
